import React from "react";
import "../../../assets/styles/components/modules/forms.scss";

import Button from "../../utils/atoms/Button.jsx";

const ResetPassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [tempID, setTempID] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [isAvailable, setIsAvailable] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    //Get tempId from url query
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let tempId = params.tempId;
    if (tempId) {
      setTempID(tempId);
      setTempID((value) => {
        if (value.length > 0) {
          let time, id;
          let tempIDArray = value.split("@");
          time = tempIDArray[0];
          id = tempIDArray[1];
          if (+new Date() < time + 3600) {
            setIsAvailable(true);
            setUserId(id);
          }
        } else {
          setError("Vous n'avez pas d'identifiant valable !");
        }
        return value;
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all fields are filled
    if (password === "" || confirmPassword === "") {
      setError("Un ou plusieurs champs sont vide");
      setSuccess("");
      setLoading(false);
      if (password === "") {
        let passwordInput = document.querySelector('input[name="password"]');
        passwordInput.classList.add("borderError");
      }
      if (confirmPassword === "") {
        let confirmPasswordInput = document.querySelector(
          'input[name="confirmPassword"]'
        );
        confirmPasswordInput.classList.add("borderError");
      }
      return;
    }

    // send request to netlify function reset-user width newPasswords
    setLoading(true);
    fetch("/.netlify/functions/reset-user", {
      method: "POST",
      body: JSON.stringify({
        password: confirmPassword,
        id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        setSuccess("Votre mot de passe à bien était mis à jour");
        setError("");
        setLoading(false);
      } else {
        setError("Une erreur est survenue");
        setSuccess("");
        setLoading(false);
      }
    });
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      <div className="bi-form-loader">
        {/*<!--- Loading manager --->*/}
        {loading && <p className="loading">Chargement...</p>}
      </div>
      {tempID.length > 0 && isAvailable && (
        <>
          <input
            type="password"
            placeholder="Mot de passe"
            name="password"
            value={password}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <input
            type="password"
            placeholder="Confirmer le mot de passe"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <Button className="bi-button yellow" type="submit" value="Envoyer">Envoyer</Button>
        </>
      )}
    </form>
  );
};

export default ResetPassword;
