import React from "react";

import Button from "../../utils/atoms/Button.jsx";
import eventBus from "../../../assets/scripts/utils/eventBus.js";

import "../../../assets/styles/components/modules/forms.scss";
// Create form for register user and send it to create-user netlify function
const Update = ({ data }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [acceptNewsletter, setAcceptNewsletter] = React.useState(
    data.has_accepted_newsletter
  );

  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "newsletter":
        setAcceptNewsletter(!acceptNewsletter);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (password) {
      // Check if passwords match
      if (password !== confirmPassword) {
        setError("Les mots de passe doivent être identiques");
        setSuccess("");
        setLoading(false);
        return;
      }

      // Si password et email sont existant send new password and new email to update
      if (email.length > 0) {
        // send email to netlify function
        // Send data to netlify function
        setLoading(true);
        fetch("/.netlify/functions/reset-user", {
          method: "POST",
          body: JSON.stringify({
            connected: true,
            email: email,
            ...(confirmPassword.length > 0 && { password: confirmPassword }),
            ...(acceptNewsletter != data.has_accepted_newsletter && {
              has_accepted_newsletter: acceptNewsletter,
            }),
            id: data._id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log("resultat data", res);
          if (res.status === 200) {
            // if the approvement of the newslette have change            
            console.log("Success");
            if (acceptNewsletter !== data.has_accepted_newsletter) {
              // Make a request to update state of the email on mailet
              console.log("We are going to do a request to update email");
              fetch("/.netlify/functions/subscribe-newsletter", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  formData: data,
                  state: acceptNewsletter,
                  from: "account",
                }),
              }).then((res) => {
                if (res.status === 200) {
                  console.log("resultat data", res);
                  setSuccess("Vous êtes bien abonnés !");
                  setError("");
                  setLoading(false);
                  res.json().then((data) => {
                    console.log("data", data[0]);
                  });
                } else {
                  setSuccess("");
                  setLoading(false);
                }
              });
            } else {
              setSuccess(
                "Vos informations de compte on bien était mis à jour!"
              );
              setError("");
              setLoading(false);
            }


            eventBus.dispatch('updateData');
          } else {
            setError("Une erreur est survenue");
            setSuccess("");
            setLoading(false);
          }
        });

        return;
      }

      // else
      if (email === "") {
        // send new password to the reset-user
        setLoading(true);
        fetch("/.netlify/functions/reset-user", {
          method: "POST",
          body: JSON.stringify({
            connected: true,
            ...(confirmPassword.length > 0 && { password: confirmPassword }),
            ...(acceptNewsletter != data.has_accepted_newsletter && {
              has_accepted_newsletter: acceptNewsletter,
            }),
            id: data._id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res.status === 200) {
            if (acceptNewsletter !== data.has_accepted_newsletter) {
              // Make a request to update state of the email on mailet
              console.log("We are going to do a request to update email");
              fetch("/.netlify/functions/subscribe-newsletter", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  formData: data,
                  state: acceptNewsletter,
                  from: "account",
                }),
              }).then((res) => {
                if (res.status === 200) {
                  console.log("resultat data", res);
                  setSuccess("Vous êtes bien abonnés !");
                  setError("");
                  setLoading(false);
                  res.json().then((data) => {
                    console.log("data", data[0]);
                  });
                } else {
                  setSuccess("");
                  setLoading(false);
                }
              });
            } else {
              setSuccess("Votre mot de passe à bien était mis à jour");
              setError("");
              setLoading(false);
            }
            eventBus.dispatch('updateData');
          } else {
            setError("Une erreur est survenue");
            setSuccess("");
            setLoading(false);
          }
        });

        return;
      }

      return;
    }

    if (email) {
      setLoading(true);
      fetch("/.netlify/functions/reset-user", {
        method: "POST",
        body: JSON.stringify({
          connected: true,
          ...(email.length > 0 && { email: email }),
          ...(acceptNewsletter !== data.has_accepted_newsletter && {
            has_accepted_newsletter: acceptNewsletter,
          }),
          id: data._id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          if (acceptNewsletter !== data.has_accepted_newsletter) {
            // Make a request to update state of the email on mailet
            console.log("We are going to do a request to update email");
            fetch("/.netlify/functions/subscribe-newsletter", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                formData: data,
                state: acceptNewsletter,
                from: "account",
              }),
            }).then((res2) => {
              if (res2.status === 200) {
                console.log("resultat data", res);
                setSuccess("Vous êtes bien abonnés !");
                setError("");
                setLoading(false);
              } else {
                setSuccess("");
                setLoading(false);
              }
            });
          } else {
            setSuccess("Votre mail à bien était mis à jour");
            setError("");
            setLoading(false);
          }
          eventBus.dispatch('updateData');
        } else {
          setError("Une erreur est survenue");
          setSuccess("");
          setLoading(false);
        }
      });

      return;
    }

    if (acceptNewsletter !== data.has_accepted_newsletter) {
      setLoading(true);
      fetch("/.netlify/functions/reset-user", {
        method: "POST",
        body: JSON.stringify({
          connected: true,
          ...(acceptNewsletter !== data.has_accepted_newsletter && {
            has_accepted_newsletter: acceptNewsletter,
          }),
          id: data._id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        console.log("Update just newsletter");
        if (res.status === 200) {
          fetch("/.netlify/functions/subscribe-newsletter", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              formData: data,
              state: acceptNewsletter,
              from: "account",
            }),
          }).then((res) => {
            if (res.status === 200) {
              console.log("resultat data", res);
              setSuccess(
                "Votre demande de changement sur notre liste à était valider"
              );
              setError("");
              setLoading(false);
              res.json().then((data) => {
                console.log("data", data[0]);
              });
            } else {
              setSuccess(
                "Vos informations de compte on bien était mis à jour!"
              );
              setLoading(false);
            }
          });
        } else {
          setError("Une erreur est survenue");
          setSuccess("");
          setLoading(false);
        }

        eventBus.dispatch('updateData');
      });
    }

    return;
  };
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      <div className="bi-form-loader">
        {/*<!--- Loading manager --->*/}
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="text"
        placeholder="Prénom"
        name="firstName"
        value={data.firstname}
        disabled
      />
      <input
        type="text"
        placeholder="Nom"
        name="lastName"
        value={data.name}
        disabled
      />
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="password"
        placeholder="Mot de passe"
        name="password"
        value={password}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="password"
        placeholder="Confirmer le mot de passe"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <br />
      <div className="bi-form-group flex">
        <input
          id="newsletter"
          type="checkbox"
          name="newsletter"
          value="newsletter"
          checked={acceptNewsletter}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <label htmlFor="newsletter">S’abonner à la newsletter</label>
      </div>
      <Button className="bi-button yellow" type="submit" value="Envoyer">Envoyer</Button>
    </form>
  );
};

export default Update;
