import React from "react";

import PetersProfile from "../../../assets/images/pages/event/peters-profile.jpg";
import MoebiusProfile from "../../../assets/images/pages/event/moebius-profile.jpg";
import ShigaProfile from "../../../assets/images/pages/event/shiga-profile.jpg";
import JouvrayProfile from "../../../assets/images/pages/event/jouvray-profile.jpg";
import CharbProfile from "../../../assets/images/pages/event/charb-profile.jpg";
import BilalProfile from "../../../assets/images/pages/event/bilal-profile.jpg";
import AugustinProfile from "../../../assets/images/pages/event/augustin-profile.jpg";
import GuarnidoProfile from "../../../assets/images/pages/event/guarnido-profile.jpg";
import LarcenetProfile from "../../../assets/images/pages/event/larcenet-profile.jpg";
import MandelProfile from "../../../assets/images/pages/event/mandel-profile.jpg";
import VivesProfile from "../../../assets/images/pages/event/vives-profile.jpg";
import BagieuProfile from "../../../assets/images/pages/event/bagieu-profile.jpg";
import MeurisseProfile from "../../../assets/images/pages/event/meurisse-profile.jpg";
import MezièresProfile from "../../../assets/images/pages/event/mezières-profile.jpg";

import PetersProfileRetina from "../../../assets/images/pages/event/peters-profile@2x.jpg";
import MoebiusProfileRetina from "../../../assets/images/pages/event/moebius-profile@2x.jpg";
import ShigaProfileRetina from "../../../assets/images/pages/event/shiga-profile@2x.jpg";
import JouvrayProfileRetina from "../../../assets/images/pages/event/jouvray-profile@2x.jpg";
import CharbProfileRetina from "../../../assets/images/pages/event/charb-profile@2x.jpg";
import BilalProfileRetina from "../../../assets/images/pages/event/bilal-profile@2x.jpg";
import AugustinProfileRetina from "../../../assets/images/pages/event/augustin-profile@2x.jpg";
import GuarnidoProfileRetina from "../../../assets/images/pages/event/guarnido-profile@2x.jpg";
import LarcenetProfileRetina from "../../../assets/images/pages/event/larcenet-profile@2x.jpg";
import MandelProfileRetina from "../../../assets/images/pages/event/mandel-profile@2x.jpg";
import VivesProfileRetina from "../../../assets/images/pages/event/vives-profile@2x.jpg";
import BagieuProfileRetina from "../../../assets/images/pages/event/bagieu-profile@2x.jpg";
import MeurisseProfileRetina from "../../../assets/images/pages/event/meurisse-profile@2x.jpg";
import MezièresProfileRetina from "../../../assets/images/pages/event/mezières-profile@2x.jpg";
const PastArtists = ({ sliders }) => {
  const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;

    posx = e.clientX - e.target.getBoundingClientRect().left;
    posy = e.clientY - e.target.getBoundingClientRect().top;
    return { x: posx, y: posy };
  };

  const hoverAnimation = (sens, event, target) => {
  /* 
    let next = event;
    const clean = () => {
      document.querySelectorAll(".bi-events-pastArtists-list picture").forEach(img => {
       if(img.style.width > 10){
        img.classList('open');
       }
      })
      document
        .querySelectorAll(".bi-events-pastArtists-list picture.open")
        .forEach((el) => {
          if (!gsap.isTweening(el)) {
            gsap.set(el, { display: "block", width: 170 });
            //
            let tl = gsap.timeline({
              onComplete: () => {
                el?.classList?.remove("open");
              },
            });

            tl.fromTo(el.querySelector("img"), { scale: 1 }, { scale: 1.2 });
            tl.fromTo(el, { width: 170 }, { width: 0 }, "-=0.2");
          }
        });
    };

    clean();
    requestAnimationFrame(() => {
      if (sens === "enter") {
        let pictureImage = event.target.querySelector("picture");
        let imgImage = event.target.querySelector("img");
        gsap.killTweensOf(pictureImage);
        gsap.killTweensOf(imgImage);

        gsap.set(pictureImage, { display: "block", width: 0 });
        //
        if (pictureImage) {
          pictureImage.classList.add("open");
          let tl = gsap.timeline();

          tl.fromTo(pictureImage, { width: 0 }, { width: 170 });
          tl.fromTo(imgImage, { scale: 1.2 }, { scale: 1 }, "-=0.2");
        }
      }

      if (sens === "leave") {
        clean();
      }
    });
    */
  };

  const movePicture = (event) => {
    let MousePos = getMousePos(event);
    let RevealElement = event.currentTarget.querySelectorAll("picture");
    RevealElement.forEach((element) => {
      element.style.top = `${MousePos.y - 80}px`;
      element.style.left = `${MousePos.x - 85.5}px`;
    });
  };

  return (
    <section className="bi-layout bi-events-pastArtists">
      <h2 className="bi-title-quaternary bi-events-pastArtists-title">
        Ils sont passés chez Expérience
      </h2>

      <ul className="bi-events-pastArtists-list">
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Frederik Peeters</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={PetersProfileRetina + " 2x," + PetersProfile + " 1x"}
            />
            <img src={PetersProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Moebius</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={MoebiusProfileRetina + " 2x," + MoebiusProfile + " 1x"}
            />
            <img src={MoebiusProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Jason Shiga</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={ShigaProfileRetina + " 2x," + ShigaProfile + " 1x"}
            />
            <img src={ShigaProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Les frères Jouvray</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={JouvrayProfileRetina + " 2x," + JouvrayProfile + " 1x"}
            />
            <img src={JouvrayProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Charb</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={CharbProfileRetina + " 2x," + CharbProfile + " 1x"}
            />
            <img src={CharbProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Enki Bilal</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={BilalProfileRetina + " 2x," + BilalProfile + " 1x"}
            />
            <img src={BilalProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Virginie Augustin</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={AugustinProfileRetina + " 2x," + AugustinProfile + " 1x"}
            />
            <img src={AugustinProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Juanjo Guarnido</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={GuarnidoProfileRetina + " 2x," + GuarnidoProfile + " 1x"}
            />
            <img src={GuarnidoProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Manu Larcenet</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={LarcenetProfileRetina + " 2x," + LarcenetProfile + " 1x"}
            />
            <img src={LarcenetProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Lisa Mandel</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={MandelProfileRetina + " 2x," + MandelProfile + " 1x"}
            />
            <img src={MandelProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Bastien Vivès</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={VivesProfileRetina + " 2x," + VivesProfile + " 1x"}
            />
            <img src={VivesProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Pénélope Bagieu</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={BagieuProfileRetina + " 2x," + BagieuProfile + " 1x"}
            />
            <img src={BagieuProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Catherine Meurisse</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={MeurisseProfileRetina + " 2x," + MeurisseProfile + " 1x"}
            />
            <img src={MeurisseProfile} alt="Profile d'artiste" />
          </picture>
        </li>
        <li
          className="bi-title-quaternary bi-events-pastArtists-list-item"
          onMouseEnter={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseLeave={(e) => {
            hoverAnimation("leave", e);
          }}
          onClick={(e) => {
            hoverAnimation("enter", e);
          }}
          onMouseMove={(e) => {
            movePicture(e);
          }}
        >
          <span>Jean-Claude Mezières</span>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={MezièresProfileRetina + " 2x," + MezièresProfile + " 1x"}
            />
            <img src={MezièresProfile} alt="Profile d'artiste" />
          </picture>
        </li>
      </ul>
      {sliders}
    </section>
  );
};

export default PastArtists;
