/**REQUIREMENTS-LIBRAIRIES-SCRIPTS-CSS*/
import React from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/modules/sliderModule/index";
import { graphql, Link, StaticQuery } from "gatsby";
import gsap from "gsap";
// import { Power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import urlBuilderImage from "../assets/scripts/utils/urlImageBuilder";
import eventBus from "../assets/scripts/utils/eventBus";
import "../assets/styles/pages/index.scss";
/**COMPONENT*/
import Tag from "../components/utils/atoms/Tag";
import Button from "../components/utils/atoms/Button";
import EventListItem from "../components/modules/events/EventListItem";
import GridSugest from "../components/modules/products/GridSugest";
import ProductListItem from "../components/modules/products/ProductListItem";
/*IMAGES*/
import BoutiqueImageDesktop from "../assets/images/pages/boutique/boutique_footer_desktop_1x.jpg";
import BoutiqueImageDesktopWebp from "../assets/images/pages/boutique/boutique_footer_desktop_1x.webp";
import BoutiqueImageDesktopRetina from "../assets/images/pages/boutique/boutique_footer_desktop_2x.jpg";
import BoutiqueImageDesktopRetinaWebp from "../assets/images/pages/boutique/boutique_footer_desktop_2x.webp";
import BoutiqueImageMobile from "../assets/images/pages/boutique/boutique_footer_mobile_1x.jpg";
import BoutiqueImageMobileWebp from "../assets/images/pages/boutique/boutique_footer_mobile_1x.webp";
import BoutiqueImageMobileRetina from "../assets/images/pages/boutique/boutique_footer_mobile_2x.jpg";
import BoutiqueImageMobileRetinaWebp from "../assets/images/pages/boutique/boutique_footer_mobile_2x.webp";


import AProposDesktop from "../assets/images/pages/index/index_about_desktop_1x.jpg";
import AProposDesktopWebp from "../assets/images/pages/index/index_about_desktop_1x.webp";
import AProposDesktopRetina from "../assets/images/pages/index/index_about_desktop_2x.jpg";
import AProposDesktopRetinaWebp from "../assets/images/pages/index/index_about_desktop_2x.webp";
import AProposMobile from "../assets/images/pages/index/index_about_mobile_1x.jpg";
import AProposMobileWebp from "../assets/images/pages/index/index_about_mobile_1x.webp";
import AProposMobileRetina from "../assets/images/pages/index/index_about_mobile_2x.jpg";
import AProposMobileRetinaWebp from "../assets/images/pages/index/index_about_mobile_2x.webp";
import Arche from "../components/modules/index/Arche";
/////

const Page = ({ data }) => {
  const [isDesktopState, setIsdesktop] = React.useState(true);

  // create all product data in merging to array
  const newData = [...data.serigraphie.nodes, ...data.paraBD.nodes];
  const lastCrush = data.lastCrush.edges[0].node;

  const introAnimation = (isDesktop) => {
    gsap.killTweensOf("h1 .title-line .word");
    if (isDesktop) {
      gsap.fromTo(
        "h1 .title-line .word",
        {
          translateY: window.innerWidth > 768 ? 260 : 160,
          rotate: (index, target) => {
            return index % 2 ? -4 : 4;
          },
        },
        {
          translateY: 14,
          rotate: 0,
          stagger: 0.15,
        }
      );
    } else {
      let tl = gsap.timeline();
      tl.fromTo(
        "h1 .title-line .word",
        {
          translateY: window.innerWidth > 768 ? 260 : 160,
          rotate: (index, target) => {
            return index % 2 ? -4 : 4;
          },
        },
        {
          translateY: 14,
          rotate: 0,
          stagger: 0.15,
        }
      );

      tl.fromTo(
        ".bi-tags-wrapper, .bi-index-picHeading-container",
        { opacity: 0, translateY: 16 },
        { opacity: 1, translateY: 0, stagger: 0.25 }
      );
    }
  };

  const isDesktop = () => {
    if (
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    ) {
      return window?.innerWidth > 768;
    }
  };

  React.useEffect(() => {
    setIsdesktop(isDesktop());
    document.addEventListener("resize", () => {
      setIsdesktop(isDesktop());
    });
  }, []);

  React.useEffect(() => {
    eventBus.on("pageTransitionEnd", () => {
      ScrollTrigger.update();
      introAnimation(isDesktop());
    });
    eventBus.on("loaderEnd", () => {
      introAnimation(isDesktop());
    });
  }, []);


  return (
    <>
      <Helmet>
        <title>Librairie Expérience | Librairie BD indépendante à Lyon</title>
        <meta
          name="description"
          content="Bienvenue sur la Librairie Expérience Lyon, spécialiste de la BD Franco-Belges, mangas et comics, et indépendante depuis 1973."
        />
      </Helmet>
      <section className="bi-layout bi-index-heading">
        <div className="bi-index-heading-title">
          <h1
            style={{
              textTransform: "uppercase",
            }}
          >
            <span className="title-line">
              <span className="word">Librairie</span>
            </span>{" "}
            <span className="title-line">
              <span className="word">Expérience</span>
            </span>
            <div className="bi-visibility-hidden bi-tags-wrapper">
              <Tag parallax-vitesse="-0.35" id="h1-tag1" line="1" column="3">
                100% indépendante
              </Tag>
              <Tag parallax-vitesse="0.35" id="h1-tag2" line="3" column="1">
                Depuis 1973
              </Tag>
            </div>
          </h1>
        </div>
        {/* TEST */}
      </section>


      <Arche />



      <section className="bi-layout bi-index-products">
        <Slider type='infinite' items={newData} heading={{ title: 'Découvrez nos produits', content: 'Un grand choix d’affiches BD, des figurines, des tirages de tête et notre production perso de sérigraphies !', buttonContent: 'Accéder à la boutique', buttonLink: '/store/' }}>
          {
            newData.map((product, index) => {
              return (
                <li key={index} className='bi-slider_item'>
                  <ProductListItem
                    product={product}
                    dataFirst={index === newData.length}
                    dataLast={index === (newData.length * 2) - 1}
                    locationSlot= 'infinite'
                  />
                </li>
              )
            })
          }
        </Slider>
      </section>

      <section className="bi-layout bi-index-about">
        <picture
          className="bi-index-picAbout"
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <source
            media="(max-width: 767px)"
            srcSet={AProposMobileRetinaWebp + " 2x," + AProposMobileWebp + " 1x"}
          />
          <source
            media="(max-width: 767px)"
            srcSet={AProposMobileRetina + " 2x," + AProposMobile + " 1x"}
          />
          <source
            media="(min-width: 768px)"
            srcSet={AProposDesktopRetinaWebp + " 2x," + AProposDesktopWebp + " 1x"}
          />
          <source
            media="(min-width: 768px)"
            srcSet={AProposDesktopRetina + " 2x," + AProposDesktop + " 1x"}
          />
          <img src={AProposDesktop} alt="Salle dela sérigraphie" />
        </picture>
        <div>
          <h2 className="bi-title-quaternary">
            100% indépendante depuis 1973 !{" "}
          </h2>
          <p>
            Expérience c’est avant tout une équipe, quatre libraires amoureux de
            la BD prêts à partager avec vous leurs plus belles lectures ! C’est
            aussi un lieu atypique où les plafonds sont recouverts de centaines
            de dédicaces.
            <br />
            <br />
            On allait oublier ! Expérience c’est aussi un atelier de sérigraphie
            où nous fabriquons nos propres productions.
          </p>
          <Button className="bi-button yellow" type="link" to="/a-propos/">
            En savoir plus
          </Button>
        </div>
      </section>
      <section className="bi-index-love">
        <h2 className="bi-title-primary">
          <span className="title-line">
            <span className="word">On adore!</span>
          </span>{" "}
        </h2>
        <Link
          to={"/coups-de-coeur/" + lastCrush.slug.current}
          className="bi-lastCrush-link"
          data-parallax
          data-p-vitesse={isDesktopState ? -1.3 : -0.8}
          data-p-direction="bottom"
          data-p-offset="false"
          data-p-rotate="8"
        >
          <picture
            className={
              "bi-index-picLeft" +
              " " +
              (lastCrush.author_article === "Camille" && "blue_type ") +
              " " +
              (lastCrush.author_article === "Jean-Louis" && "red_type ") +
              " " +
              (lastCrush.author_article === "Jonathan" && "orange_type ") +
              " " +
              (lastCrush.author_article === "Maxime" && "yellow_type ")
            }
          >
            <source
              media="(max-width: 767px)"
              srcSet={
                urlBuilderImage(lastCrush.cover.asset.path, 166, 226, {
                  ...lastCrush.cover.crop,
                  ...lastCrush.cover.asset,
                }) +
                " 2x," +
                urlBuilderImage(lastCrush.cover.asset.path, 83, 113, {
                  ...lastCrush.cover.crop,
                  ...lastCrush.cover.asset,
                }) +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                urlBuilderImage(lastCrush.cover.asset.path, 496, 734, {
                  ...lastCrush.cover.crop,
                  ...lastCrush.cover.asset,
                }) +
                " 2x," +
                urlBuilderImage(lastCrush.cover.asset.path, 248, 367, {
                  ...lastCrush.cover.crop,
                  ...lastCrush.cover.asset,
                }) +
                " 1x"
              }
            />
            <img
              src={urlBuilderImage(lastCrush.cover.asset.path, 248, 367, {
                ...lastCrush.cover.crop,
                ...lastCrush.cover.asset,
              })}
              alt="Salle dela sérigraphie"
            />
          </picture>
            <div
              className="bi-visibility-hidden bi-tags-wrapper"
              data-parallax={true}
              data-p-vitesse={-0.5}
              data-p-direction="bottom"
              data-p-offset="false"
              data-p-rotate="-8"
            >
              <Tag parallax-vitesse="0.5" id="h1-tag1" line="1" column="2">
                Notre dernier coup de coeur
              </Tag>
            </div>
        </Link>
        <p>On vous aide à y voir plus clair parmi toutes ces sorties BD.</p>
        <Button className="bi-button black" type="link" to="/coups-de-coeur/">
          Découvrir nos coups de coeur
        </Button>
      </section>
      <section className="bi-layout bi-shop-program">
        {data && data.events.nodes && (
          <GridSugest title={""} items={data &&
            data.events.nodes
              .filter((event) => {
                if(event && event.year) {
                  let dateFrom = event.year.split("/");
                  let newDate = new Date(
                    dateFrom[2],
                    dateFrom[1] - 1,
                    dateFrom[0]
                  ).getTime();

                  return newDate > new Date().getTime();
                }

                return false 
              })
              .slice(0, 3)} heading={{title: 'Demandez le programme', content: "À la librairie, on adore faire rencontrer lecteurs et auteurs. Entre dédicaces, festivals, soirées à l’atelier de sérigraphie… nos événements sont nombreux et réguliers.", buttonLink: '/evenements/', buttonContent: 'Tous nos événements'}}>
            {data &&
              data.events.nodes
                .filter((event) => {
                  if(event && event.year) {
                    let dateFrom = event.year.split("/");
                    let newDate = new Date(
                      dateFrom[2],
                      dateFrom[1] - 1,
                      dateFrom[0]
                    ).getTime();
  
                    return newDate > new Date().getTime();
                  }
  
                  return false 
                })
                .slice(0, 3)
                .map((event, index) => {
                  return <li key={index} className='bi-slider_item'><EventListItem key={index} event={event} mobileWidth={400} desktopWidth={800}/></li>;
                })}
          </GridSugest>
        )}
      </section>
      <picture
        className="bi-shop-picture"
        data-parallax="true"
        data-p-direction="top"
        data-p-vitesse="0.25"
      >
        <source
          media="(max-width: 767px)"
          srcSet={
            BoutiqueImageMobileRetinaWebp + " 2x," + BoutiqueImageMobileWebp + " 1x"
          }
        />
        <source
          media="(max-width: 767px)"
          srcSet={
            BoutiqueImageMobileRetina + " 2x," + BoutiqueImageMobile + " 1x"
          }
        />
        <source
          media="(min-width: 768px)"
          srcSet={
            BoutiqueImageDesktopRetinaWebp + " 2x," + BoutiqueImageDesktopWebp + " 1x"
          }
        />
        <source
          media="(min-width: 768px)"
          srcSet={
            BoutiqueImageDesktopRetina + " 2x," + BoutiqueImageDesktop + " 1x"
          }
        />
        <img src={BoutiqueImageDesktop} alt="Salle dela sérigraphie" />
      </picture>
    </>
  );
};

export const query = graphql`
  query AllProductsIndex {
    paraBD: allSanityProductDocumentType(
      sort: { fields: _createdAt, order: DESC }
      filter: { type: { eq: "para-bd" } }
      limit: 12
    ) {
      nodes {
        name
        id
        quantity
        _type
        type
        slug {
          current
        }
        price
        pro_price
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
      }
    }
    serigraphie: allSanityProductDocumentType(
      sort: { fields: _createdAt, order: DESC }
      filter: { type: { eq: "serigraphie" } }
      limit: 12
    ) {
      nodes {
        name
        id
        quantity
        _type
        type
        price
        pro_price
        slug {
          current
        }
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
      }
    }
    events: allSanityEventDocumentType(sort: {fields: year, order: ASC}) {
      nodes {
        year(locale: "fr-FR", formatString: "DD/MM/YYYY")
        type
        slug {
          current
        }
        name
        id
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
        _rawDescription
        _rawEditionWords
      }
    }
    lastCrush: allSanityCrushDocumentType(
      sort: { fields: _createdAt, order: DESC }
      limit: 1
      ) {
      edges {
        node {
          id
          name
          author_article
          slug {
            current
          }
          cover {
            crop {
              bottom
              left
              right
              top
            }
            asset {
              _id
              width
              height
              label
              path
              altText
            }
          }
        }
      }
    }
  }
`;
export default Page;
