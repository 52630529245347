import React from "react";
import Button from "../../utils/atoms/Button";
import { navigate } from "gatsby";

import "../../../assets/styles/components/modules/forms.scss";
import "../../../assets/styles/components/modules/forms/professional-space.scss";

const ProfessionalSpace = ({ data }) => {
  const [companyName, setCompanyName] = React.useState(""); // Required
  const [companySIRET, setCompanySIRET] = React.useState(""); // Required
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "companyName":
        setCompanyName(value);
        break;
      case "companySIRET":
        setCompanySIRET(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all obligatory fields are filled
    if (companyName === "" || companySIRET === "") {
      setError("Remplissez tous les champs");
      setSuccess("");
      setLoading(false);
      return;
    }

    // send request to netlify function send-mail
    fetch("/.netlify/functions/send-mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Email: data.email,
        Name: data.firstname + " " + data.name,
        companyName: companyName,
        companySIRET: companySIRET,
        type: "professional-request",
      }),
    }).then((res) => {
      setSuccess(
        "Votre demande nous est bien parvenue, nous reviendrons vers vous dans les plus brefs délais !"
      );
      setError("");
      setLoading(false);
    });
  };

  return (
    <form
      className="bi-form-professional-space"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        {!error && !success && (
          <p className="message">
            Bienvenue dans l’espace professionnel ! Vous souhaitez revendre nos
            produits dans votre établissement et bénéficier de tarifs
            préférentiels ? Il vous suffit de remplir les informations suivantes
            pour que votre compte passe du côté pro !
          </p>
        )}
      </div>
      <div className="bi-form-loader">
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="text"
        name="companyName"
        placeholder="Nom de l'entreprise*"
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="text"
        name="companySIRET"
        placeholder="SIRET*"
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <div className="bi-form-footer">
        <Button className="bi-button yellow" type="submit" value="Envoyer">Envoyer</Button>
        <p className="small">*Champs obligatoires</p>
      </div>
    </form>
  );
};

export default ProfessionalSpace;
