import React from "react";
import Marquee from "../utils/atoms/Marquee";
import { PortableText } from "@portabletext/react";
import IconButton from "../utils/atoms/IconButton";
import GridSugest from "../modules/products/GridSugest";
import CrushListItem from "../modules/crushes/CrushListItem";
import { Helmet } from "react-helmet";

// Get store user
import { useSelector } from "react-redux";

import urlImageBuilder from "../../assets/scripts/utils/urlImageBuilder";
import {
  addToCrush,
  verifCrushExisting,
  removeFromCrush,
} from "../../assets/scripts/utils/crushManager";

import "../../assets/styles/templates/crush.scss";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle(array) {
  const seed = getRandomInt(0, 5);
  let exportedArray = array;
  const minShuffle = () =>{
    var m = exportedArray.length, t, i;

    // While there remain elements to shuffle…
    while (m) {
  
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
  
      // And swap it with the current element.
      t = exportedArray[m];
      exportedArray[m] = exportedArray[i];
      exportedArray[i] = t;
    }
  
    return array;
  }

  for (let index = 0; index < seed; index++) {
    exportedArray = minShuffle(); 
  }

  return exportedArray;
}

const Page = (props) => {
  const crush = props.pageContext.crush;
  const allCrushes = props.pageContext.allCrushes.nodes;
  const { connected, id, data } = useSelector((state) => state.authentication);
  const [crushExisting, setCrushExisting] = React.useState(false);
  const [shufflesProduct, setShufflesProduct] = React.useState(allCrushes);

  React.useEffect(() => {
    if (connected) {
      setCrushExisting(verifCrushExisting(crush._id, data.wishlist));
      console.log(
        "verif crush existing:",
        verifCrushExisting(crush._id, data.wishlist)
      );
    }
  }, [connected]);

  React.useEffect(()=>{
    let products = shuffle(allCrushes);
    setShufflesProduct(products);
  }, [])
  return (
    <>
      <Helmet>
        <title>
          {crush.name} | Librairie Expérience : le coup de coeur des libraires
        </title>
        <meta
          name="description"
          content="Découvrez la sélection BD coups de coeur de nos libraires Jean-Louis, Camille, Jonathan et Maxime."
        />
      </Helmet>
      <div>
        <section className="bi-crush-header">
          <div
            className={
              "bi-crush-cover " +
              (crush.author_article === "Camille" && "blue_type ") +
              " " +
              (crush.author_article === "Jean-Louis" && "red_type ") +
              " " +
              (crush.author_article === "Jonathan" && "orange_type ") +
              " " +
              (crush.author_article === "Maxime" && "yellow_type ")
            }
          >
            <Marquee isCrush='crush' number="8">{crush.author_article}</Marquee>
            <picture>
              <source
                srcSet={
                  urlImageBuilder(
                    crush.cover.asset.path
                      .replace("image-", "")
                      .replace("-jpg", ".jpg"),
                    820,
                    null,
                    { ...crush.cover.crop, ...crush.cover.asset }
                  ) +
                  " 2x," +
                  urlImageBuilder(
                    crush.cover.asset.path
                      .replace("image-", "")
                      .replace("-jpg", ".jpg"),
                    410,
                    null,
                    { ...crush.cover.crop, ...crush.cover.asset }
                  ) +
                  " 1x"
                }
              />
              <img
                src={urlImageBuilder(
                  crush.cover.asset.path
                    .replace("image-", "")
                    .replace("-jpg", ".jpg"),
                  410,
                  null,
                  { ...crush.cover.crop, ...crush.cover.asset }
                )}
                alt={crush.cover.asset.altText}
              />
            </picture>
          </div>
          <div className="bi-crush-article">
            <h1 className="bi-title-secondary">{crush.name}</h1>
            <div className="bi-crush-information">
              <div>
                <p className="small">Auteur</p>
                <p>
                  {crush.authors.map((el) => {
                    return (
                      <>
                        {el}
                        <br />
                      </>
                    );
                  })}
                </p>
              </div>
              <div>
                <p className="small">Editeur</p>
                <p>{crush.editor}</p>
              </div>
              <div>
                <p className="small">Genre</p>
                <p>{crush.genre}</p>
              </div>
              <div>
                <p className="small">Volume</p>
                <p>{crush.volume}</p>
              </div>
              <div>
                <p className="small">Année</p>
                <p>{crush.year}</p>
              </div>
            </div>
            {connected && (
              <>
                {crushExisting ? (
                  <div
                    className="bi-information_plus-action"
                    onClick={async (event) => {
                      await removeFromCrush(crush, connected, id, event);
                      alert(
                        "Coup de coeur supprimé de votre liste de souhaits"
                      );
                      setCrushExisting(false);
                    }}
                  >
                    <IconButton type="wish-big" />{" "}
                    <p>Enlever de ma liste de souhaits</p>
                  </div>
                ) : (
                  <div
                    className="bi-information_plus-action"
                    onClick={async (event) => {
                      await addToCrush(crush, connected, id, event);
                      alert("Coup de coeur ajouté à votre liste de souhaits");
                      setCrushExisting(true);
                    }}
                  >
                    <IconButton type="wish-big" />{" "}
                    <p>Ajouter à ma liste de souhaits</p>
                  </div>
                )}
              </>
            )}
            <div className="bi-crush-content">
              <PortableText value={crush._rawContent} />
            </div>
          </div>
        </section>
        {allCrushes
          .filter((x) => x.author_article === crush.author_article)
          .filter((x) => x.name !== crush.name)
          .slice(-3).length > 0  && (
          <section className="bi-layout bi-crush-suggest">
            <GridSugest
              title={"Les autres coups de coeur de " + crush.author_article}
              items={shufflesProduct
                .filter((x) => x.author_article === crush.author_article)
                .filter((x) => x.name !== crush.name)
                .slice(-3)}
                heading={{title: "LES AUTRES COUPS DE COEUR DE " + crush.author_article.toUpperCase(), type: 'tertiary'}}
            >
              {shufflesProduct
                .filter((x) => x.author_article === crush.author_article)
                .filter((x) => x.name !== crush.name)
                .slice(-3)
                .map((crush) => {
                  let objectCrush = {
                    slug: crush.slug.current,
                    name: crush.name,
                    picture: crush.cover.asset.path,
                    cropData: { ...crush.cover.crop, ...crush.cover.asset },
                    author: crush.author_article,
                  };
                  console.log(objectCrush);
                  return (
                    <li key={crush.id} className='bi-slider_item'>
                      <CrushListItem crush={objectCrush} desktopWidth="" />
                    </li>
                  );
                })}
            </GridSugest>
          </section>
        )}
      </div>
    </>
  );
};

export default Page;
