import React from "react";

import "../../../assets/styles/pages/message.scss";
import Button from "../../../components/utils/atoms/Button.jsx";

const getCookie = (cName) => {
  // Split cookie string and get all individual name=value pairs in an array
  if (typeof window != "undefined") {
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
      if (cName === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }
  }
  // Return null if not found
  return null;
};

const Page = () => {
  const [orderData, setOrderData] = React.useState([]);
  const [sessionData, setSessionData] = React.useState({});
  const [sessionStructuredData, setSessionStructureData] = React.useState({
    clientName: null,
    clientEmail: null,
    clientProducts: [
      {
        productName: null,
        productPrice: null,
        productQuantity: null,
      },
    ],
    clientTotal: null,
    receiptUrl: null,
  });

  // Look at this if you want notify width custom mail
 /* const notifyNewOrder = () => {
    fetch("/.netlify/functions/send-mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order: { ...sessionStructuredData },
        type: "confirm-new-order",
      }),
    }).then((res) => {
      if (res.status === 200) {
        
      }
    });
  };*/
  // Page component when stripe payment is canceled
  React.useEffect(() => {
    // Get the session id from the url
    let cookieId = getCookie("lib-experience-user-id");
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );
    if (sessionId) {
      // Get the session data from the netlify function retrieve-checkout-session.js endpoint
      fetch("/.netlify/functions/retrieve-checkout-session", {
        method: "POST",
        body: JSON.stringify({ sessionId }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log('We need some data', data);
          // CReate an async function to Create order in the sanity database before showing the receipt at the netlify function send-order-cms.js endpoint
          const createOrder = async () => {
            let allReadyExisting = () => { return window.libOrder === data.session.payment_intent.id ? true : false; }
            let allReady = allReadyExisting();

            window.libOrder = data.session.payment_intent.id;
            const response = await fetch("/.netlify/functions/send-order-cms", {
              method: "POST",
              body: JSON.stringify({
                id: data.session.payment_intent.id,
                products: data.session.line_items.data.map((item) => ({
                  name: item.description,
                  quantity: item.quantity,
                })),
                total: data.session.payment_intent.amount / 100,
                receiptUrl:
                  data.session.payment_intent.charges.data[0].receipt_url,
                userId: cookieId ? cookieId : null,
                customerSessionId: sessionId,
                customerEmail: data.customer.email,
                customerShipping: Object.values(
                  data.customer.shipping.address
                ).join(", "),
                customerAddress: Object.values(data.customer.address).join(
                  ", "
                ),
                customerPhone: data.customer.phone,
                customerName: data.customer.name,
                allReadyExisting: allReady
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                setOrderData(data);
                console.log('data:', data)
              })
              .catch((err) => {
                console.log("Error: ", err);
              });

      
          };

          createOrder().then(() => {
            setSessionData(data);
            setSessionStructureData({
              clientAddress: Object.values(data.customer.address).join(", "),
              clientName: data.customer.name,
              clientEmail: data.customer.email,
              clientPhone: data.customer.phone,
              clientProducts: data.session.line_items.data.map((item) => ({
                productName: item.description,
                productPrice: item.price.unit_amount / 100,
                productQuantity: item.quantity,
              })),
              shippingAddress: Object.values(
                data.customer.shipping.address
              ).join(", "),
              clientTotal: data.session.payment_intent.amount / 100,
              receiptUrl:
                data.session.payment_intent.charges.data[0].receipt_url,
                allReadyExisting: orderData.allReadyExisting
            });
          });
        });
    }
  }, []);

  return (
    <>
      <section className="bi-message-page">
        <h1 className="bi-title-secondary">
          Merci pour <span className="bi-span-title-1">votre</span>{" "}
          <span className="bi-span-title-two">commande !</span>
        </h1>
        <div className="bi-recap-commande">
          {sessionData.session &&
            sessionData.customer &&
            sessionStructuredData && (
              <>
                <h2 className="bi-title-tertiary">
                  Recapitulatif de votre commande
                </h2>
                <hr />

                <ul>
                  {sessionStructuredData.clientProducts.map(
                    (product, index) => {
                      return (
                        <li key={index}>
                          <h3 className="bi-title-quaternary">
                            {product.productName}
                          </h3>
                          <div>
                            {product.productQuantity * product.productPrice}€
                          </div>
                        </li>
                      );
                    }
                  )}
                  <hr />
                  <li>
                    <p>Total</p>
                    <p>{sessionStructuredData.clientTotal}€</p>
                  </li>
                </ul>
                <div className="bi-recap-adress">
                  <p>
                    Adresse de facturation :{" "}
                    {sessionStructuredData.clientAddress}
                  </p>
                  <p>
                    Adresse de livraison :{" "}
                    {sessionStructuredData.shippingAddress}
                  </p>
                </div>
                <p>
                  Si vous ne voyez pas votre reçu par email vous pouvez y
                  accédder directement ici :{" "}
                  <a href={sessionStructuredData.receiptUrl} target="_blank" rel="noreferrer">
                    Reçu de paiements
                  </a>
                </p>
                <p>
                  En cas de problème contacter nous via{" "}
                  <a
                    href="mailto:lexperience.collectivites@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lexperience.collectivites[at]gmail.com
                  </a>
                </p>
              </>
            )}
          <Button type="link" className="bi-button black" to="/store">
            Accéder à la boutique
          </Button>
        </div>
      </section>
    </>
  );
};

export default Page;
