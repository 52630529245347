import * as React from "react";
import Contact from "../components/modules/forms/Contact";

import "../assets/styles/pages/contact.scss";
import { Helmet } from "react-helmet";
import Tag from "../components/utils/atoms/Tag";
import gsap from 'gsap';

import eventBus from "../assets/scripts/utils/eventBus";

const Page = () => {

  React.useEffect(()=>{
    /*gsap.fromTo(
      "h1 .title-line .word",
      {
        translateY: window.innerWidth > 768 ? 260 : 160,
        rotate: (index, target) => {
          return index % 2 ? -4 : 4;
        },
      },
      { translateY: 0, rotate: 0, stagger: 0.15 }
    ); */

    eventBus.on("pageTransitionEnd", () => {
      gsap.killTweensOf("h1 .title-line .word");
        gsap.fromTo(
          "h1 .title-line .word",
          {
            translateY: window.innerWidth > 768 ? 260 : 160,
            rotate: (index, target) => {
              return index % 2 ? -4 : 4;
            },
          },
          { translateY: 0, rotate: 0, stagger: 0.15 }
        );
    });

    eventBus.on("loaderEnd", () => {
      gsap.killTweensOf("h1 .title-line .word");
      gsap.fromTo(
        "h1 .title-line .word",
        {
          translateY: window.innerWidth > 768 ? 260 : 160,
          rotate: (index, target) => {
            return index % 2 ? -4 : 4;
          },
        },
        { translateY: 0, rotate: 0, stagger: 0.15 }
      );
    });
  }, [])

  return (
    <>
    <Helmet>
      <title>Contact et SAV | Librairie Expérience : librairie BD indépendante à Lyon</title>
      <meta name="description" content="Pour toutes questions relatives à une commande, un devis pour une production de sérigraphies dans notre atelier lyonnais, ou pour plus d’informations, veuillez remplir notre formulaire de contact." />
    </Helmet>
      <section className="bi-contact-header">
        <h1 className="bi-title-secondary">
          <span className="title-line">
              <span className="word">Une question ? </span>
            </span>{" "}
            <span className="title-line">
              <span className="word">Nous sommes là pour</span>
            </span>
            <span className="title-line">
              <span className="word">vous répondre</span>
            </span>
          <div className="bi-visibility-hidden bi-tags-wrapper">
              <Tag parallax-vitesse="0.60" id="h1-tag1" line="1" column="2">J'ai des questions au sujet d'un article ? </Tag>
              <Tag parallax-vitesse="0.20" id="h1-tag2" line="2" column="0">J'ai des questions au sujet de la librairie ? </Tag>
              <Tag parallax-vitesse="-0.20" id="h1-tag2" line="3" column="1">J'ai des questions au sujet d'une BD ? </Tag>
            </div>
        </h1>
      </section>
      <section className="bi-layout bi-contact-form_wrapper">
        <Contact />
        <address>
          <ul>
            <li>
              <h2 className="small">Adresse</h2>
              <p>
                5 place Antonin Poncet <br />
                69002 Lyon
              </p>
            </li>
            <li>
              <h2 className="small">Téléphone</h2>
              <a href="tel:+33472418414">04 72 41 84 14</a>
            </li>
            <li>
              <h2 className="small">Email</h2>
              <a href="mailto:mailto:librairieexperience@gmail.com" target="_blank">
                librairieexperience@gmail.com
              </a>
            </li>
          </ul>
        </address>
      </section>
    </>
  );
};

export default Page;
