import React from "react";
import Tabs from "../../components/modules/account/Tabs";
import "../../assets/styles/pages/account.scss";
import Product from "../../components/modules/cart/Product";
import Button from "../../components/utils/atoms/Button";
import { useSelector } from "react-redux";

const getFakeId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};
const fakeOrders = [
  {
    paymentIntentId: getFakeId(),
    created: "2020-05-17T12:00:00.000Z",
    total: 40,
    products: [
      {
        imageUrl: "https://via.placeholder.com/150",
        name: "Product 1",
        price: "10",
        quantity: 1,
      },
    ],
  },
  {
    paymentIntentId: getFakeId(),
    created: "2020-05-17T12:00:00.000Z",
    total: 40,
    products: [
      {
        imageUrl: "https://via.placeholder.com/150",
        name: "Product 1",
        price: "20",
        quantity: 2,
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        name: "Product 2",
        price: "20",
        quantity: 8,
      },
    ],
  },
];

const Page = () => {
  const [orders, setOrders] = React.useState([]);
  const { connected, id, data } = useSelector((state) => state.authentication);

  React.useEffect(() => {
    if (connected) {
      if (data.ordersData && data.ordersData.length > 0) {
        let newOrders = [];
        data.ordersData.forEach((el) => {
          const total = () => {
            let total = 0;
            console.log("el", el.products);
            el.products.forEach((p) => {
              total += p.price * p.quantity;
            });
            return total;
          };

          const products = () => {
            let productsArray = [];
            el.products.forEach((p) => {
              let obj = {
                imageUrl: `https://cdn.sanity.io/images/rpq9eend/production/${p.cover.asset._ref
                  .replace("image-", "")
                  .replace("-jpg", ".jpg")
                  .replace("-png", ".png")}`,
                name: p.name,
                price: p.price,
                quantity: p.quantity,
                slug: p.slug.current
              };
              productsArray.push(obj);
            });
            return productsArray;
          };

          let obj = {
            created: el._createdAt,
            paymentIntentId: el.id,
            total: total(),
            products: products(),
          };
          newOrders.push(obj);
        });
        setOrders(newOrders);
        console.log("new Orders: ", newOrders);
      }
    }
  }, [connected]);

  return (
    <section className="bi-account-c">
      <Tabs />
      <div className="bi-layout">
        <div className="bi-account-c_content">
        <div className="bi-title_wrapper">
          <h1 className="bi-title-tertiary">
            {!(orders.length > 0)
              ? "Vous n’avez passé aucune commande..."
              : "Mes commandes"}
          </h1>
          </div>
          {!(orders.length > 0) && (
            <Button className="bi-button yellow" type="text">
              Accéder à la boutique
            </Button>
          )}
          {orders.length > 0 &&
            orders.map((order, index) => {
              return (
                <div className="bi-account-c_order" key={index}>
                  <div className="bi-account-c_order-header">
                    <h2
                      className="bi-title-quaternary"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {new Date(order.created).toLocaleDateString("fr-FR", {
                        weekday: "long",
                        month: "long",
                        year: "numeric",
                        day: "numeric",
                      })}
                    </h2>{" "}
                    <p>Numéro de commande: {order.paymentIntentId}</p>
                  </div>
                  <ul className="bi-account-c_order-products">
                    {order.products.length > 0 &&
                      order.products.map((product, index) => {
                        return (
                          <li key={index}>
                            <Product product={product} link={true}>
                              <p>Quantité : {product.quantity}</p>
                            </Product>
                          </li>
                        );
                      })}
                  </ul>
                  <hr />
                  <p>
                    Total : <span>{order.total}€</span>
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Page;
