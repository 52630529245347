import React from "react";

import "../../../assets/styles/components/utils/atoms/tag.scss";

const Tag = (props) => {
  const [isDesktopState, setIsdesktop] = React.useState(true);

  const isDesktop = () => {
    if (
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    ) {
      return window?.innerWidth > 768;
    }
  };
  React.useEffect(() => {
    setIsdesktop(isDesktop());
    document.addEventListener("resize", () => {
      setIsdesktop(isDesktop());
    });
  }, []);
  return (
    <>
      {isDesktopState ? (
        <div
          data-parallax
          data-p-vitesse={props["parallax-vitesse"] ? parseFloat(props["parallax-vitesse"]) : '0.4'}
          data-p-direction="top"
          {...props}
          className="bi-tag"
        >
          {props.children}
        </div>
      ) : (
        <div {...props} className="bi-tag">
          {props.children}
        </div>
      )}
    </>
  );
};

export default Tag;
