import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../utils/atoms/Button";

// photos membre

import LaLibrairieJLMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-mobile.jpg";
import LaLibrairieJLMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-mobile@2x.jpg";

import LaLibrairieCMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-c-mobile.jpg";
import LaLibrairieCMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-c-mobile@2x.jpg";

import LaLibrairieJMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-j-mobile.jpg";
import LaLibrairieJMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-j-mobile@2x.jpg";

import LaLibrairieMMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-m-mobile.jpg";
import LaLibrairieMMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-m-mobile@2x.jpg";

import LaLibrairieJLDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-desktop.jpg";
import LaLibrairieJLDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-desktop@2x.jpg";

import LaLibrairieCDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-c-desktop.jpg";
import LaLibrairieCDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-c-desktop@2x.jpg";

import LaLibrairieJDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-j-desktop.jpg";
import LaLibrairieJDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-j-desktop@2x.jpg";

import LaLibrairieMDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-m-desktop.jpg";
import LaLibrairieMDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-m-desktop@2x.jpg";

import "../../../assets/styles/components/modules/about/partner.scss";
import IconButton from "../../utils/atoms/IconButton";
import { setTeamMember } from "../../../assets/scripts/store/redux-slices/modals";

import { SwitchTransition, Transition } from "react-transition-group";
import eventBus from "../../../assets/scripts/utils/eventBus";
import gsap from "gsap";

const Partner = () => {
  const [loaded, setLoaded] = React.useState(false);
  const teamMember = useSelector((state) => state.modals.teamMember);
  const dispatch = useDispatch();
  const data = {
    partners: [
      {
        name: "Jean-Louis",
        description:
          "Jean-Louis c’est la mémoire du lieu ! Il a connu la librairie à la fin des années 80, à l’époque où la compta se faisait sur des carnets en papier. Il partage son temps entre la librairie et l’atelier de sérigraphie, c’est lui qui est à l’origine de tous nos projets d’images. Sinon c’est aussi Jean-Louis qui, quand il décroche le téléphone, dit : « Boucherie Sanzot, bonjour ! » … les fans de Tintin auront la réf. Ses premiers souvenirs bd, c’est sur les genoux de son grand-père qu’il les a vécus. “C’est mon grand-père qui m’a initié à la bande-dessinée, je me souviens très bien de la première histoire qu’il m’a lu, La Conga des bananes de Corto Maltese !” Il avait bon goût grand papa !",
        crushes: [
          {
            name: "Corto Maltese, d'Hugo Pratt",
          },
          {
            name: "Théodore Poussin, de Franck Le Gall",
          },
          {
            name: "Les phalanges de l'ordre noir, d'Enki Bilal et Pierre Christin",
          },
        ],
        images: [
          { imgMobile: LaLibrairieJLMobile },
          { imgMobileRetina: LaLibrairieJLMobileRetina },
          { imgDesktop: LaLibrairieJLDesktop },
          { imgDesktopRetina: LaLibrairieJLDesktopRetina },
        ],
      },
      {
        name: "Camille",
        description:
          "Camille commence sa carrière en dévorant Le Journal de Spirou, les Witch mag puis les Lanfeust mag. Elle va acheter ses bd chaque mois quand elle a rendez-vous chez l’orthodontiste et pique celles de ses parents. Aujourd’hui, la benjamine de l’équipe, qui avoue avoir un penchant pour les histoires poignantes, est la grande prêtresse du rayon jeunesse ! Sa technique pour trouver la bd qu’il vous faut : être à l’écoute ! Car comme elle dit : “Il y a tellement de styles et de graphismes différents qu’il y a forcément une bd faite pour vous !”",
        crushes: [
          {
            name: "La tendresse des crocodiles, de Fred Bernard",
          },
          {
            name: "La quête de l’oiseau du temps, de Loisel & Le Tendre",
          },
          {
            name: "Jojo, de Geerts",
          },
        ],
        images: [
          { imgMobile: LaLibrairieCMobile },
          { imgMobileRetina: LaLibrairieCMobileRetina },
          { imgDesktop: LaLibrairieCDesktop },
          { imgDesktopRetina: LaLibrairieCDesktopRetina },
        ],
      },
      {
        name: "Jonathan",
        description:
          "Jonathan entre dans le monde de la bd en lisant les aventures d’un village d’irréductibles gaulois, mais la révélation, il l’a en descendant dans le bureau de tabac de son quartier. “Je devais avoir un truc comme 8 ans, j’ai ouvert un comics et je suis tombé dedans ! À l’époque, on en trouvait moins qu’aujourd’hui et j’attendais comme un forcené les maigres parutions de Strange et Titans !”. Vous l’aurez compris, c’est lui qui bichonne le rayon comics. D’abord client fidèle de la librairie, il y entre en 2010 en tant que stagiaire et n’a (presque) jamais quitté son poste depuis. Jon a une très grosse voix et l’écouter pitcher les bd c’est un régal.",
        crushes: [
          {
            name: "Watchmen, d’Alan Moore & Dave Gibbons",
          },
          {
            name: "Sin City, de Frank Miller",
          },
          {
            name: "Transmetropolitan, de Warren Ellis & Darrick Robertson",
          },
        ],
        images: [
          { imgMobile: LaLibrairieJMobile },
          { imgMobileRetina: LaLibrairieJMobileRetina },
          { imgDesktop: LaLibrairieJDesktop },
          { imgDesktopRetina: LaLibrairieJDesktopRetina },
        ],
      },
      {
        name: "Maxime",
        description:
          "Amoureux des mangas depuis tout petit, Maxime lit presque autant qu’il joue à Pokémon (c’est vous dire !). Il a poussé les murs pour faire une belle place à ce genre et adore partager ses coups de cœur. Son véritable but : faire lire One Piece à un maximum de monde ! Auto-proclamé “maître de la bonne musique”, c’est certainement lui qui a concocté la playlist qui tourne dans la librairie au moment de vos visites.",
        crushes: [
          {
            name: "One Piece, d’Eiichiro Oda",
          },
          {
            name: "Bonne nuit Punpun, d’Inio Asano",
          },
          {
            name: "Arms, de Ryōji Minagawa",
          },
        ],
        images: [
          { imgMobile: LaLibrairieMMobile },
          { imgMobileRetina: LaLibrairieMMobileRetina },
          { imgDesktop: LaLibrairieMDesktop },
          { imgDesktopRetina: LaLibrairieMDesktopRetina },
        ],
      },
    ],
  };

  const partner = data.partners.find(
    (partner) => partner.name === teamMember.name
  );

  const getNextName = (name) => {
    const names = data.partners.map((el) => {
      return el.name;
    });

    let currentIndex = names.indexOf(name);
    let maxIndex = names.length - 1;
    const prevIndex = () => {
      if (currentIndex === 0) {
        return maxIndex;
      } else {
        return currentIndex - 1;
      }
    };
    const nextIndex = () => {
      if (currentIndex === maxIndex) {
        return 0;
      } else {
        return currentIndex + 1;
      }
    };

    return { prev: names[prevIndex()], next: names[nextIndex()] };
  };
  const nextSlider = () => {
    dispatch(
      setTeamMember({
        name: getNextName(partner.name).next,
        open: true,
      })
    );
  };

  const prevSlider = () => {
    dispatch(
      setTeamMember({
        name: getNextName(partner.name).prev,
        open: true,
      })
    );
  };

  const isDesktop = () => {
    if (typeof window !== 'undefined' &&
      window.document && window.document.createElement) {
      return window?.innerWidth > 768;
    }
  };

  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      onLeave(isDesktop(), 'rc');
    });
  }, []);
  React.useEffect(() => {
    if (teamMember.name) {
      onAppear(isDesktop());
    }
  }, [teamMember]);
  /**
   * Animation functions
   * -{} onAppear
   * - onLeave
   * */

  const onAppear = (isDesktop) => {
    if (loaded === false) {
      eventBus.dispatch("scrollStop");
      let tl = gsap.timeline();
      tl.from(".partner .container", {
        translateX: isDesktop ? "50vw" : "100vw",
        rotate: -8,
        height: "110vh",
        translateY: "-10vh",
      });
      tl.fromTo(
        ".partner .container > div",
        { opacity: 0, translateY: 20 },
        {
          opacity: 1,
          translateY: 0,
          onComplete: () => {
            setLoaded(true);
          },
        }
      );
    }
  };

  const onLeave = (isDesktop, rc) => {
    if (!rc) {
      eventBus.dispatch("scrollStart");
    }
    let tl = gsap.timeline();
    tl.fromTo(
      ".partner .container > div",
      { opacity: 1, translateY: 0 },
      { opacity: 0, translateY: -20 }
    );
    tl.to(".partner .container", {
      translateX: isDesktop ? "50vw" : "100vw",
      onComplete: () => {
        dispatch(
          setTeamMember({
            name: "",
            open: false,
          })
        );
        setLoaded(false);
      },
    });
  };
  if (teamMember.open && teamMember.name) {
    return (
      <div className="bi-layout partner">
        <SwitchTransition mode={"out-in"}>
          <Transition
            key={teamMember.name}
            unmountOnExit
            timeout={300}
            onEnter={(node) => {
              gsap.from(node.querySelector(".partner__content"), {
                opacity: 0,
                translateY: -20,
              });
            }}
            onExit={(node) => {
              gsap.to(node.querySelector(".partner__content"), {
                opacity: 0,
                translateY: 20,
              });
            }}
          >
            <div className="container">
              <div className="partner__header">
                <IconButton
                  type="modal-cross"
                  onClick={() => {
                    onLeave();
                  }}
                />
              </div>
              <div
                className="partner__content overflow"
                onWheel={(event) => {
                  event.stopPropagation();
                }}
              >
                <div>
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet={
                        partner.images[1].imgMobileRetina +
                        " 2x," +
                        partner.images[0].imgMobile +
                        " 1x"
                      }
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={
                        partner.images[3].imgDesktopRetina +
                        " 2x," +
                        partner.images[2].imgDesktop +
                        " 1x"
                      }
                    />
                    <img src={partner.images[0].imgMobile} alt="" />
                  </picture>
                  <div className="partner__nav">
                    <div>
                      <IconButton
                        type="white-slider-prev"
                        onClick={() => {
                          prevSlider();
                        }}
                      />
                      <h2 className="bi-title-tertiary">{partner.name}</h2>
                      <IconButton
                        type="white-slider-next"
                        onClick={() => {
                          nextSlider();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p>{partner.description}</p>
                <div className="partner__favorites">
                  <h3 className="bi-title-quaternary">3 de ses BD préférées</h3>
                  <ul>
                    <li>{partner.crushes[0].name.split(',').map((el, index) => {
                      if (index === 0) {
                        return (<>{el}</>)
                      } else {
                        return (<><span className="no-italic">, 
                          {el}
                        </span></>)
                      }
                    })}</li>
                    <li>{partner.crushes[1].name.split(',').map((el, index) => {
                      if (index === 0) {
                        return (<>{el}</>)
                      } else {
                        return (<><span className="no-italic">, 
                          {el}
                        </span></>)
                      }
                    })}</li>
                    <li>{partner.crushes[2].name.split(',').map((el, index) => {
                      if (index === 0) {
                        return (<>{el}</>)
                      } else {
                        return (<><span className="no-italic">, 
                          {el}
                        </span></>)
                      }
                    })}</li>
                  </ul>
                </div>
              </div>
              <div className="partner__cta">
                <Button
                  className="bi-button yellow"
                  type="link"
                  to={"/coups-de-coeur/" + '?filter=' + teamMember.name}
                >
                  Voir ses coups de coeur
                </Button>
              </div>
            </div>
          </Transition>
        </SwitchTransition>
        <div
          onClick={() => {
            dispatch(
              setTeamMember({
                name: "",
                open: false,
              })
            );
          }}
          className="bi-partner-modal__close"
        ></div>
      </div>
    );
  } else {
    return null;
  }
};

export default Partner;
