import * as React from "react";

import Button from "../../components/utils/atoms/Button.jsx";
import { Login } from "../../components/modules/forms/Login.jsx";
import "../../assets/styles/pages/account.scss";
import { Helmet } from "react-helmet";

const Page = () => {
  return (
    <>
    <Helmet>
      <title>Se connecter | Librairie Expérience : connectez-vous à votre espace personnel</title>
      <meta name="description" content="Connectez-vous à votre compte personnel ou professionnel pour passer commandes et profitez d’offres exclusives." />
    </Helmet>
    <section className="bi-layout bi-account">
      <div className="bi-account-card" /*onWheel={(e)=>{      <--------------------------------------- Pq ?
        e.preventDefault();
        e.stopPropagation()
      }}*/>
        <h1 className="bi-title-quaternary">Connexion</h1>
        <Login />
        <Button type='link' to="/account/inscription" className="bi-button black login-create-account">
          Créer un compte
        </Button>
      </div>
    </section>
    </>
  );
};

export default Page;
