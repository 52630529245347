import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromCart } from "../../assets/scripts/store/redux-slices/cart";
import { setCart } from "../../assets/scripts/store/redux-slices/modals";

import IconButton from "../utils/atoms/IconButton";

import "../../assets/styles/components/modules/cart.scss";

import Product from "../modules/cart/Product";
import Button from "../utils/atoms/Button";
import eventBus from "../../assets/scripts/utils/eventBus";

// Animation library
import gsap from "gsap";

const Cart = (props) => {
  const cartIsOpen = useSelector((state) => state.modals.cart);
  const cartProducts = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleSTripeSession = (e) => {
    e.preventDefault();

    // Post data to create-checkout-session netlify function endpoint
    const data = {
      products: cartProducts.cart,
      baseUrl: window.location.origin,
      total: cartProducts.total,
    };

    fetch("/.netlify/functions/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("Result", result);
        // redirect user to checkout page
        window.location.href = result.url;
      })
      .catch((err) => console.log(err));
  };

  const isDesktop = () => {
    if (
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    ) {
      return window?.innerWidth > 768;
    }
  };

  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      onLeave(isDesktop(), "rc");
    });
  }, []);

  React.useEffect(() => {
    if (cartIsOpen) {
      onAppear(isDesktop());
    }
  }, [cartIsOpen]);
  /**
   * Animation functions
   * - onAppear
   * - onLeave
   * */

  const onAppear = (isDesktop) => {
    eventBus.dispatch("scrollStop");
    let tl = gsap.timeline();
    tl.from(".bi-cart-wrapper", {
      translateX: isDesktop ? "50vw" : "100vw",
      rotate: -8,
      height: "110vh",
      translateY: "-10vh",
    });
    tl.fromTo(
      ".bi-cart-wrapper > div",
      { opacity: 0, translateY: 20 },
      { opacity: 1, translateY: 0 }
    );

    if (document.querySelectorAll(".bi-cart-wrapper .title-line .word")) {
      tl.from(".bi-cart-wrapper .title-line .word", {
        translateY: "160px",
        stagger: 0.15,
        rotate: (index, target) => {
          return index % 2 ? -4 : 4;
        },
      });
    }

    tl.fromTo(".bi-cart-wrapper .bi-button.yellow", { opacity: 0 }, {opacity: 1});
  };

  const onLeave = (isDesktop, rc) => {
    let tl = gsap.timeline({
      onComplete: () => {
        if (!rc) {
          eventBus.dispatch("scrollStart");
        }
      },
    });
    tl.fromTo(
      ".bi-cart-wrapper > div",
      { opacity: 1, translateY: 0 },
      { opacity: 0, translateY: -20 }
    );
    tl.to(".bi-cart-wrapper", {
      translateX: isDesktop ? "50vw" : "100vw",
      onComplete: () => {
        dispatch(setCart(false));
        eventBus.dispatch("scrollStart");
      },
    });
  };

  if (cartIsOpen) {
    return (
      <div className="bi-cart bi-layout">
        <div className="bi-cart-wrapper">
          <div className="bi-cart-header">
            <p className="bi-title-quaternary">Panier</p>
            <div
              onClick={() => {
                onLeave();
              }}
            >
              <IconButton type="modal-cross" />
            </div>
            <div className="bi-cart-modal__close"></div>
          </div>
          <div
            className={
              "bi-cart-center" +
              (cartProducts.cart.length > 0 ? " initial" : "")
            }
            onWheel={(event) => {
              event.stopPropagation();
            }}
          >
            <ul>
              {cartProducts.cart.length > 0 ? (
                cartProducts.cart.map((cartItem, index) => {
                  return (
                    <li key={index}>
                      <Product product={cartItem}>
                        <Button type="quantity" product={cartItem} />{" "}
                        <Button
                          type="text"
                          onClick={() => {
                            dispatch(removeProductFromCart(cartItem));
                          }}
                        >
                          Supprimer
                        </Button>
                      </Product>
                    </li>
                  );
                })
              ) : (
                <div className="bi-cart-empty">
                  <p className="bi-title-secondary">
                    <span className="title-line">
                      <span className="word">Votre</span>
                    </span>{" "}
                    <span className="title-line">
                      <span className="word">panier </span>
                    </span>
                    <span className="title-line">
                      <span className="word">est vide</span>
                    </span>
                  </p>
                  <Button className="bi-button yellow" type="link" to="/store/">
                    Accéder à la boutique
                  </Button>
                </div>
              )}
            </ul>
          </div>
          {cartProducts.cart.length > 0 ? (
            <>
              {" "}
              <div className="bi-cart-bottom">
                <p>Total</p> <p>{cartProducts.total} €</p>
                <Button
                  className="bi-button yellow"
                  onClick={(e) => {
                    handleSTripeSession(e);
                  }}
                >
                  Passer à la caisse
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Cart;
