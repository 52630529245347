import React from "react";
import ProductListItem from "../modules/products/ProductListItem";

import "../../assets/styles/sections/storeList.scss";
import Button from "../utils/atoms/Button";

const StoreList = ({ products, title, description }) => {
  const list = React.useRef(null);
  const initialArray = products.slice(0, 4);
  const [remainingValues, setRemainingValues] = React.useState(
    products.slice(5, products.length - 1)
  ); // Créer un tableau avec les valkeurs qui restent
  const [lastEndItem, setLastEndItem] = React.useState(
    products.length < 5 ? false : 4
  );
  const [currentProducts, setCurrentProducts] = React.useState(initialArray);

  const getMoreProduct = (type) => {
    if (lastEndItem) {
      setRemainingValues();
      let newArray = products.slice(lastEndItem, lastEndItem + 8);
      setCurrentProducts([...currentProducts, ...newArray]);
      setCurrentProducts((value) => {
        if (currentProducts.length != products.length && newArray.length > 7) {
          setLastEndItem(lastEndItem + 8);
        } else {
          setLastEndItem(false);
        }
        return value;
      });
    }
  };

  const handleImageRatio = () => {
    const images = list.current.querySelectorAll("img");

    const fitImage = (image) => {
      const aspectRatio = image.naturalWidth / image.naturalHeight;

      // If image is landscape
      if (aspectRatio > 1) {
        image.style.width = "100%";
        image.style.height = "auto";
        image.dataset.ratio = 'landcape';
      }

      // If image is portrait
      else if (aspectRatio < 1) {
        image.style.width = "auto";
        image.style.maxHeight = "100%";
        image.dataset.ratio = 'portrait';
      }

      // Otherwise, image is square
      else {
        image.style.maxWidth = "100%";
        image.style.height = "auto";
        image.dataset.ratio = 'square';
      }
    };
    Array.from(images).forEach((image) => {
      image.addEventListener("load", () => {
        fitImage(image);
      });

      if (image.complete && image.naturalWidth !== 0) fitImage(image);
    });
  };

  React.useEffect(()=>{
    handleImageRatio();
  }, [currentProducts])
  return (
    <>
      <section className="bi-store_wrapper" ref={list}>
        <div className="bi-store bi-layout">
          <div className="bi-store_header">
            {" "}
            <picture></picture>
            <div>
              <h2>
                {title} <div className="content">{products.length}</div>
              </h2>
              <p className="bi-store_content-description">
                <span>{description}</span>
              </p>
            </div>
          </div>
          {currentProducts.map((product, index) => {
            return <ProductListItem product={product} key={index} />;
          })}
        </div>
        {lastEndItem && (
          <Button
            type="text"
            className="bi-store_link"
            onClick={() => {
              getMoreProduct(title);
            }}
          >
            Voir plus
          </Button>
        )}
      </section>

      {/* <section className="bi-store_wrapper">
        <div className="bi-store bi-layout">
          <div className="bi-store_header">
            {" "}
            <picture></picture>
            <div className="bi-store_content">
              <h2>
                title <div className="content">products.lentgh</div>
              </h2>
              <p className="bi-store_content-description">
                <span>description</span>
              </p>
            </div>
          </div>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
        </div>
        <Button type="text" className="bi-store_link">Voir plus</Button>
      </section> */}
    </>
  );
};

export default StoreList;
