import React from "react";

import { SwitchTransition, CSSTransition } from "react-transition-group";

import "../../../assets/styles/components/modules/about/dateSlider.scss";
import IconButton from "../../utils/atoms/IconButton";
import Tag from "../../utils/atoms/Tag";

const DateSlider = () => {
  const track = React.useRef();
  const nodeRef = React.useRef(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const getVWtoPixel = (value, vw) => {
    let pxValue = (value * vw) / 100;

    return pxValue;
  };
  const data = [
    {
      year: 1973,
      content:
        "Adrienne Krikorian, un petit bout de femme au fort caractère et au grand cœur ouvre la librairie Expérience, rue du Petit David en plein centre de Lyon. C’est l’une des plus anciennes librairies bd de France et son nom lui vient, bien sûr, du groupe de rock The Jimi Hendrix Experience ! Ça vous donne le ton …",
    },
    {
      year: 1990,
      content:
        "La librairie déménage place Antonin Poncet. Pour s’occuper de cette nouvelle boutique Adrienne fait confiance à Jean-Louis, un jeune vendeur de santiags passionné de bd qui traine chez Expérience depuis un moment déjà.",
    },
    {
      year: "1993 - 1998",
      content:
        "Durant cette (trop courte) période le magasin est divisé en deux et c’est Stéphane qui, dans la deuxième salle, gère la partie … disquaire ! Pendant un temps il y a même la radio Couleur 3 qui s’installe dans l’alcôve et Mathias le roi de l’électro qui bosse avec Stéphane. Peut-être les années les plus folles du magasin.",
    },
    {
      year: 2000,
      content:
        "Adrienne (devenue « Mamie » pour les intimes) décide de passer la main et propose à Jean-Louis de racheter la librairie. Ce dernier s’associe à Nicolas un client désireux de changer de vie ! Pendant 20 ans ce célèbre duo sera accompagné par des employés tous différents mais partageant la même passion : la bd ! Il y a eu Nathalie, Agathe, David, Pierre, Landry, Raphaëlle, Josselin, Youssef, Jonathan, Maxime puis Camille.",
    },
    {
      year: 2004,
      content:
        "Expérience se lance dans une nouvelle aventure, la sérigraphie ! Les éditions Cornélius vendent leur machine d’impression, c’est l’occasion qui fait le larron. On se lance timidement dans le bain puis avec les années et l’embauche de Renaud en 2011 on démarre sérieusement l’activité. Marie le rejoindra en 2021 et leur trio, formé avec Jean-Louis, nous régale de merveilleuses réalisations.",
    },
    {
      year: 2007,
      content:
        "C’est l’année où on se lance dans le *Projet Bermuda* : un recueil d’histoires courtes au format bd. L’idée était d’éditer des lyonnais pour promouvoir la création locale. Les bouquins étaient vendus en librairie et offerts aux éditeurs pour les encourager à publier ces auteurs. Le projet - et l’envie plus générale de soutenir la création - émanait des nocturnes organisées tous les troisièmes jeudis du mois où se rencontraient lecteurs, auteurs et éditeurs. Ces événements ont permis la signature de plusieurs ouvrages. Après 8 tomes on décide d’extraire la production du *Projet Bermuda* de la librairie et on monte la maison d’édition associative Expé Éditions, qui vole aujourd’hui de ses propres ailes.",
    },
    {
      year: 2009,
      content:
        "Jean-Marc, le « meilleur client de la librairie » quitte tout pour ouvrir son magasin bd à Villeurbanne. Il souhaite monter une franchise mais ça ne correspond pas à notre ADN 100% indé alors on lui permet quand même d’utiliser le nom. Expérience Bis est née ! Nous sommes bien deux magasins différents et à part beaucoup d’amour il n’y a rien entre nous.",
    },
    {
      year: 2021,
      content:
        "Une nouvelle page se tourne, Mamie s’est éteinte un an plus tôt et Nicolas nous quitte pour changer de vie, à nouveau. Jonathan, Maxime et Camille reprennent le flambeau et s’associent, toujours avec Jean-Louis ! À suivre…",
    },
  ];

  React.useEffect(()=>{
    window.addEventListener('resize', center);
    return () => window.removeEventListener('resize', center);
  },[currentIndex])

  const center = ()=>{
    setTimeout(() => {
      document.querySelector(
        ".bi-dateSlider_frise"
      ).style.transform = `translateX(-${
        currentIndex *
        getVWtoPixel(window.innerWidth < 769 ? 100 : 33, window.innerWidth)
      }px)`;
      document.querySelector(
        ".bi-dateSlider_frise .date-slider_nav"
      ).style.transform = `translateX(${
        currentIndex *
        getVWtoPixel(window.innerWidth < 769 ? 100 : 33, window.innerWidth)
      }px)`;
    }, 100);
  }


  React.useEffect(() => {
    setTimeout(() => {
      document.querySelector(
        ".bi-dateSlider_frise"
      ).style.transform = `translateX(-${
        currentIndex *
        getVWtoPixel(window.innerWidth < 769 ? 100 : 33, window.innerWidth)
      }px)`;

      if (window.innerWidth < 769) {
        document.querySelector(
          ".bi-dateSlider_frise .mobile"
        ).style.transform = `translateX(${
          currentIndex *
          getVWtoPixel(100, window.innerWidth)
        }px)`;
      }else{
        document.querySelector(
          ".bi-dateSlider_frise .desktop"
        ).style.transform = `translateX(${
          currentIndex *
          getVWtoPixel(33, window.innerWidth)
        }px)`;
      }


    }, 100);
  }, [currentIndex]);

  const nextSlider = () => {
    if (currentIndex < 7) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlider = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="bi-dateSlider">
      <h2 className="bi-title-tertiary">
        <span className="bi-title-span">
          Et d’ailleurs, pourquoi Expérience ?
        </span>
        <Tag>
          Pour Jimi Hendrix, bien-sûr !
        </Tag>
      </h2>
      <p>
        La Librairie Expérience est une entité du milieu de la BD, découvrez en
        quelques dates, une partie de son histoire depuis son ouverture en 1973.
      </p>
      <div className="bi-dateSlider_frise" ref={track}>
        <div className="date-slider_nav desktop">
          <div
            className="date-slider_nav-left"
            onClick={() => {
              prevSlider();
            }}
          >
            <IconButton type={currentIndex > 0 ? "slider-prev" : "outline-slider-prev"} />
          </div>
          <div
            className="date-slider_nav-right"
            onClick={() => {
              nextSlider();
            }}
          >
            <IconButton type={currentIndex < 7 ? "slider-next" : "outline-slider-next"} />
          </div>
        </div>
        {data.map((el, index) => {
          return (
            <p
              key={index}
              className={
                "bi-title-tertiary " +
                (index === currentIndex ? "bi-date-active" : "")
              }
              onClick={() => {
                setCurrentIndex(index);
              }}
            >
              {el.year}
            </p>
          );
        })}
        <div className="date-slider_nav mobile">
          <div
            className="date-slider_nav-left"
            onClick={() => {
              prevSlider();
            }}
          >
           <IconButton type={currentIndex > 0 ? "slider-prev" : "outline-slider-prev"} />
          </div>
          <div
            className="date-slider_nav-right"
            onClick={() => {
              nextSlider();
            }}
          >
            <IconButton type={currentIndex < 7 ? "slider-next" : "outline-slider-next"} />
          </div>
        </div>
        <hr />
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={currentIndex}
          classNames="fade"
          nodeRef={nodeRef}
          timeout={500}
        >
          <p className="bi-dateSlider_content" ref={nodeRef}>
            {data[currentIndex].content}
          </p>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default DateSlider;
