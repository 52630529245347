import * as React from "react";
import gsap from 'gsap';

import eventBus from "../assets/scripts/utils/eventBus";
import Button from "../components/utils/atoms/Button";
import { Helmet } from "react-helmet";

import Tag from "../components/utils/atoms/Tag";
import laLibrairieDesktop from "../assets/images/pages/a-propos/about_lalibrairie_desktop_1x.jpg";
import laLibrairieDesktopRetina from "../assets/images/pages/a-propos/about_lalibrairie_desktop_2x.jpg";
import laLibrairieMobile from "../assets/images/pages/a-propos/about_lalibrairie_mobile_1x.jpg";
import laLibrairieMobileRetina from "../assets/images/pages/a-propos/about_lalibrairie_mobile_2x.jpg";

import laLibrairiePlafondDesktop from "../assets/images/pages/a-propos/about_lalibrairieplafond_desktop_1x.jpg";
import laLibrairiePlafondDesktopRetina from "../assets/images/pages/a-propos/about_lalibrairieplafond_desktop_2x.jpg";
import laLibrairiePlafondMobile from "../assets/images/pages/a-propos/about_lalibrairieplafond_mobile_1x.jpg";
import laLibrairiePlafondMobileRetina from "../assets/images/pages/a-propos/about_lalibrairieplafond_mobile_2x.jpg";

import laLibrairieFocusedBookDesktop from "../assets/images/pages/a-propos/about_block_desktop_1x.jpg";
import laLibrairieFocusedBookDesktopRetina from "../assets/images/pages/a-propos/about_block_desktop_2x.jpg";
import laLibrairieFocusedBookMobile from "../assets/images/pages/a-propos/about_book_mobile_1x.jpg";
import laLibrairieFocusedBookMobileRetina from "../assets/images/pages/a-propos/about_book_mobile_2x.jpg";

import laLibrairieFocusSerigraphieDesktop from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-desktop.jpg";
import laLibrairieFocusSerigraphieDesktopRetina from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-desktop@2x.jpg";
import laLibrairieFocusSerigraphieMobile from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-mobile.jpg";
import laLibrairieFocusSerigraphieMobileRetina from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-mobile@2x.jpg";

import laLibrairieTeamDesktop from "../assets/images/pages/a-propos/lalibrairie_team-desktop.jpg";
import laLibrairieTeamDesktopRetina from "../assets/images/pages/a-propos/lalibrairie_team-desktop@2x.jpg";
import laLibrairieTeamMobile from "../assets/images/pages/a-propos/lalibrairie_team-mobile.jpg";
import laLibrairieTeamMobileRetina from "../assets/images/pages/a-propos/lalibrairie_team-mobile@2x.jpg";

import laLibrairieDesktopWebp from "../assets/images/pages/a-propos/about_lalibrairie_desktop_1x.webp";
import laLibrairieDesktopRetinaWebp from "../assets/images/pages/a-propos/about_lalibrairie_desktop_2x.webp";
import laLibrairieMobileWebp from "../assets/images/pages/a-propos/about_lalibrairie_mobile_1x.webp";
import laLibrairieMobileRetinaWebp from "../assets/images/pages/a-propos/about_lalibrairie_mobile_2x.webp";

import laLibrairiePlafondDesktopWebp from "../assets/images/pages/a-propos/about_lalibrairieplafond_desktop_1x.webp";
import laLibrairiePlafondDesktopRetinaWebp from "../assets/images/pages/a-propos/about_lalibrairieplafond_desktop_2x.webp";
import laLibrairiePlafondMobileWebp from "../assets/images/pages/a-propos/about_lalibrairieplafond_mobile_1x.webp";
import laLibrairiePlafondMobileRetinaWebp from "../assets/images/pages/a-propos/about_lalibrairieplafond_mobile_2x.webp";

import laLibrairieFocusedBookDesktopWebp from "../assets/images/pages/a-propos/about_block_desktop_1x.webp";
import laLibrairieFocusedBookDesktopRetinaWebp from "../assets/images/pages/a-propos/about_block_desktop_2x.webp";
import laLibrairieFocusedBookMobileWebp from "../assets/images/pages/a-propos/about_book_mobile_1x.webp";
import laLibrairieFocusedBookMobileRetinaWebp from "../assets/images/pages/a-propos/about_book_mobile_2x.webp";

import laLibrairieFocusSerigraphieDesktopWebp from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-desktop.webp";
import laLibrairieFocusSerigraphieDesktopRetinaWebp from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-desktop@2x.webp";
import laLibrairieFocusSerigraphieMobileWebp from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-mobile.webp";
import laLibrairieFocusSerigraphieMobileRetinaWebp from "../assets/images/pages/a-propos/lalibrairie_focus-serigraphie-mobile@2x.webp";

import laLibrairieTeamDesktopWebp from "../assets/images/pages/a-propos/lalibrairie_team-desktop.webp";
import laLibrairieTeamDesktopRetinaWebp from "../assets/images/pages/a-propos/lalibrairie_team-desktop@2x.webp";
import laLibrairieTeamMobileWebp from "../assets/images/pages/a-propos/lalibrairie_team-mobile.webp";
import laLibrairieTeamMobileRetinaWebp from "../assets/images/pages/a-propos/lalibrairie_team-mobile@2x.webp";

// Load blurry image

import "../assets/styles/pages/a-propos.scss";
import AboutSlider from "../components/modules/about/slider";
import DateSlider from "../components/modules/about/dateSlider";

const Page = () => {
  const [isDesktop, setIsDesktop] = React.useState(true)

  const introAnimation = (isDesktop) => {
    gsap.killTweensOf(".bi-about-heading picture");
    gsap.fromTo(".bi-about-heading picture", { scale: isDesktop ? 0.9 : 0.95, y: isDesktop ? 16 : 8 }, { scale: 1, y: 0 })
    gsap.fromTo(".bi-about-heading picture img", { scale: isDesktop ? 1.1 : 1.05, y: isDesktop ? 16 : 8 }, { scale: 1.2, y: 0 })
    if (!isDesktop) {
      gsap.fromTo('.bi-about-resume', { opacity: 0 }, { opacity: 1, delay: 0.5 })
    }
  }
  React.useEffect(() => {
    setIsDesktop(() => {
      if (typeof window !== 'undefined' &&
        window.document && window.document.createElement) {
        return window?.innerWidth > 768;
      }
    });

    eventBus.on("pageTransitionEnd", () => {
      introAnimation(isDesktop)
    });
    eventBus.on("loaderEnd", () => {
      introAnimation(isDesktop)
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          À propos | Librairie Expérience : notre histoire et nos libraires
        </title>
        <meta
          name="description"
          content="Notre équipe de passionnés et spécialistes de la BD vous ouvre les portes de l’une des plus anciennes librairies de France. Fondée par Adrienne Krikorian en 1973 à Lyon, elle est mythique pour son plafond voûté recouvert de dessins d’auteurs."
        />
      </Helmet>
      <section className="bi-about">
        <h1>EXPÉRIENCE</h1>
        <div className="bi-layout bi-about-heading">
          <picture
            data-parallax={isDesktop}
            data-p-vitesse="-0.3"
            data-p-direction="bottom"
            >
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieMobileRetinaWebp + " 2x," + laLibrairieMobileWebp + " 1x"
              }
            />
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieMobileRetina + " 2x," + laLibrairieMobile + " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieDesktopRetinaWebp + " 2x," + laLibrairieDesktopWebp + " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieDesktopRetina + " 2x," + laLibrairieDesktop + " 1x"
              }
            />
            <img
              src={laLibrairieDesktop}
              alt="Image de la salle principale de la librairie expérience"
            />
          </picture>
        </div>

        <div className="bi-layout bi-about-resume">
          <picture
            data-parallax={isDesktop}
            // data-p-vitesse="-0.8"
            // data-p-scale="1.5"
            // data-p-direction="bottom"
          >
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairiePlafondMobileRetinaWebp +
                " 2x," +
                laLibrairiePlafondMobileWebp +
                " 1x"
              }
            />
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairiePlafondMobileRetina +
                " 2x," +
                laLibrairiePlafondMobile +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairiePlafondDesktopRetinaWebp +
                " 2x," +
                laLibrairiePlafondDesktopWebp +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairiePlafondDesktopRetina +
                " 2x," +
                laLibrairiePlafondDesktop +
                " 1x"
              }
            />
            <img
              src={laLibrairiePlafondDesktop}
              alt="Image de la salle principale de la librairie expérience"
            />
          </picture>
          <div>
            <h2 className="bi-title-quaternary">
              Un plafond voûté entièrement recouvert de dessins d’auteurs, une
              équipe de passionnés qui vous accueille comme à la maison, une
              ambiance conviviale et chaleureuse, bienvenue chez Expérience !{" "}
            </h2>
            <p>
              Notre librairie spécialisée dans la BD est située en plein coeur
              de Lyon, à quelques pas seulement de la place Bellecour. Vous
              pouvez venir découvrir notre magasin les lundis de 14h à 19h et
              du mardi au samedi de 10h à 19h.
            </p>
          </div>
        </div>
        <div className="bi-layout bi-about-cta">
          <h2 className="bi-title-tertiary">
            Nous proposons un large choix de BD franco-belges, de mangas et de
            comics.
          </h2>
          <p>
            Nous avons aussi à cœur de mettre en avant des ouvrages édités par
            des maisons d’édition indépendantes, et chez nous, vous trouverez de
            la lecture pour tous les âges. Fans de BD sous toutes ses formes,
            nous proposons un beau rayon para BD, avec des affiches, des
            sérigraphies et des figurines.
          </p>
          <Button type="link" className="bi-button yellow" to="/store">
            Accéder à la boutique
          </Button>
        </div>
        <picture
          data-parallax="true"
          data-p-direction="top"
          data-p-vitesse="0.25"
        >
          <source
            media="(max-width: 767px)"
            srcSet={
              laLibrairieFocusedBookMobileRetinaWebp +
              " 2x," +
              laLibrairieFocusedBookMobileWebp +
              " 1x"
            }
          />
          <source
            media="(max-width: 767px)"
            srcSet={
              laLibrairieFocusedBookMobileRetina +
              " 2x," +
              laLibrairieFocusedBookMobile +
              " 1x"
            }
          />
          <source
            media="(min-width: 768px)"
            srcSet={
              laLibrairieFocusedBookDesktopRetinaWebp +
              " 2x," +
              laLibrairieFocusedBookDesktopWebp +
              " 1x"
            }
          />
          <source
            media="(min-width: 768px)"
            srcSet={
              laLibrairieFocusedBookDesktopRetina +
              " 2x," +
              laLibrairieFocusedBookDesktop +
              " 1x"
            }
          />
          <img
            src={laLibrairieFocusedBookDesktop}
            alt="Image de la salle principale de la librairie expérience"
          />
        </picture>
        <div className="bi-layout bi-about-history">
          <div>
            <h2 className="bi-title-quaternary">
              Depuis 2007, Expérience fait aussi de la sérigraphie.{" "}
            </h2>
            <p>
              Notre atelier se trouve au 52 rue Saint-Michel dans le 7e
              arrondissement de Lyon. Là-bas on y exprime notre passion pour la
              BD autrement qu’au magasin : nous fabriquons de belles images de
              formats divers et variés. Que ça soit une commande d’un éditeur ou
              nos propres collections, nos sérigraphies sont toutes faites avec
              amour !
            </p>
            <br />
            <br />
            <p>Pour toute demande de devis c’est par là :</p>
            <br />
            <a href="mailto:lexperience.serico@free.fr" target="_blank">
              lexperience.serico@free.fr
            </a>
          </div>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieFocusSerigraphieMobileRetinaWebp +
                " 2x," +
                laLibrairieFocusSerigraphieMobileWebp +
                " 1x"
              }
            />
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieFocusSerigraphieMobileRetina +
                " 2x," +
                laLibrairieFocusSerigraphieMobile +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieFocusSerigraphieDesktopRetinaWebp +
                " 2x," +
                laLibrairieFocusSerigraphieDesktopWebp +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieFocusSerigraphieDesktopRetina +
                " 2x," +
                laLibrairieFocusSerigraphieDesktop +
                " 1x"
              }
            />
            <img src={laLibrairieFocusSerigraphieDesktop} alt="" />
          </picture>
        </div>
        <DateSlider />
        <div className="bi-layout bi-about-team">
          <div className="bi-about-team-content">
            <h2 className="bi-title-tertiary">Notre équipe</h2>
            <p>
              Venez nous voir, on aime mettre la musique un peu fort et raconter
              des conneries mais promis notre priorité sera de vous trouver des
              lectures incroyables. Chez Expérience, la bd c’est pour tout le
              monde.
            </p>
          </div>
          <AboutSlider />
        </div>
        <div className="bi-layout bi-about-teamPicture">
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieTeamMobileRetinaWebp +
                " 2x," +
                laLibrairieTeamMobileWebp +
                " 1x"
              }
            />
            <source
              media="(max-width: 767px)"
              srcSet={
                laLibrairieTeamMobileRetina +
                " 2x," +
                laLibrairieTeamMobile +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieTeamDesktopRetinaWebp +
                " 2x," +
                laLibrairieTeamDesktopWebp +
                " 1x"
              }
            />
            <source
              media="(min-width: 768px)"
              srcSet={
                laLibrairieTeamDesktopRetina +
                " 2x," +
                laLibrairieTeamDesktop +
                " 1x"
              }
            />
            <img src={laLibrairieTeamDesktop} alt="" />
            <div className="bi-visibility-hidden bi-tags-wrapper">
              <Tag parallax-vitesse='-0.25' id="h1-tag1" line="1" column="3">
                "CLICK"
              </Tag>
              <Tag parallax-vitesse='0.30' id="h1-tag2" line="2-2" column="0-2" >
                Chheeeeeessssssssee
              </Tag>
              <Tag parallax-vitesse='-0.35' id="h1-tag3" line="3" column="4">
                OUISTITI
              </Tag>
            </div>
          </picture>
        </div>
      </section>
    </>
  );
};

export default Page;
