import React from "react";
import "../../../assets/styles/components/modules/forms.scss";

import Button from "../../utils/atoms/Button.jsx";

const ResetEmail = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all fields are filled
    if (email === "") {
      setError("Email et/ou mot de passe incorrect");
      setSuccess("");
      setLoading(false);
      let mailInput = document.querySelector('input[type="email"]');
      mailInput.classList.add("borderError");
      return;
    }

    // Send Email to netlify function for checking is email is existing
    setLoading(true);
    fetch("/.netlify/functions/reset-user", {
      method: "POST",
      body: JSON.stringify({
        email,
        baseUrl: window ? window.location.origin : "http://localhost:3000",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      // Si l'email ou la reqête à bien fonctionner

      if (res.status === 200) {
        // get res reset url
        res.json().then((data) => {
          // send  async email width reset url and show the url as a message
          fetch("/.netlify/functions/send-mail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Email: email,
              Name: data.name,
              url: data.url,
              type: "lost-password",
              TemplateID: "4036411",
            }),
          }).then((res) => {
            if (res.status === 200) {
              setSuccess(
                "Un email vous à était envoyer avec cette url " + data.url
              );
              setError("");
              setLoading(false);
            }
          });
        });
      } else {
        setError("Cet email ne semble être associé à aucun compte");
        setSuccess("");
        setLoading(false);
      }
    });
    // && if email is exiting trigger an link for reseting password
  };
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      <div className="bi-form-loader">
        {/*<!--- Loading manager --->*/}
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <Button className="bi-button yellow" type="submit" value="Envoyer">Envoyer</Button>
    </form>
  );
};

export default ResetEmail;
