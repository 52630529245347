import React from "react";

import "../../../assets/styles/components/utils/atoms/input-newsletter.scss";
import IconButton from "./IconButton";
const InputNewsletter = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // send request to netlify function login-user
    setLoading(true);
    fetch("/.netlify/functions/subscribe-newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        from: "website"
      }),
    }).then((res) => {
      if (res.status === 200) {
        setSuccess("Vous êtes bien abonnés !");
        setError("");
        setLoading(false);
        res.json().then((data) => {
          console.log("data", data[0]);
        });
      } else {
        setSuccess("");
        setLoading(false);
      }
    });
  };
  return (
    <form
      className="bi-input-newsletter bi-button"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      {!error && !success && (
        <input
          type="mail"
          placeholder="Email"
          name="email"
          value={email}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      )}
      <button type="submit" name="S'inscrire" aria-label="S'inscrire">
        <IconButton type="slider-next" />
      </button>
    </form>
  );
};

export default InputNewsletter;
