import React from "react";
import Tabs from "../../components/modules/account/Tabs";
import "../../assets/styles/pages/account.scss";
import ProfessionalSpace from "../../components/modules/forms/ProfessionalSpace";

import { useSelector, useDispatch } from "react-redux";

const Page = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.authentication);
  return (
    <section className="bi-account-c">
      <Tabs data={data}/>
      <div className="bi-layout">
        <div className="bi-account-c_content">
          <div className="bi-title_wrapper">
            <h1 className="bi-title-tertiary">Espace professionnel</h1>
          </div>
          {data.is_pro ? (
            <>
              <p className="bi-primary-paragraph pro-succes-sentence">
                Félicitations ! Votre compte professionnel à bien été créé.
              </p>
              <div className="bi-input-pro-account">
                <input
                  disabled
                  id="company"
                  type="text"
                  placeholder={data.companyName}
                />
                <input
                  disabled
                  id="siret"
                  type="text"
                  placeholder={data.companySIRET}
                />
              </div>
            </>
          ) : (
            <ProfessionalSpace data={data} />
          )}
        </div>
      </div>
    </section>
  );
};

export default Page;
