const addToCrush = (element, connected, userId, event) => {
  console.log(event);
  if (connected) {
    const data = {
      userId: userId,
      id: element._id,
    };

    // post data to add-user-crush endpoint
    fetch("/.netlify/functions/add-user-crush", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
      });
  }
};

const removeFromCrush = (element, connected, userId, event) => {
  if (connected) {
    const data = {
      userId: userId,
      id: element._id,
    };

    // post data to add-user-crush endpoint
    fetch("/.netlify/functions/remove-user-crush", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
      });
  }
};


const verifCrushExisting = (elementId, array) => {
    let result = false;
    if(array && typeof array === 'object') {
      array.forEach((element) => {
        if (element._ref === elementId) {
        result = true;
        }
    });
    }
    return result;
}

export { addToCrush, removeFromCrush, verifCrushExisting };