import React from "react";

import Button from "../../utils/atoms/Button.jsx";
import eventBus from "../../../assets/scripts/utils/eventBus";

import "../../../assets/styles/components/modules/forms.scss";
// Create form for register user and send it to create-user netlify function
const Register = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [acceptNewsletter, setAcceptNewsletter] = React.useState(false);
  const [acceptCGU, setAcceptCGU] = React.useState(false);

  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "newsletter":
        setAcceptNewsletter(!acceptNewsletter);
        break;
      case "cgu":
        setAcceptCGU(!acceptCGU);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Les mots de passe doivent être identiques");
      setSuccess("");
      setLoading(false);
      return;
    }

    // Check if all fields are filled
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setError("Tous les champs sont obligatoires");
      setSuccess("");
      setLoading(false);
      return;
    }

    // Check if user have accept CGU
    if (!acceptCGU) {
      setError("Vous devez accepter les conditions générales !");
      setSuccess("");
      setLoading(false);
      return;
    }

    // Send data to netlify function
    setLoading(true);
    fetch("/.netlify/functions/create-user", {
      method: "POST",
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        baseUrl: window ? window.location.origin : "http://localhost:3000",
        acceptNewsletter,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        let userID;
        res.json().then((data) => {
          userID = data._id;

          // set cookies lib-experience-connected to true and lib-experience-user-id to the user id
          document.cookie = `lib-experience-connected=true; path=/; max-age=31536000`;
          document.cookie = `lib-experience-user-id=${userID}; path=/; max-age=31536000`;

          // make async await  fetch post request to send-mail endpoint to send success email to the user after registration
          fetch("/.netlify/functions/send-mail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Email: email,
              Name: firstName + " " + lastName,
              type: "confirm-email-client",
              TemplateID: "4036299",
            }),
          }).then((res) => {
            if (res.status === 200) {
              fetch("/.netlify/functions/subscribe-newsletter", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                  from: "subscribe-user",
                }),
              }).then((res) => {
                if (res.status === 200) {
                  setSuccess("Vous êtes bien abonnés !");
                  setError("");
                  setLoading(false);
                  res.json().then((data) => {
                    console.log("data", data[0]);
                  });
                } else {
                  setSuccess("");
                  setLoading(false);
                }
              });
            }
            setSuccess(
              "Vous êtes bien inscrit veuillez aller sur la page de connexion"
            );
            setError("");
            setLoading(false);
            eventBus.dispatch("updateData");
          });
        });
      } else {
        if (res.status === 422) {
          setError("Cet email est déja associé à un compte !");
        } else {
          setError(
            "Une erreur est survenu pendant le processus d'inscription !"
          );
        }
        res.json().then((data) => {
          console.log(data);
        });
        setSuccess("");
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    document.querySelector(".bi-account-card").scroll(0, 0);
  }, [error, success]);
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      <div className="bi-form-loader">
        {/*<!--- Loading manager --->*/}
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="text"
        placeholder="Prénom"
        name="firstName"
        value={firstName}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="text"
        placeholder="Nom"
        name="lastName"
        value={lastName}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="password"
        placeholder="Mot de passe"
        name="password"
        value={password}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="password"
        placeholder="Confirmer le mot de passe"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <br />
      <div className="bi-form-group flex">
        <input
          id="newsletter"
          type="checkbox"
          name="newsletter"
          value="newsletter"
          checked={acceptNewsletter}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <label htmlFor="newsletter">S’abonner à la newsletter</label>
      </div>
      <div className="bi-form-group flex">
        <input
          id="cgu"
          type="checkbox"
          name="cgu"
          calue="cgu"
          checked={acceptCGU}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <label htmlFor="cgu">J’accepte les conditions générales*</label>
      </div>
      <p className="bi-form-legende small">*Champs obligatoires</p>
      <Button className="bi-button yellow sumbit-button-register" type="submit" value="Créer un compte">Créer un compte</Button>
    </form>
  );
};

export default Register;
