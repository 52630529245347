import React from "react";
import Tag from "../utils/atoms/Tag";
import { PortableText } from "@portabletext/react";
import IconButton from "../utils/atoms/IconButton";
import urlImageBuilder from "../../assets/scripts/utils/urlImageBuilder";
import "../../assets/styles/templates/event.scss";
import GridSugest from "../modules/products/GridSugest";
import EventListItem from "../modules/events/EventListItem";
import { Helmet } from "react-helmet";
import Button from "../utils/atoms/Button";
import gsap from "gsap";

const Page = (props) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTrim, setIsTrim] = React.useState(true);
  const [message, setMessage] = React.useState(true);

  const event = props.pageContext.event;

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.onresize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  }, []);

  let eventArticle = event._rawEditionWords;
  let eventTrimArticle = event._rawEditionWords
    ? event._rawEditionWords[0].children[0].text.substring(0, 200) + "..."
    : "";
  console.log(eventTrimArticle);

  const currentUrl = () => {
    return document.URL;
  };
  const shareFacebook = () => {
    windowDialog(
      "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl()
    );
  };

  const shareTwitter = () => {
    windowDialog("https://twitter.com/intent/tweet/?url=" + currentUrl());
  };

  const copyLink = () => {
    navigator.clipboard.writeText(currentUrl());
    gsap.killTweensOf(".bi-event-introduction .message");
    let tl = gsap.timeline();
    tl.fromTo(
      ".bi-event-introduction .message",
      { opacity: 0, translateY: 16 },
      { opacity: 1, translateY: 0 }
    );
    tl.fromTo(
      ".bi-event-introduction .message",
      { opacity: 1 },
      { opacity: 0, delay: 0.5 }
    );
  };

  const windowDialog = (url) => {
    window.open(
      url,
      "_blank",
      "llocation=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {event.name} | Librairie Expérience : dédicaces et rencontres
        </title>
        <meta
          name="description"
          content="Stoppez tout ! Rendez-vous chez Librairie Experience à Bellecour Lyon pour passer un moment privilégié avec un auteur ou dessinateur de BD."
        />
      </Helmet>
      <section className="bi-layout bi-event-header">
        <div className="bi-event-header_infos">
          <div>
            <p>{event.year}</p>
            <Tag data-parallax="false">Dédicace</Tag>
          </div>
          <h1 className="bi-title-secondary">{event.name}</h1>
        </div>
        <div
          className="bi-event-header-cover_container"
          data-parallax={!isMobile}
          data-p-vitesse="-0.8"
          data-p-direction="bottom"
          data-p-offset="false"
          data-p-rotate="4"
          data-p-scale="1"
        >
          <picture className="bi-event-header_cover">
            <img
              src={urlImageBuilder(event.cover.asset.path, 360, null, {
                ...event.cover.asset,
                ...event.cover.crop,
              })}
              alt={event.cover.asset.altText}
            />
          </picture>
        </div>
        <div className="bi-event-header_background"></div>
      </section>
      <section className="bi-layout bi-event-introduction">
        <div>
          <h2 className="bi-title-quaternary">À propos de l’événement</h2>
          <PortableText value={event._rawDescription} />
        </div>
        <div>
          <h2 className="bi-title-quaternary">Partager</h2>
          <ul>
            <li
              onClick={(e) => {
                e.preventDefault();
                shareFacebook();
              }}
            >
              <IconButton type="share-facebook" />
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                shareTwitter();
              }}
            >
              <IconButton type="share-twitter" />
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                copyLink();
              }}
            >
              <div className="message">
                <p>Lien copié</p>
              </div>
              <IconButton type="share-link" />
            </li>
          </ul>
        </div>
      </section>

      <section className="bi-layout bi-event-content">
        {(eventTrimArticle || eventArticle) && (
          <>
            <h2 className="bi-title-primary">Le mot de l'éditeur</h2>
            <div
              className={"bi-event-content_bulle " + (!isMobile && "small")}
              data-parallax={isMobile ? "false" : "true"}
              data-p-vitesse="0.4"
              data-p-direction="bottom"
              data-p-offset="false"
            >
              {!isMobile ? (
                <PortableText value={eventArticle} className="small" />
              ) : (
                <>
                  {isTrim ? (
                    <>
                      <p>{eventTrimArticle}</p>{" "}
                      <Button
                        type="text"
                        onClick={() => {
                          setIsTrim(false);
                        }}
                      >
                        Lire plus{" "}
                        <svg
                          width="23"
                          height="16"
                          viewBox="0 0 23 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.95 0L13.3285 1.61143L18.5955 6.85714H0V9.14286H18.5955L13.317 14.3886L14.95 16L23 8L14.95 0Z"
                            fill="#1D1D1D"
                          />
                        </svg>
                      </Button>
                    </>
                  ) : (
                    <PortableText value={eventArticle} className="small" />
                  )}
                </>
              )}
            </div>{" "}
          </>
        )}
      </section>

      {props.pageContext.allEvents.nodes
        .filter((event) => {
          let dateFrom = event.year.split("/");
          let newDate = new Date(
            dateFrom[2],
            dateFrom[1] - 1,
            dateFrom[0]
          ).getTime();
          return newDate > new Date().getTime();
        })
        .slice(-3).length > 0 && (
        <section className="bi-layout bi-event-suggestion">
          <GridSugest title={"Plus d'événements"} items={props.pageContext.allEvents.nodes
              .filter((event) => {
                let dateFrom = event.year.split("/");
                let newDate = new Date(
                  dateFrom[2],
                  dateFrom[1] - 1,
                  dateFrom[0]
                ).getTime();
                return newDate > new Date().getTime();
              })
              .slice(-3)}
              heading={{title: "Plus d'événements"}}>
            {props.pageContext.allEvents.nodes
              .filter((event) => {
                let dateFrom = event.year.split("/");
                let newDate = new Date(
                  dateFrom[2],
                  dateFrom[1] - 1,
                  dateFrom[0]
                ).getTime();
                return newDate > new Date().getTime();
              })
              .slice(-3)
              .map((event, index) => (
                <li key={index} className='bi-slider_item'>
                  <EventListItem key={event.id} event={event} />
                </li>
              ))}
          </GridSugest>
        </section>
      )}
    </>
  );
};

export default Page;
