import * as React from "react";
import gsap from "gsap";
import QuotesSection from "../../components/sections/Quotes";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import eventBus from "../../assets/scripts/utils/eventBus";

import "../../assets/styles/pages/evenements.scss";

import ArtistsSlider from "../../components/modules/events/ArtistsSlider";
import PastEventsSlider from "../../components/modules/events/PastEventsSlider";

///
import Slider from "../../components/modules/sliderModule/index";
///
import EventDesktop from "../../assets/images/pages/event/event_desktop_1x.jpg";
import EventDesktopRetina from "../../assets/images/pages/event/event_desktop_2x.jpg";
import EventMobile from "../../assets/images/pages/event/event_mobile_1x.jpg";
import EventMobileRetina from "../../assets/images/pages/event/event_mobile_2x.jpg";

import EventDesktopWebp from "../../assets/images/pages/event/event_desktop_1x.webp";
import EventDesktopRetinaWebp from "../../assets/images/pages/event/event_desktop_2x.webp";
import EventMobileWebp from "../../assets/images/pages/event/event_mobile_1x.webp";
import EventMobileRetinaWebp from "../../assets/images/pages/event/event_mobile_2x.webp";

// Load all images for pasts artists

import PetersProfileRetina from "../../assets/images/pages/event/peters-profile@2x.jpg";
import MoebiusProfileRetina from "../../assets/images/pages/event/moebius-profile@2x.jpg";
import ShigaProfileRetina from "../../assets/images/pages/event/shiga-profile@2x.jpg";
import JouvrayProfileRetina from "../../assets/images/pages/event/jouvray-profile@2x.jpg";
import CharbProfileRetina from "../../assets/images/pages/event/charb-profile@2x.jpg";
import BilalProfileRetina from "../../assets/images/pages/event/bilal-profile@2x.jpg";
import AugustinProfileRetina from "../../assets/images/pages/event/augustin-profile@2x.jpg";
import GuarnidoProfileRetina from "../../assets/images/pages/event/guarnido-profile@2x.jpg";
import LarcenetProfileRetina from "../../assets/images/pages/event/larcenet-profile@2x.jpg";
import MandelProfileRetina from "../../assets/images/pages/event/mandel-profile@2x.jpg";
import VivesProfileRetina from "../../assets/images/pages/event/vives-profile@2x.jpg";
import BagieuProfileRetina from "../../assets/images/pages/event/bagieu-profile@2x.jpg";
import MeurisseProfileRetina from "../../assets/images/pages/event/meurisse-profile@2x.jpg";
import MezièresProfileRetina from "../../assets/images/pages/event/mezières-profile@2x.jpg";
import PastArtists from "../../components/modules/events/PastArtists";
import EventListItem from "../../components/modules/events/EventListItem";

// import PetersProfileRetinaWebp from "../../assets/images/pages/event/peters-profile@2x.webp";
// import MoebiusProfileRetinaWebp from "../../assets/images/pages/event/moebius-profile@2x.webp";
// import ShigaProfileRetinaWebp from "../../assets/images/pages/event/shiga-profile@2x.webp";
// import JouvrayProfileRetinaWebp from "../../assets/images/pages/event/jouvray-profile@2x.webp";
// import CharbProfileRetinaWebp from "../../assets/images/pages/event/charb-profile@2x.webp";
// import BilalProfileRetinaWebp from "../../assets/images/pages/event/bilal-profile@2x.webp";
// import AugustinProfileRetinaWebp from "../../assets/images/pages/event/augustin-profile@2x.webp";
// import GuarnidoProfileRetinaWebp from "../../assets/images/pages/event/guarnido-profile@2x.webp";
// import LarcenetProfileRetinaWebp from "../../assets/images/pages/event/larcenet-profile@2x.webp";
// import MandelProfileRetinaWebp from "../../assets/images/pages/event/mandel-profile@2x.webp";
// import VivesProfileRetinaWebp from "../../assets/images/pages/event/vives-profile@2x.webp";
// import BagieuProfileRetinaWebp from "../../assets/images/pages/event/bagieu-profile@2x.webp";
// import MeurisseProfileRetinaWebp from "../../assets/images/pages/event/meurisse-profile@2x.webp";
// import MezièresProfileRetinaWebp from "../../assets/images/pages/event/mezières-profile@2x.webp";
const Page = (props) => {
  const [isDesktopState, setIsdesktop] = React.useState(true);
  const { data } = props;
  const allEvents = data.allSanityEventDocumentType.nodes;

  // filter Events by future events and past events
  const futureEvents = allEvents.filter((event) => {
    let dateFrom = event.year.split("/");
    let newDate = new Date(dateFrom[2], dateFrom[1] - 1, dateFrom[0]).getTime();
    return newDate > new Date().getTime();
  });
  const pastEvents = allEvents.filter((event) => {
    let dateFrom = event.year.split("/");
    let newDate = new Date(dateFrom[2], dateFrom[1] - 1, dateFrom[0]).getTime();
    return newDate < new Date().getTime();
  });

  const introAnimation = (isDesktop) => {
    if (isDesktop) {
      gsap.killTweensOf(".bi-events-header .bi-e-description");
      gsap.fromTo(".bi-events-header .bi-e-description", { y: 32 }, { y: 0 });
      gsap.fromTo(
        ".bi-events-header .bi-e-picture picture img",
        { scale: 1.1 },
        { scale: 1 }
      );
    } else {
      gsap.killTweensOf(".bi-events-header .bi-e-description");
      gsap.fromTo(
        ".bi-e-description h1, .bi-e-description p",
        { y: 16, opacity: 0 },
        { y: 0, opacity: 1, stagger: 0.25 }
      );
      gsap.fromTo(
        ".bi-events-header .bi-e-picture picture img",
        { scale: 1.05 },
        { scale: 1 }
      );
    }
  };

  const isDesktop = () => {
    if (typeof window !== 'undefined' &&
      window.document && window.document.createElement) {
      return window?.innerWidth > 768;
    }
  };
  React.useEffect(() => {
      gsap.set('.bi-events-header .bi-e-picture picture img', {scale: window?.innerWidth > 768 ? 1.1 : 1.05});
    setIsdesktop(isDesktop());
    setIsdesktop((value)=>{
      return value;
    })
    document.addEventListener("resize", () => {
      setIsdesktop(isDesktop());
    });
  }, []);
  React.useEffect(() => {
    eventBus.on("pageTransitionEnd", () => {
      introAnimation(isDesktop());
    });
    eventBus.on("loaderEnd", () => {
      introAnimation(isDesktop());
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Événements | Librairie Expérience : participez aux dédicaces
        </title>
        <meta
          name="description"
          content="Consultez notre agenda et inscrivez-vous aux dédicaces pour ne louper aucune rencontre avec vos dessinateurs préférés."
        />
      </Helmet>
      <section className="bi-layout bi-events-header">
        <div className="bi-e-description">
          <h1 className="bi-title-secondary">Événements</h1>
          <p>
            Nous organisons pas mal de dédicaces au magasin et de temps à autre
            des événements de plus grande ampleur. Souvent on aime arroser ça
            d’un petit canon !
          </p>
        </div>
        <div
          className="bi-e-picture"
          data-parallax={isDesktopState}
          data-p-vitesse="0.5"
          data-p-direction="bottom"
          data-p-offset="false"
        >
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={EventMobileRetinaWebp + " 2x," + EventMobileWebp + " 1x"}
            />
            <source
              media="(max-width: 767px)"
              srcSet={EventMobileRetina + " 2x," + EventMobile + " 1x"}
            />
            <source
              media="(min-width: 768px)"
              srcSet={EventDesktopRetinaWebp + " 2x," + EventDesktopWebp + " 1x"}
            />
            <source
              media="(min-width: 768px)"
              srcSet={EventDesktopRetina + " 2x," + EventDesktop + " 1x"}
            />
            <img src={EventDesktop} alt="Salle dela sérigraphie" />
          </picture>
        </div>
      </section>
      <QuotesSection content="Passez un moment privilégié avec un auteur et rencontrez d’autres passionnés de BD!" />
      <section className="bi-layout bi-events-becomingEvents">
        <Slider items={futureEvents} title='Les événements à venir'>
          {
            futureEvents.map((el, index) => {
              return (
                <li key={index} className='bi-slider_item'>
                  <EventListItem event={el}
                    desktopWidth="322"
                    mobileWidth="218" />
                </li>
              )
            })
          }
        </Slider>
      </section>
      <section className="bi-layout bi-events-pastEvents">
        <PastEventsSlider events={pastEvents} />
      </section>
      <PastArtists
        sliders={
          <ArtistsSlider
            artists={[
              { name: "Frederik Peeters", image: PetersProfileRetina },
              { name: "Moebius", image: MoebiusProfileRetina },
              { name: "Jason Shiga", image: ShigaProfileRetina },
              { name: "Les frères Jouvray", image: JouvrayProfileRetina },
              { name: "Charb", image: CharbProfileRetina },
              { name: "Enki Bilal", image: BilalProfileRetina },
              { name: "Virginie Augustin", image: AugustinProfileRetina },
              { name: "Guarnido", image: GuarnidoProfileRetina },
              { name: "Manu Larcenet", image: LarcenetProfileRetina },
              { name: "Lisa Mandel", image: MandelProfileRetina },
              { name: "Bastien Vivès", image: VivesProfileRetina },
              { name: "Pénélope Bagieu", image: BagieuProfileRetina },
              { name: "Catherine Meurisse", image: MeurisseProfileRetina },
              { name: "Jean-Claude Mezières", image: MezièresProfileRetina },
            ]}
          />
        }
      />
      {/* <section className="bi-layout bi-events-pastArtists">
        <h2 className="bi-title-quaternary bi-events-pastArtists-title">
          Ils sont passés chez Expérience
        </h2>

        <ul className="bi-events-pastArtists-list">
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Frederik Peeters</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={PetersProfileRetina + " 2x," + PetersProfile + " 1x"}
              />
              <img src={PetersProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Moebius</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={MoebiusProfileRetina + " 2x," + MoebiusProfile + " 1x"}
              />
              <img src={MoebiusProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Jason Shiga</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={ShigaProfileRetina + " 2x," + ShigaProfile + " 1x"}
              />
              <img src={ShigaProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Les frères Jouvray</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={JouvrayProfileRetina + " 2x," + JouvrayProfile + " 1x"}
              />
              <img src={JouvrayProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Charb</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={CharbProfileRetina + " 2x," + CharbProfile + " 1x"}
              />
              <img src={CharbProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Enki Bilal</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={Enki BilalProfileRetina + " 2x," + Enki BilalProfile + " 1x"}
              />
              <img src={Enki BilalProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Virginie Augustin</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={
                  AugustinProfileRetina + " 2x," + AugustinProfile + " 1x"
                }
              />
              <img src={AugustinProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Guarnido</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={
                  GuarnidoProfileRetina + " 2x," + GuarnidoProfile + " 1x"
                }
              />
              <img src={GuarnidoProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Manu Larcenet</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={
                  LarcenetProfileRetina + " 2x," + LarcenetProfile + " 1x"
                }
              />
              <img src={LarcenetProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Lisa Mandel</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={MandelProfileRetina + " 2x," + MandelProfile + " 1x"}
              />
              <img src={MandelProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Bastien Vivès</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={VivesProfileRetina + " 2x," + VivesProfile + " 1x"}
              />
              <img src={VivesProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Pénélope Bagieu</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={BagieuProfileRetina + " 2x," + BagieuProfile + " 1x"}
              />
              <img src={BagieuProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Catherine Meurisse</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={
                  MeurisseProfileRetina + " 2x," + MeurisseProfile + " 1x"
                }
              />
              <img src={MeurisseProfile} alt="Profile d'artiste" />
            </picture>
          </li>
          <li className="bi-title-quaternary bi-events-pastArtists-list-item">
            <span>Jean-Claude Mezières</span>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={
                  MezièresProfileRetina + " 2x," + MezièresProfile + " 1x"
                }
              />
              <img src={MezièresProfile} alt="Profile d'artiste" />
            </picture>
          </li>
        </ul>

        <ArtistsSlider
          artists={[
            { name: "Frederik Peeters", image: PetersProfileRetina },
            { name: "Moebius", image: MoebiusProfileRetina },
            { name: "Jason Shiga", image: ShigaProfileRetina },
            { name: "Les frères Jouvray", image: JouvrayProfileRetina },
            { name: "Charb", image: CharbProfileRetina },
            { name: "Enki Bilal", image: Enki BilalProfileRetina },
            { name: "Virginie Augustin", image: AugustinProfileRetina },
            { name: "Guarnido", image: GuarnidoProfileRetina },
            { name: "Manu Larcenet", image: LarcenetProfileRetina },
            { name: "Lisa Mandel", image: MandelProfileRetina },
            { name: "Bastien Vivès", image: VivesProfileRetina },
            { name: "Pénélope Bagieu", image: BagieuProfileRetina },
            { name: "Catherine Meurisse", image: MeurisseProfileRetina },
            { name: "Jean-Claude Mezières", image: MezièresProfileRetina },
          ]}
        />
        </section> */}
    </>
  );
};

export const query = graphql`
  query MyQuery {
    allSanityEventDocumentType(sort: {fields: year, order: ASC}) {
      nodes {
        name
        year(locale: "fr-FR", formatString: "DD/MM/YYYY")
        type
        slug {
          current
        }
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
      }
    }
  }
`;
export default Page;
