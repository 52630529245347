import * as React from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/utils/atoms/Button.jsx";
import ResetPassword from "../../components/modules/forms/ResetPassword";
const Page = () => {
  return (
    <>
      <Helmet>
        <title>
          J'ai perdu mon mot de passe | Librairie Expérience : renvoyez un lien
        </title>
        <meta
          name="description"
          content="Connectez-vous à votre compte personnel ou professionnel pour passer commandes et profitez d’offres exclusives."
        />
      </Helmet>
      <section className="bi-layout bi-account">
        <div className="bi-account-card" onWheel={(e)=>{
        e.preventDefault();
        e.stopPropagation()
      }}>
          <h1 className="bi-title-quaternary">Réinitialiser mon mot de passe</h1>
          <ResetPassword />
          <Button
            type="link"
            to="/account/connexion"
            className="bi-button black"
          >
            Retour
          </Button>
        </div>
      </section>
    </>
  );
};

export default Page;
