import React from "react";

import gsap from "gsap";
import { Power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import eventBus from "../../../assets/scripts/utils/eventBus";

import CorridorDesktop from "../../../assets/images/pages/index/index_arche_desktop_1x.jpg";
import CorridorDesktopWebp from "../../../assets/images/pages/index/index_arche_desktop_1x.webp";
import CorridorDesktopRetina from "../../../assets/images/pages/index/index_arche_desktop_2x.jpg";
import CorridorDesktopRetinaWebp from "../../../assets/images/pages/index/index_arche_desktop_2x.webp";
import CorridorMobile from "../../../assets/images/pages/index/index_arche_mobile_1x.png";
import CorridorMobileRetina from "../../../assets/images/pages/index/index_arche_mobile_2x.png";

const Arche = () => {

  // for(let i = 0; i<=100; i++){
  //   const ease = i === 1/100 ? 1 : 1 - Math.pow(2, -10 * i/100)
  //   console.log(ease)
  // }
    let imageArch = React.useRef(null);
    let pictureArch = React.useRef(null);
    const plus = 0.09083333333;
    const coefDir = 0.05/180

    const rightValue = () => {
        if (window.innerWidth < 1024) {
          return 2;
        }
        if (window.innerWidth > 2000) {
          return 10;
        }
        if (window.innerWidth > 1440) {
          return 5;
        }
        if (window.innerWidth > 1024) {
          return 3;
        }
      };
      
    const isDesktop = () => {
        if (
          typeof window !== "undefined" &&
          window.document &&
          window.document.createElement
        ) {
          return window?.innerWidth > 768;
        }
      };


    React.useEffect(() => {
        eventBus.on("pageTransitionEnd", () => {
            ScrollTrigger.update();
        });

        gsap.registerPlugin(ScrollTrigger);
        if (isDesktop()) {
            let archTimeline = gsap.timeline({
                scrollTrigger: {
                  trigger: ".bi-index-picHeading-container",
                  start: "bottom bottom",
                  end: () => "+=" + window.innerHeight,
                  pin: true,
                  pinSpacer: true,
                  scrub: true,
                //   markers: true

                },
              }).fromTo(
                "#clip",
                {
                  scale: 1,
                  translateX: () => {
                    let vw = window.innerWidth / 2 - 278;
                    return vw;
                  },
                  translateY: () => {
                    let vh = window.innerHeight / 2 - 278;
                    return vh;
                  },
                  transformOrigin: "center",
                },
                {
                  translateX: () => {
                    let vw = window.innerWidth / 2 - 278;
                    return vw;
                  },
                  translateY: () => {
                    let vh = window.innerHeight / 2 - 278;
                    return vh;
                  },
                  transformOrigin: "center",
                  scale: rightValue(),
                  onStart: () => {
                    setTimeout(() => {
                      if (
                        navigator.userAgent.indexOf("Safari") > -1 &&
                        navigator.userAgent.indexOf("Chrome") <= -1
                      ) {
                        document.querySelector("#clip").classList.add("center");
                      }
                    }, 10);
                  },
                  onComplete: () => {
                    console.log("Animation ScaleArch Fini !");
                  },
                  duration: 3,
                  ease: Power2.easeInOut,
                },
                0
            )
        }
    },[])

    React.useEffect(() => {
        if(isDesktop()){
            const headerContainer = document.querySelector(".bi-index-picHeading-container");
            const picture = document.querySelector(".bi-index-picHeading")
            const img = picture.querySelector("#arch-img")
            const containerTop = headerContainer.getBoundingClientRect().top
            const containerBottom = headerContainer.getBoundingClientRect().bottom
    
            let lastScroll = null
            let lastScale = null
    
            window.addEventListener("scroll", ()=>{
                const scrollTop = document.documentElement.scrollTop
    
                if(scrollTop < containerTop){
                  // if(scrollTop < containerTop - 125){
                    img.style.transform = `translateY(${scrollTop/2.5 - 225}px) scale(1.3)`
                    lastScroll = scrollTop/2.5 - 225
                  // }else{


                  //   const x = (scrollTop - (containerTop-125))/125
                  //   const ease = 1 - (1 - x) * (1 - x);
                  //   img.style.transform = `translateY(${((scrollTop/(2.5))  - 225) }px) scale(1.3)`
                  //   lastScroll = ((scrollTop/(2.5))  - 225)

                    
                  // }
    
                }else{
                    if(scrollTop <= containerBottom){
                      const scale = ( scrollTop - containerTop ) / 5000
                      img.style.transform = `translateY(${lastScroll}px) scale(${ 1.3 - scale })`
                      lastScale = scale
                      
                    }else{  

                      if(scrollTop <= containerBottom + 300){
                        const x = 1 - ((containerBottom + 300)- scrollTop)/300
                        const ease = 1 - Math.sqrt(1 - Math.pow(x, 2));

                        console.log(ease)

                        const scale = ( scrollTop - containerTop ) / 5000
                        lastScale = scale
                        img.style.transform = `translateY(${( scrollTop/2.5 - (window.innerHeight - window.innerHeight * (plus + coefDir * window.innerHeight)))}px) scale(${(1.3 - lastScale)})`


                        lastScroll = scrollTop/2.5 - (window.innerHeight - window.innerHeight * (plus + coefDir * window.innerHeight))
                      
                      } else {

                        const opacity = 2.3 - 1.3 * (scrollTop/(containerBottom + 300))
                        img.style.transform = `translateY(${ scrollTop/2.5 - (window.innerHeight - window.innerHeight * (plus + coefDir * window.innerHeight)) }px) scale(${1.3 - lastScale})`
                        lastScroll = scrollTop/2.5 - (window.innerHeight - window.innerHeight * (plus + coefDir * window.innerHeight))//36
                        img.style.opacity = opacity
                        
                      }
    
                    }
                }
    
            })
        }
    },[])

    return (
        <div className="bi-index-picHeading-container">
        <picture
          className="bi-index-picHeading"
          ref={pictureArch}
        >
          {/* <source
            media="(max-width: 767px)"
            srcSet={CorridorMobileRetinaWebp + " 2x," + CorridorMobileWebp + " 1x"}
          /> */}
          <source
            media="(max-width: 767px)"
            srcSet={CorridorMobileRetina + " 2x," + CorridorMobile + " 1x"}
          />
          <source
            media="(min-width: 768px)"
            srcSet={CorridorDesktopRetinaWebp + " 2x," + CorridorDesktopWebp + " 1x"}
          />
          <source
            media="(min-width: 768px)"
            srcSet={CorridorDesktopRetina + " 2x," + CorridorDesktop + " 1x"}
          />
          <img
            className="anim-image"
            id="arch-img"
            ref={imageArch}
            src={CorridorDesktop}
            alt="Salle dela sérigraphie"
          />
        </picture>
        <svg
          width="0"
          height="0"
          viewBox="0 0 557 868"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clip">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              clipPathUnits="objectBoundingBox"
              d="M0 277.64C0 124.304 124.535 0 278.156 0C431.778 0 556.313 124.304 556.313 277.64V868H0V277.64Z"
              fill="#7A3434"
            />
          </clipPath>
        </svg>
      </div>
    )

}



export default Arche