import * as React from "react";

import Button from "../../components/utils/atoms/Button.jsx";
import Register from "../../components/modules/forms/Register.jsx";
import "../../assets/styles/pages/account.scss";

const Page = () => {
  return (
    <section className="bi-layout bi-account">
      <div className="bi-account-card" /*onWheel={(e)=>{              <--------------------------------------- Pq ?
        e.preventDefault();
        e.stopPropagation()
      }}*/>
        <h1 className="bi-title-quaternary register-title">Créer un compte</h1>
        <Register />
        <Button type='link' to="/account/connexion" className="bi-button black register-already-account">
          J'ai déjà un compte
        </Button>
      </div>
    </section>
  );
};

export default Page;
