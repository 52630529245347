import React from "react";
import gsap from "gsap";

import "../../assets/styles/sections/quotes.scss";
import { Helmet } from "react-helmet";

const QuotesSection = ({ content }) => {
  const uniqueId = "a" + Math.floor(Math.random() * 50);
  React.useEffect(() => {
    setTimeout(() => {
      if (window && window.Splitting) {
        const results = window.Splitting({
          target: document.querySelector("." + uniqueId),
          by: "lines",
        });
        // create html for each line
        results[0]?.lines.forEach((line, index) => {
          const divLine = document.createElement("span");
          divLine.classList.add("line");
          const divLineCache = document.createElement("span");
          divLineCache.classList.add("title-line");
          line.forEach((word) => {
            let space = document.createElement("span");
            space.innerText = " ";
            space.classList.add("whitespace");
            divLine.appendChild(word);
            divLine.appendChild(space);
          });

          divLineCache.appendChild(divLine);
          document.querySelector("." + uniqueId).appendChild(divLineCache);
        });

        // create intersectionObserver
        let previousY = 0;
        let previousRatio = 0;
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const currentY = entry.boundingClientRect.y;
            const currentRatio = entry.intersectionRatio;
            const isIntersecting = entry.isIntersecting;

            if (isIntersecting && previousY > currentY) {
              gsap.set("." + uniqueId + " .title-line", { overflow: "hidden" });
              gsap.fromTo(
                "." + uniqueId + " .title-line .line",
                {
                  translateY: "160px",
                  rotate: (index, target) => {
                    return index % 2 ? -4 : 4;
                  },
                },
                {
                  translateY: 0,
                  rotate: 0,
                  stagger: 0.15,
                  delay: 0.6,
                  duration: 0.6,
                  onComplete: () => {
                    gsap.set("." + uniqueId + " .title-line", {
                      overflow: "initial",
                    });
                  },
                }
              );
            }

            previousY = currentY;
            previousRatio = currentRatio;
          });
        };
        let observer = new IntersectionObserver(handleIntersection, {
          threshold: 0,
        });
        if (document.querySelector("#" + uniqueId)) {
          observer.observe(document.querySelector("#" + uniqueId));
        }
      }
    }, 500);
  }, []);
  return (
    <section className="bi-quote_wrapper bi-layout" id={uniqueId}>
      <>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://unpkg.com/splitting/dist/splitting.css"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/splitting/dist/splitting-cells.css"
          />
          <script src="https://unpkg.com/splitting/dist/splitting.min.js"></script>
        </Helmet>
      </>
      <h2 className={"bi-quote" + " " + uniqueId}>{content}</h2>
    </section>
  );
};

export default QuotesSection;
