import React from "react";
import IconButton from "../../utils/atoms/IconButton";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css/core";

const ArtistsSlider = ({ artists }) => {
  return (
    <div className="artists-slider" style={{
        gridColumn: "1 / 9"
    }}>
      <Splide
        aria-label="My Favorite Images"
        hasTrack={ false }
        options={{
          width: '100%',
          gap: "16px",
          cover: false,
          arrows: true,
          autoWidth: true,
          fixedHeight: 265,
          pagination: false,
        }}
      >
        <div className="artists-slider__container custom-wrapper">
          <SplideTrack>
            {artists &&
              artists.map((artist, index) => {
                return (
                  <SplideSlide key={index}>
                    <div className="artists-slider__item">
                      <picture className="artists-slider__item-image" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 220
                      }}>
                        <img src={artist.image} alt={artist.name}  style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}/>
                      </picture>
                      <div className="artists-slider__item-name bi-title-quaternary" style={{
                        marginTop: 16
                      }}>
                        {artist.name}
                      </div>
                    </div>
                  </SplideSlide>
                );
              })}
          </SplideTrack>
        </div>
        <div className="splide__arrows" >
          <div className="artists-slider__arrow artists-slider__arrow--left splide__arrow splide__arrow--prev">
            <IconButton type="slider-prev" />
          </div>
          <div className="artists-slider__arrow artists-slider__arrow--right splide__arrow splide__arrow--next">
            <IconButton type="slider-next" />
          </div>
        </div>
      </Splide>
    </div>
  );
};

export default ArtistsSlider;
