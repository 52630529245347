import * as React from "react";
import Tabs from "../../components/modules/account/Tabs";
import "../../assets/styles/pages/account.scss";
import Product from "../../components/modules/cart/Product";
import Button from "../../components/utils/atoms/Button";
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart } from "../../assets/scripts/store/redux-slices/cart";
import urlImageBuilder from "../../assets/scripts/utils/urlImageBuilder";

const fakeCrushProducts = [
  {
    imageUrl: "https://via.placeholder.com/150",
    name: "Product 1",
    price: "10",
    availability: "Disponible seulement en magasin",
  },
  {
    imageUrl: "https://via.placeholder.com/150",
    name: "Product 2",
    price: "20",
    availability: null,
  },
  {
    imageUrl: "https://via.placeholder.com/150",
    name: "Product 3",
    price: "30",
    availability: null,
  },
];
const Page = () => {
  const [crushProducts, setCrushProducts] = React.useState([]);
  const { connected, id, data } = useSelector((state) => state.authentication);
  const [wishListData, setWishListData] = React.useState(data.wishListData);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (connected) {
      console.log(data);
      if (wishListData) {
        let newCrushProducts = [];
        console.log("WishListData: ", wishListData);
        wishListData.forEach((element) => {
          if (element._type === "productDocumentType") {
            let obj = {
              ...element,
              availability: null,
              quantity: 1,
              imageUrl: `https://cdn.sanity.io/images/rpq9eend/production/${element.cover.asset._ref
                .replace("image-", "")
                .replace("-jpg", ".jpg")
                .replace("-png", ".png")}?w=200`,
              id: element._id,
            };
            newCrushProducts.push(obj);
            console.log("un produit:", obj);
          } else if (element._type === "crushDocumentType") {
            let obj = {
              ...element,
              imageUrl: `https://cdn.sanity.io/images/rpq9eend/production/${element.cover.asset._ref
                .replace("image-", "")
                .replace("-jpg", ".jpg")
                .replace("-png", ".png")}?w=200`,
              availability: "Disponible seulement en magasin",
            };
            newCrushProducts.push(obj);
          }
        });

        setCrushProducts(newCrushProducts);
      }
    }
  }, [connected, wishListData]);

  const deleteCrushItem = (productId, setLoading) => {
    // poost a request to
    fetch("/.netlify/functions/del-user-crush", {
      method: "POST",
      body: JSON.stringify({
        userId: data._id,
        id: productId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.message) {
          setCrushProducts(crushProducts.filter((el) => el._id !== productId));
        }
      });
  };

  const setProductToCart = (product) => {
    dispatch(addProductToCart(product));
  };

  return (
    <section className="bi-account-c">
      <Tabs />
      <div className="bi-layout">
        <div className="bi-account-c_content">
          <div className="bi-title_wrapper">
            <h1 className="bi-title-tertiary">
              {!(crushProducts.length > 0)
                ? "Votre liste de souhaits est vide..."
                : "Ma liste de souhaits"}
            </h1>
          </div>
          {!(crushProducts.length > 0) && (
            <Button className="bi-button yellow" type="link" to="/store">
              Visiter la boutique
            </Button>
          )}
          <ul className="bi-account-c_crushes">
            {crushProducts.map((product, index) => {
              return (
                <li key={index} className="bi-account-c_crushes-crush">
                  <Product product={product}>
                    
                      {product.availability ? (
                        <p>{product.availability}</p>
                      ) : (
                        <Button
                          className="bi-button yellow"
                          onClick={() => {
                            setProductToCart(product);
                          }}
                        >
                          Ajouter au panier
                        </Button>
                      )}
                      <Button
                        type="text"
                        onClick={() => {
                          deleteCrushItem(product._id);
                        }}
                      >
                        SUPPRIMER
                      </Button>
                  </Product>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Page;
