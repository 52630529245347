import * as React from "react";
import gsap from "gsap";
import { graphql, Link } from "gatsby";

import CrushListItem from "../../components/modules/crushes/CrushListItem.jsx";
import { FilterButton } from "../../components/utils/atoms/Button.jsx";
import "../../assets/styles/pages/crushes.scss";
import "../../assets/styles/components/modules/crushes/filterWrapperMobile.scss";
import IconButton from "../../components/utils/atoms/IconButton.jsx";

/*
import RandomCarréDesktop from "../../assets/images/pages/crushes/img-random_carré-desktop.jpg";
import RandomCarréDesktopRetina from "../../assets/images/pages/crushes/img-random_carré-desktop@2x.jpg";

import RandomCarréMobile from "../../assets/images/pages/crushes/img-random_carré-mobile.jpg";
import RandomCarréMobileRetina from "../../assets/images/pages/crushes/img-random_carré-mobile@2x.jpg";
*/

import RandomCarréDesktop1 from "../../assets/images/pages/crushes/random_squares/randomimage_1_desktop_1x.jpg";
import RandomCarréDesktop1Retina from "../../assets/images/pages/crushes/random_squares/randomimage_1_desktop_2x.jpg";
import RandomCarréMobile1 from "../../assets/images/pages/crushes/random_squares/randomimage_1_mobile_1x.jpg";
import RandomCarréMobile1Retina from "../../assets/images/pages/crushes/random_squares/randomimage_1_mobile_2x.jpg";
import RandomCarréDesktop1Webp from "../../assets/images/pages/crushes/random_squares/randomimage_1_desktop_1x.webp";
import RandomCarréDesktop1RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_1_desktop_2x.webp";
import RandomCarréMobile1Webp from "../../assets/images/pages/crushes/random_squares/randomimage_1_mobile_1x.webp";
import RandomCarréMobile1RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_1_mobile_2x.webp";

import RandomCarréDesktop2 from "../../assets/images/pages/crushes/random_squares/randomimage_2_desktop_1x.jpg";
import RandomCarréDesktop2Retina from "../../assets/images/pages/crushes/random_squares/randomimage_2_desktop_2x.jpg";
import RandomCarréMobile2 from "../../assets/images/pages/crushes/random_squares/randomimage_2_mobile_1x.jpg";
import RandomCarréMobile2Retina from "../../assets/images/pages/crushes/random_squares/randomimage_2_mobile_2x.jpg";
import RandomCarréDesktop2Webp from "../../assets/images/pages/crushes/random_squares/randomimage_2_desktop_1x.webp";
import RandomCarréDesktop2RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_2_desktop_2x.webp";
import RandomCarréMobile2Webp from "../../assets/images/pages/crushes/random_squares/randomimage_2_mobile_1x.webp";
import RandomCarréMobile2RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_2_mobile_2x.webp";

import RandomCarréDesktop3 from "../../assets/images/pages/crushes/random_squares/randomimage_3_desktop_1x.jpg";
import RandomCarréDesktop3Retina from "../../assets/images/pages/crushes/random_squares/randomimage_3_desktop_2x.jpg";
import RandomCarréMobile3 from "../../assets/images/pages/crushes/random_squares/randomimage_3_mobile_1x.jpg";
import RandomCarréMobile3Retina from "../../assets/images/pages/crushes/random_squares/randomimage_3_mobile_2x.jpg";
import RandomCarréDesktop3Webp from "../../assets/images/pages/crushes/random_squares/randomimage_3_desktop_1x.webp";
import RandomCarréDesktop3RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_3_desktop_2x.webp";
import RandomCarréMobile3Webp from "../../assets/images/pages/crushes/random_squares/randomimage_3_mobile_1x.webp";
import RandomCarréMobile3RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_3_mobile_2x.webp";

import RandomCarréDesktop4 from "../../assets/images/pages/crushes/random_squares/randomimage_4_desktop_1x.jpg";
import RandomCarréDesktop4Retina from "../../assets/images/pages/crushes/random_squares/randomimage_4_desktop_2x.jpg";
import RandomCarréMobile4 from "../../assets/images/pages/crushes/random_squares/randomimage_4_mobile_1x.jpg";
import RandomCarréMobile4Retina from "../../assets/images/pages/crushes/random_squares/randomimage_4_mobile_1x.jpg";
import RandomCarréDesktop4Webp from "../../assets/images/pages/crushes/random_squares/randomimage_4_desktop_1x.webp";
import RandomCarréDesktop4RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_4_desktop_2x.webp";
import RandomCarréMobile4Webp from "../../assets/images/pages/crushes/random_squares/randomimage_4_mobile_1x.webp";
import RandomCarréMobile4RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_4_mobile_1x.webp";

import RandomCarréDesktop5 from "../../assets/images/pages/crushes/random_squares/randomimage_5_desktop_1x.jpg";
import RandomCarréDesktop5Retina from "../../assets/images/pages/crushes/random_squares/randomimage_5_desktop_2x.jpg";
import RandomCarréMobile5 from "../../assets/images/pages/crushes/random_squares/randomimage_5_mobile_1x.jpg";
import RandomCarréMobile5Retina from "../../assets/images/pages/crushes/random_squares/randomimage_5_mobile_1x.jpg";
import RandomCarréDesktop5Webp from "../../assets/images/pages/crushes/random_squares/randomimage_5_desktop_1x.webp";
import RandomCarréDesktop5RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_5_desktop_2x.webp";
import RandomCarréMobile5Webp from "../../assets/images/pages/crushes/random_squares/randomimage_5_mobile_1x.webp";
import RandomCarréMobile5RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_5_mobile_1x.webp";

import RandomCarréDesktop6 from "../../assets/images/pages/crushes/random_squares/randomimage_6_desktop_1x.jpg";
import RandomCarréDesktop6Retina from "../../assets/images/pages/crushes/random_squares/randomimage_6_desktop_2x.jpg";
import RandomCarréMobile6 from "../../assets/images/pages/crushes/random_squares/randomimage_6_mobile_1x.jpg";
import RandomCarréMobile6Retina from "../../assets/images/pages/crushes/random_squares/randomimage_6_mobile_1x.jpg";
import RandomCarréDesktop6Webp from "../../assets/images/pages/crushes/random_squares/randomimage_6_desktop_1x.webp";
import RandomCarréDesktop6RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_6_desktop_2x.webp";
import RandomCarréMobile6Webp from "../../assets/images/pages/crushes/random_squares/randomimage_6_mobile_1x.webp";
import RandomCarréMobile6RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_6_mobile_1x.webp";

import RandomCarréDesktop7 from "../../assets/images/pages/crushes/random_squares/randomimage_7_desktop_1x.jpg";
import RandomCarréDesktop7Retina from "../../assets/images/pages/crushes/random_squares/randomimage_7_desktop_2x.jpg";
import RandomCarréMobile7 from "../../assets/images/pages/crushes/random_squares/randomimage_7_mobile_1x.jpg";
import RandomCarréMobile7Retina from "../../assets/images/pages/crushes/random_squares/randomimage_7_mobile_1x.jpg";
import RandomCarréDesktop7Webp from "../../assets/images/pages/crushes/random_squares/randomimage_7_desktop_1x.webp";
import RandomCarréDesktop7RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_7_desktop_2x.webp";
import RandomCarréMobile7Webp from "../../assets/images/pages/crushes/random_squares/randomimage_7_mobile_1x.webp";
import RandomCarréMobile7RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_7_mobile_1x.webp";

import RandomCarréDesktop8 from "../../assets/images/pages/crushes/random_squares/randomimage_8_desktop_1x.jpg";
import RandomCarréDesktop8Retina from "../../assets/images/pages/crushes/random_squares/randomimage_8_desktop_2x.jpg";
import RandomCarréMobile8 from "../../assets/images/pages/crushes/random_squares/randomimage_8_mobile_1x.jpg";
import RandomCarréMobile8Retina from "../../assets/images/pages/crushes/random_squares/randomimage_8_mobile_1x.jpg";
import RandomCarréDesktop8Webp from "../../assets/images/pages/crushes/random_squares/randomimage_8_desktop_1x.webp";
import RandomCarréDesktop8RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_8_desktop_2x.webp";
import RandomCarréMobile8Webp from "../../assets/images/pages/crushes/random_squares/randomimage_8_mobile_1x.webp";
import RandomCarréMobile8RetinaWebp from "../../assets/images/pages/crushes/random_squares/randomimage_8_mobile_1x.webp";





import RandomRectangleDesktop1 from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_desktop_1x.jpg";
import RandomRectangleDesktop1Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_desktop_2x.jpg";
import RandomRectangleMobile1 from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_mobile_1x.jpg";
import RandomRectangleMobile1Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_mobile_2x.jpg";
import RandomRectangleDesktop1Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_desktop_1x.webp";
import RandomRectangleDesktop1RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_desktop_2x.webp";
import RandomRectangleMobile1Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_mobile_1x.webp";
import RandomRectangleMobile1RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_1_mobile_2x.webp";

import RandomRectangleDesktop2 from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_desktop_1x.jpg";
import RandomRectangleDesktop2Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_desktop_2x.jpg";
import RandomRectangleMobile2 from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_mobile_1x.jpg";
import RandomRectangleMobile2Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_mobile_2x.jpg";
import RandomRectangleDesktop2Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_desktop_1x.webp";
import RandomRectangleDesktop2RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_desktop_2x.webp";
import RandomRectangleMobile2Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_mobile_1x.webp";
import RandomRectangleMobile2RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_2_mobile_2x.webp";

import RandomRectangleDesktop3 from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_desktop_1x.jpg";
import RandomRectangleDesktop3Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_desktop_2x.jpg";
import RandomRectangleMobile3 from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_mobile_1x.jpg";
import RandomRectangleMobile3Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_mobile_2x.jpg";
import RandomRectangleDesktop3Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_desktop_1x.webp";
import RandomRectangleDesktop3RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_desktop_2x.webp";
import RandomRectangleMobile3Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_mobile_1x.webp";
import RandomRectangleMobile3RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_3_mobile_2x.webp";

import RandomRectangleDesktop4 from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_desktop_1x.jpg";
import RandomRectangleDesktop4Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_desktop_2x.jpg";
import RandomRectangleMobile4 from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_mobile_1x.jpg";
import RandomRectangleMobile4Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_mobile_2x.jpg";
import RandomRectangleDesktop4Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_desktop_1x.webp";
import RandomRectangleDesktop4RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_desktop_2x.webp";
import RandomRectangleMobile4Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_mobile_1x.webp";
import RandomRectangleMobile4RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_4_mobile_2x.webp";

import RandomRectangleDesktop5 from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_desktop_1x.jpg";
import RandomRectangleDesktop5Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_desktop_2x.jpg";
import RandomRectangleMobile5 from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_mobile_1x.jpg";
import RandomRectangleMobile5Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_mobile_2x.jpg";
import RandomRectangleDesktop5Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_desktop_1x.webp";
import RandomRectangleDesktop5RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_desktop_2x.webp";
import RandomRectangleMobile5Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_mobile_1x.webp";
import RandomRectangleMobile5RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_5_mobile_2x.webp";

import RandomRectangleDesktop6 from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_desktop_1x.jpg";
import RandomRectangleDesktop6Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_desktop_2x.jpg";
import RandomRectangleMobile6 from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_mobile_1x.jpg";
import RandomRectangleMobile6Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_mobile_2x.jpg";
import RandomRectangleDesktop6Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_desktop_1x.webp";
import RandomRectangleDesktop6RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_desktop_2x.webp";
import RandomRectangleMobile6Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_mobile_1x.webp";
import RandomRectangleMobile6RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_6_mobile_2x.webp";

import RandomRectangleDesktop7 from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_desktop_1x.jpg";
import RandomRectangleDesktop7Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_desktop_1x.webp";
import RandomRectangleDesktop7Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_desktop_2x.jpg";
import RandomRectangleDesktop7RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_desktop_2x.webp";
import RandomRectangleMobile7 from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_mobile_1x.jpg";
import RandomRectangleMobile7Webp from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_mobile_1x.webp";
import RandomRectangleMobile7Retina from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_mobile_2x.jpg";
import RandomRectangleMobile7RetinaWebp from "../../assets/images/pages/crushes/random_rectangles/randomimage_7_mobile_2x.webp";

import { Helmet } from "react-helmet";
import { createPortal } from "react-dom";

import { useDispatch } from "react-redux";
import eventBus from "../../assets/scripts/utils/eventBus.js";

const Page = ({ data }) => {
  let dataValues = data.crushes.nodes;
  console.log('DATA Values', dataValues);
  const dispatch = useDispatch();
  // Global state of the page
  const [filter, setFilter] = React.useState("all");
  const [crushes, setCrushes] = React.useState(dataValues);
  const [subCrushes, setSubCrushes] = React.useState([]);
  const [menuFilter, setMenuFilter] = React.useState(false);

  // subState of the page
  const [genreState, setGenreFilters] = React.useState(false);
  const [volumeState, setVolumeFilters] = React.useState(false);
  const [yearsState, setYearsFilters] = React.useState(false);
  const [wrapperState, setWrapperState] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  const [subfilterState, setSubfilterState] = React.useState({
    genre: [],
    volume: [],
    year: [],
  });

  // subState values
  const [allGenres, setAllGenres] = React.useState([
    ...new Set(crushes.map((crush) => crush.genre)),
  ]);
  //
  //
  const [allVolumes, setAllVolumes] = React.useState(["Série", "One Shot"]);
  const [allYears, setAllYears] = React.useState([
    ...new Set(crushes.map((crush) => crush.year)),
  ]);

  const randomArrayCarre = [
    [
      RandomCarréDesktop1,
      RandomCarréDesktop1Retina,
      RandomCarréMobile1,
      RandomCarréMobile1Retina,
    ],
    [
      RandomCarréDesktop2,
      RandomCarréDesktop2Retina,
      RandomCarréMobile2,
      RandomCarréMobile2Retina,
    ],
    [
      RandomCarréDesktop3,
      RandomCarréDesktop3Retina,
      RandomCarréMobile3,
      RandomCarréMobile3Retina,
    ],
    [
      RandomCarréDesktop4,
      RandomCarréDesktop4Retina,
      RandomCarréMobile4,
      RandomCarréMobile4Retina,
    ],
    [
      RandomCarréDesktop5,
      RandomCarréDesktop5Retina,
      RandomCarréMobile5,
      RandomCarréMobile5Retina,
    ],
    [
      RandomCarréDesktop6,
      RandomCarréDesktop6Retina,
      RandomCarréMobile6,
      RandomCarréMobile6Retina,
    ],
    [
      RandomCarréDesktop7,
      RandomCarréDesktop7Retina,
      RandomCarréMobile7,
      RandomCarréMobile7Retina,
    ],
    [
      RandomCarréDesktop8,
      RandomCarréDesktop8Retina,
      RandomCarréMobile8,
      RandomCarréMobile8Retina,
    ]
  ];
  const randomArrayRect = [
    [
      RandomRectangleDesktop1,
      RandomRectangleDesktop1Retina,
      RandomRectangleMobile1,
      RandomRectangleMobile1Retina,
    ],
    [
      RandomRectangleDesktop2,
      RandomRectangleDesktop2Retina,
      RandomRectangleMobile2,
      RandomRectangleMobile2Retina,
    ],
    [
      RandomRectangleDesktop3,
      RandomRectangleDesktop3Retina,
      RandomRectangleMobile3,
      RandomRectangleMobile3Retina,
    ],
    [
      RandomRectangleDesktop4,
      RandomRectangleDesktop4Retina,
      RandomRectangleMobile4,
      RandomRectangleMobile4Retina,
    ],
    [
      RandomRectangleDesktop5,
      RandomRectangleDesktop5Retina,
      RandomRectangleMobile5,
      RandomRectangleMobile5Retina,
    ],
    [
      RandomRectangleDesktop6,
      RandomRectangleDesktop6Retina,
      RandomRectangleMobile6,
      RandomRectangleMobile6Retina,
    ],
    [
      RandomRectangleDesktop7,
      RandomRectangleDesktop7Retina,
      RandomRectangleMobile7,
      RandomRectangleMobile7Retina,
    ]
  ];
  const randomArrayCarreWebp = [
    [
      RandomCarréDesktop1Webp,
      RandomCarréDesktop1RetinaWebp,
      RandomCarréMobile1Webp,
      RandomCarréMobile1RetinaWebp,
    ],
    [
      RandomCarréDesktop2Webp,
      RandomCarréDesktop2RetinaWebp,
      RandomCarréMobile2Webp,
      RandomCarréMobile2RetinaWebp,
    ],
    [
      RandomCarréDesktop3Webp,
      RandomCarréDesktop3RetinaWebp,
      RandomCarréMobile3Webp,
      RandomCarréMobile3RetinaWebp,
    ],
    [
      RandomCarréDesktop4Webp,
      RandomCarréDesktop4RetinaWebp,
      RandomCarréMobile4Webp,
      RandomCarréMobile4RetinaWebp,
    ],
    [

      RandomCarréDesktop5Webp,
      RandomCarréDesktop5RetinaWebp,
      RandomCarréMobile5Webp,
      RandomCarréMobile5RetinaWebp,
    ],
    [
      RandomCarréDesktop6Webp,
      RandomCarréDesktop6RetinaWebp,
      RandomCarréMobile6Webp,
      RandomCarréMobile6RetinaWebp,
    ],
    [
      RandomCarréDesktop7Webp,
      RandomCarréDesktop7RetinaWebp,
      RandomCarréMobile7Webp,
      RandomCarréMobile7RetinaWebp,
    ],
    [
      RandomCarréDesktop8Webp,
      RandomCarréDesktop8RetinaWebp,
      RandomCarréMobile8Webp,
      RandomCarréMobile8RetinaWebp,
    ]
  ];
  const randomArrayRectWebp = [
    [
      RandomRectangleDesktop1Webp,
      RandomRectangleDesktop1RetinaWebp,
      RandomRectangleMobile1Webp,
      RandomRectangleMobile1RetinaWebp,
    ],
    [
      RandomRectangleDesktop2Webp,
      RandomRectangleDesktop2RetinaWebp,
      RandomRectangleMobile2Webp,
      RandomRectangleMobile2RetinaWebp,
    ],
    [
      RandomRectangleDesktop3Webp,
      RandomRectangleDesktop3RetinaWebp,
      RandomRectangleMobile3Webp,
      RandomRectangleMobile3RetinaWebp,
    ],
    [
      RandomRectangleDesktop4Webp,
      RandomRectangleDesktop4RetinaWebp,
      RandomRectangleMobile4Webp,
      RandomRectangleMobile4RetinaWebp,
    ],
    [
      RandomRectangleDesktop5Webp,
      RandomRectangleDesktop5RetinaWebp,
      RandomRectangleMobile5Webp,
      RandomRectangleMobile5RetinaWebp,
    ],
    [
      RandomRectangleDesktop6Webp,
      RandomRectangleDesktop6RetinaWebp,
      RandomRectangleMobile6Webp,
      RandomRectangleMobile6RetinaWebp,
    ],
    [
      RandomRectangleDesktop7Webp,
      RandomRectangleDesktop7RetinaWebp,
      RandomRectangleMobile7Webp,
      RandomRectangleMobile7RetinaWebp,
    ]
  ];
  const randomRectangleArray = [[]];

  const handleFilter = (filterValue) => {
    if (filterValue === filter) {
      setFilter("all");
      setFilter((v) => {
        window.scroll(0, 0);
        return v;
      });
      return;
    }

    setFilter("all");
    setGenreFilters(false);
    setVolumeFilters(false);
    setYearsFilters(false);
    setWrapperState(false);
    setSubfilterState({
      genre: [],
      volume: [],
      year: [],
    });
    setSubCrushes([]);

    setSubCrushes((value) => {
      setFilter(filterValue);

      setFilter((v) => {
        window.scroll(0, 0);
        handleSubFilter(filter);
        return v;
      });

      return value;
    });
  };

  const handleSubFilter = (filterValue) => {
    switch (filterValue) {
      case "genre":
        setGenreFilters(!genreState);
        // if genre Filters is true set false others
        if (!genreState) {
          setWrapperState("genre");
          setVolumeFilters(false);
          setYearsFilters(false);
        } else {
          setWrapperState(false);
        }
        break;
      case "volume":
        setVolumeFilters(!volumeState);
        // if volume Filters is true set false others
        if (!volumeState) {
          setWrapperState("volume");
          setGenreFilters(false);
          setYearsFilters(false);
        } else {
          setWrapperState(false);
        }
        break;
      case "year":
        setYearsFilters(!yearsState);
        // if year Filters is true set false others
        if (!yearsState) {
          setWrapperState("year");
          setVolumeFilters(false);
          setGenreFilters(false);
        } else {
          setWrapperState(false);
        }
        break;
      default:
        break;
    }
  };

  const FilterWrapper = () => {
    const [subfilters, setSubfilter] = React.useState();
    const [crushesInital, setCrushesInital] = React.useState(crushes);

    React.useEffect(() => {
      switch (wrapperState) {
        case "genre":
          setSubfilter([...new Set(allGenres)]);
          break;

        case "volume":
          setSubfilter(allVolumes);
          break;

        case "year":
          setSubfilter([...new Set(allYears)].sort().reverse());
          break;

        default:
          break;
      }
    }, [wrapperState]);

    const handleLittleFilter = (value) => {
      if (subfilterState) {
        if (subfilterState[wrapperState].includes(value)) {
          // remove value from subfilterState
          setSubfilterState({
            ...subfilterState,
            [wrapperState]: [
              ...subfilterState[wrapperState].filter((item) => item !== value),
            ],
          });
        } else {
          setSubfilterState({
            ...subfilterState,
            [wrapperState]: [...subfilterState[wrapperState], value],
          });
        }
        setSubfilterState((stateSub) => {
          // filter the subCrushes
          updateSubFilters(stateSub);

          return stateSub;
        });
      }
    };

    const updateSubFilters = (stateSub) => {
      let newCrushes = [];
      // filter by subfilterState property {genre, volume, year}
      // For each property length > 0  push filtered result to. newCrushes
      Object.keys(stateSub).forEach((key) => {
        if (stateSub[key].length > 0) {
          newCrushes = [
            ...newCrushes,
            ...crushesInital.filter((crush) =>
              stateSub[key].includes(crush[key])
            ),
          ];
        }
      });

      // verify if stateSub keys all property is empty
      // if is all empty set SubCruches to empty
      if (Object.keys(stateSub).every((key) => stateSub[key].length === 0)) {
        newCrushes = [];
        setSubCrushes(newCrushes);
      }

      // if newCrushes length > 0 merge all result in one array
      setTimeout(() => {
        if (newCrushes.length > 0) {
          // remove duplicates in newCrushes
          newCrushes = [...new Set(newCrushes)];
          setSubCrushes(newCrushes);
        }
      }, 100);
    };

    React.useEffect(() => {
      if (document.querySelector(".bi-subFilters")) {
        let tl = gsap.timeline();

        tl.from(".bi-subFilters", { height: 0 });
        tl.from(".bi-subFilters li", { opacity: 0, y: 16, stagger: 0.05 });
      }
    }, []);

    return (
      <>
        {subfilters && subfilters.length > 0 && (
          <ul className="bi-subFilters">
            {subfilters.map((subfilter) => (
              <li
                key={subfilter}
                className={
                  subfilterState[wrapperState].includes(subfilter)
                    ? "open"
                    : "close"
                }
              >
                <FilterButton
                  type="small-filter"
                  close={subfilterState[wrapperState].includes(subfilter)}
                  onClick={(e) => {
                    setCrushes(crushesInital);
                    handleLittleFilter(subfilter);
                  }}
                >
                  {" "}
                  {subfilter ? subfilter : "inconnu"}{" "}
                </FilterButton>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  const FilterWrapperMobile = ({
    closeModal,
    state,
    allGenres,
    allVolumes,
    allYears,
    crushes,
  }) => {
    const [crushesInital, setCrushesInital] = React.useState(crushes);

    // console.log(
    //   "Current State",
    //   state,
    //   allGenres,
    //   allVolumes,
    //   allYears,
    //   crushes
    // );

    const handleLittleFilter = (value, type) => {
      if (subfilterState) {
        if (subfilterState[type].includes(value)) {
          // remove value from subfilterState
          setSubfilterState({
            ...subfilterState,
            [type]: [...subfilterState[type].filter((item) => item !== value)],
          });
        } else {
          setSubfilterState({
            ...subfilterState,
            [type]: [...subfilterState[type], value],
          });
        }
        setSubfilterState((stateSub) => {
          // filter the subCrushes
          updateSubFilters(stateSub);

          return stateSub;
        });
      }
    };

    const updateSubFilters = (stateSub) => {
      let newCrushes = [];
      // filter by subfilterState property {genre, volume, year}
      // For each property length > 0  push filtered result to. newCrushes
      Object.keys(stateSub).forEach((key) => {
        if (stateSub[key].length > 0) {
          newCrushes = [
            ...newCrushes,
            ...crushesInital.filter((crush) =>
              stateSub[key].includes(crush[key])
            ),
          ];
        }
      });

      // verify if stateSub keys all property is empty
      // if is all empty set SubCruches to empty
      if (Object.keys(stateSub).every((key) => stateSub[key].length === 0)) {
        newCrushes = [];
        setSubCrushes(newCrushes);
      }

      // if newCrushes length > 0 merge all result in one array
      setTimeout(() => {
        if (newCrushes.length > 0) {
          // remove duplicates in newCrushes
          newCrushes = [...new Set(newCrushes)];
          setSubCrushes(newCrushes);
        }
      }, 100);
    };

    return (
      <div className="bi-filter-menu-mobile-container">
        <div className="bi-filter-menu-mobile-header">
          <div className="bi-filter-menu-mobile-header__content">
            <h4>Filtrer par </h4>
            <IconButton
              type="modal-cross"
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </div>
        <hr />
        <div className="bi-filter-menu-mobile-three-details-container">
          <div className="bi-filter-menu-mobile-three-detail detail-one">
            <details open={genreState}>
              <summary
                onClick={() => {
                  setGenreFilters(!genreState);
                }}
              >
                <p>Genre</p>
                <div className="bi-filter-menu-mobile-cross-container">
                  <div className="bi-filter-menu-mobile-cross cross-one">
                    <svg
                      className="moins-filter"
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 2H0V0H16V2Z" fill="black" />
                    </svg>

                    <svg
                      className="plus-filter"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </summary>
              <div>
                {[...new Set(allGenres)].map((el, index) => {
                  return (
                    <FilterButton
                      key={index}
                      type="small-filter"
                      close={state.genre.includes(el)}
                      onClick={() => {
                        setCrushes(crushesInital);
                        handleLittleFilter(el, "genre");
                      }}
                    >
                      {el}
                    </FilterButton>
                  );
                })}
              </div>
            </details>
          </div>

          <div className="bi-filter-menu-mobile-three-detail detail-two">
            <details open={volumeState}>
              <summary
                onClick={() => {
                  setVolumeFilters(!volumeState);
                }}
              >
                <p>Volume</p>
                <div className="bi-filter-menu-mobile-cross-container">
                  <div className="bi-filter-menu-mobile-cross cross-two">
                    <svg
                      className="moins-filter"
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 2H0V0H16V2Z" fill="black" />
                    </svg>

                    <svg
                      className="plus-filter"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </summary>
              <div>
                {allVolumes.map((el, index) => {
                  return (
                    <FilterButton
                      close={state.volume.includes(el)}
                      filtered={null}
                      type="small-filter"
                      onClick={() => {
                        setCrushes(crushesInital);
                        handleLittleFilter(el, "volume");
                      }}
                    >
                      {el}
                    </FilterButton>
                  );
                })}
              </div>
            </details>
          </div>

          <div className="bi-filter-menu-mobile-three-detail detail-three">
            <details open={yearsState}>
              <summary
                onClick={() => {
                  setYearsFilters(!yearsState);
                }}
              >
                <p>Année</p>
                <div className="bi-filter-menu-mobile-cross-container">
                  <div className="bi-filter-menu-mobile-cross cross-three">
                    <svg
                      className="moins-filter"
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 2H0V0H16V2Z" fill="black" />
                    </svg>

                    <svg
                      className="plus-filter"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </summary>
              <div>
                {[...new Set(allYears)].map((el, index) => {
                  return (
                    <FilterButton
                      close={state.year.includes(el)}
                      type="small-filter"
                      onClick={() => {
                        setCrushes(crushesInital);
                        handleLittleFilter(el, "year");
                      }}
                    >
                      {el}
                    </FilterButton>
                  );
                })}
              </div>
            </details>
          </div>
        </div>
      </div>
    );
  };

  const handleFilterHover = (filterValue) => {
    // when hover on a filter and they are not active
    // select all crush who don't mathe the filter and reduce their opacity
    switch (filterValue) {
      case "Jean-Louis":
        // Select all .bi-crushes-content_gridItem that don't contain "bi-Jean-Louis" as className
        document
          .querySelectorAll(".bi-crushes-content_gridItem:not(.bi-Jean-Louis)")
          .forEach((el) => {
            el.classList.add("bi-opacity-50");
          });

        // remove the opacity from the selected filter
        document.querySelectorAll(".bi-Jean-Louis").forEach((el) => {
          el.classList.remove("bi-opacity-50");
        });
        break;
      case "Jonathan":
        // Select all .bi-crushes-content_gridItem that don't contain "bi-Jonathan" as className
        document
          .querySelectorAll(".bi-crushes-content_gridItem:not(.bi-Jonathan)")
          .forEach((el) => {
            el.classList.add("bi-opacity-50");
          });

        // remove the opacity from the selected filter
        document.querySelectorAll(".bi-Jonathan").forEach((el) => {
          el.classList.remove("bi-opacity-50");
        });
        break;
      case "Maxime":
        // Select all .bi-crushes-content_gridItem that don't contain "bi-Maxime" as className
        document
          .querySelectorAll(".bi-crushes-content_gridItem:not(.bi-Maxime)")
          .forEach((el) => {
            el.classList.add("bi-opacity-50");
          });

        // remove the opacity from the selected filter
        document.querySelectorAll(".bi-Maxime").forEach((el) => {
          el.classList.remove("bi-opacity-50");
        });
        break;
      case "Camille":
        // Select all .bi-crushes-content_gridItem that don't contain "bi-Camille" as className
        document
          .querySelectorAll(".bi-crushes-content_gridItem:not(.bi-Camille)")
          .forEach((el) => {
            el.classList.add("bi-opacity-50");
          });

        // remove the opacity from the selected filter
        document.querySelectorAll(".bi-Camille").forEach((el) => {
          el.classList.remove("bi-opacity-50");
        });
        break;
      case "Sorti du grenier":
        // Select all .bi-crushes-content_gridItem that don't contain "bi-Sortie-du-grenier" as className
        document
          .querySelectorAll(".bi-crushes-content_gridItem:not(.bi-Sorti)")
          .forEach((el) => {
            el.classList.add("bi-opacity-50");
          });

        // remove the opacity from the selected filter
        document.querySelectorAll(".bi-Sortie-du-grenier").forEach((el) => {
          el.classList.remove("bi-opacity-50");
        });
        break;
      default:
        // remove the opacity from all filters
        document
          .querySelectorAll(".bi-crushes-content_gridItem")
          .forEach((el) => {
            el.classList.remove("bi-opacity-50");
          });
        break;
    }
  };

  const handleSlugFilter = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.get('filter')){
      let filterValue = urlParams.get('filter');
      handleFilter(filterValue);
    }
  }

  React.useEffect(() => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    handleSlugFilter();
    window.addEventListener("resize", () => {
      if (window.innerWidth < 769) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

  }, []);


  React.useEffect(() => {
    // changes crushes to display value depending on filter
    if (filter === "all") {
      setCrushes(data.crushes.nodes);
    } else {
      setCrushes(
        data.crushes.nodes.filter((crush) =>
          crush.author_article.includes(filter)
        ) || data.crushes.nodes
      );
    }

    // update substate values
    setCrushes((value) => {
      setAllGenres(value.map((crush) => crush.genre));
      setAllVolumes(["Série", "One Shot"]);
      setAllYears(value.map((crush) => crush.year));

      return value;
    });
  }, [filter, subfilterState]);

  return (
    <>
      <Helmet>
        <title>
          Coups de coeur | Librairie Expérience : les coups de coeur BD de
          l’équipe
        </title>
        <meta
          name="description"
          content="Découvrez les coups de coeur BD de nos libraires Jean-Louis, Camille, Jonathan et Maxime."
        />
      </Helmet>
      <section className="bi-layout bi-crushes">
        <div className="bi-crushes-heading">
          <h1 className="bi-title-tertiary">Nos coups de coeur</h1>
          <p>
            On a tous nos chouchous ! Notre équipe ne déroge pas à la règle et
            c’est pour cette raison que chaque membre présente ses propres coups
            de cœurs.
          </p>
          <div
            className="bi-heading-filter-wrapper"
            data-Filter={filter != "all" ? true : false}
          >
            <FilterButton
              type="big"
              data-open={filter === "Jean-Louis" ? true : false}
              close={!(filter === "Jean-Louis" ? true : false)}
              className="bi-button bi-button-filter_big red"
              onClick={() => handleFilter("Jean-Louis")}
              onMouseEnter={() => handleFilterHover("Jean-Louis")}
              onMouseOver={() => handleFilterHover("Jean-Louis")}
              onMouseLeave={() => handleFilterHover(null)}
            >
              Jean-Louis
            </FilterButton>

            <FilterButton
              type="big"
              data-open={filter === "Jonathan" ? true : false}
              close={!(filter === "Jonathan" ? true : false)}
              className="bi-button bi-button-filter_big orange"
              onClick={() => handleFilter("Jonathan")}
              onMouseEnter={() => handleFilterHover("Jonathan")}
              onMouseOver={() => handleFilterHover("Jonathan")}
              onMouseLeave={() => handleFilterHover(null)}
            >
              Jonathan
            </FilterButton>
            <FilterButton
              type="big"
              data-open={filter === "Maxime" ? true : false}
              close={!(filter === "Maxime" ? true : false)}
              className="bi-button bi-button-filter_big yellow"
              onClick={() => handleFilter("Maxime")}
              onMouseEnter={() => handleFilterHover("Maxime")}
              onMouseOver={() => handleFilterHover("Maxime")}
              onMouseLeave={() => handleFilterHover(null)}
            >
              Maxime
            </FilterButton>
            <FilterButton
              type="big"
              data-open={filter === "Camille" ? true : false}
              close={!(filter === "Camille" ? true : false)}
              className="bi-button bi-button-filter_big blue"
              onClick={() => handleFilter("Camille")}
              onMouseEnter={() => handleFilterHover("Camille")}
              onMouseOver={() => handleFilterHover("Camille")}
              onMouseLeave={() => handleFilterHover(null)}
            >
              Camille
            </FilterButton>
            <FilterButton
              type="big"
              data-open={filter === "Sorti du grenier" ? true : false}
              close={!(filter === "Sorti du grenier" ? true : false)}
              onClick={() => handleFilter("Sorti du grenier")}
              onMouseEnter={() => handleFilterHover("Sorti du grenier")}
              onMouseOver={() => handleFilterHover("Sorti du grenier")}
              onMouseLeave={() => handleFilterHover(null)}
            >
              Sorti du grenier
            </FilterButton>
          </div>
        </div>
        <div className="bi-crushes-content">
          <div
            className={`bi-crushes-content_filterSlot ${
              isMobile ? "notShowing" : ""
            }`}
          >
            <p>Filtrer par :</p>
            <div>
              <FilterButton
                type="small"
                close={genreState}
                filtered={subfilterState.genre.length > 0}
                onClick={(e) => {
                  handleSubFilter("genre");
                }}
              >
                Genre
              </FilterButton>
              <FilterButton
                type="small"
                close={volumeState}
                filtered={subfilterState.volume.length > 0}
                onClick={(e) => {
                  handleSubFilter("volume");
                }}
              >
                Volume
              </FilterButton>
              <FilterButton
                type="small"
                close={yearsState}
                filtered={subfilterState.year.length > 0}
                onClick={(e) => {
                  handleSubFilter("year");
                }}
              >
                Année
              </FilterButton>
            </div>
            <div
              onClick={() => {
                setFilter("all");
                setGenreFilters(false);
                setVolumeFilters(false);
                setYearsFilters(false);
                setWrapperState(false);
                setSubfilterState({
                  genre: [],
                  volume: [],
                  year: [],
                });
                setSubCrushes([]);
              }}
              className="bi-reset"
            >
              <IconButton type="reset" />
            </div>
            <FilterWrapper state={wrapperState} />
          </div>
          <div
            className={`bi-crushes-mobile-filter ${
              isMobile ? "" : "notShowing"
            }`}
          >
            <hr />
            <div className="bi-crushes-mobile__content">
              <p>Filtrer par :</p>
              <div className="bi-crushes-mobile__btn">
                <div
                  onClick={() => {
                    setFilter("all");
                    setGenreFilters(false);
                    setVolumeFilters(false);
                    setYearsFilters(false);
                    setSubfilterState({
                      genre: [],
                      volume: [],
                      year: [],
                    });
                    setSubCrushes([]);
                  }}
                >
                  <IconButton type="resetBtnMobile" />
                </div>

                <div onClick={() => setMenuFilter(true)}>
                  <IconButton
                    type="filterBtnMobile"
                    onClick={() => setMenuFilter(true)}
                  />
                </div>
                {menuFilter &&
                  createPortal(
                    <FilterWrapperMobile
                      closeModal={() => setMenuFilter(false)}
                      state={subfilterState}
                      allGenres={allGenres}
                      allVolumes={allVolumes}
                      allYears={allYears}
                      crushes={crushes}
                      updateSubFilter={(value) => {}}
                    />,
                    document.querySelector(".bi-children-modal")
                  )}
              </div>
            </div>

            <hr />
          </div>
          <div className="bi-crushes-content_gridSlot">
            {(subCrushes.length > 0 &&
              subCrushes.map((crush, index) => {
                let objectCrush = {
                  slug: crush.slug.current,
                  name: crush.name,
                  picture: crush.cover.asset.path,
                  cropData: { ...crush.cover.crop, ...crush.cover.asset },
                  author: crush.author_article,
                };

                let imgID = Math.floor((Math.random() * 8) )
                let rectID = Math.floor((Math.random() * 7) )
                return (
                  <>
                    <div
                      className={
                        "bi-crushes-content_gridItem" +
                        " bi-" +
                        objectCrush.author
                      }
                      key={objectCrush.name}
                    >
                      <CrushListItem crush={objectCrush} desktopWidth="206" />
                    </div>
                    {
                      // if element in 4th position after each 16th element
                      // add a divider
                      index % 16 === 2 ? (
                        <picture
                          className="bi-divider divider_one"
                          data-id={imgID}
                        >
                          <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayCarreWebp[imgID][1]
                            }
                          />
                                                     <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayCarre[imgID][1] 
                            }
                            />
                          <source
                            media="(max-width: 767px)"
                            srcSet={
                              randomArrayCarreWebp[imgID][3] +
                              " 2x," +
                              randomArrayCarreWebp[imgID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(max-width: 767px)"
                            srcSet={
                              randomArrayCarre[imgID][3] +
                              " 2x," +
                              randomArrayCarre[imgID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayCarre[imgID][1] +
                              " 2x," +
                              randomArrayCarre[imgID][0] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayCarre[imgID][1] +
                              " 2x," +
                              randomArrayCarre[imgID][0] +
                              " 1x"
                            }
                          />
                          <img
                            src={randomArrayCarre[imgID][0]}
                            alt="Salle dela sérigraphie"
                          />
                        </picture>
                      ) : null
                    }
                    {
                      // if element in 4th position after each 16th element
                      // add a divider
                      index % 16 === 8 ? (
                        <picture className="bi-divider divider_two">
                          {" "}
                          <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayRectWebp[imgID][1]
                            }
                          />
                          <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayRect[imgID][1] 
                            }
                            />
                          <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[rectID][3] +
                              " 2x," +
                              randomArrayRectWebp[rectID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[rectID][3] +
                              " 2x," +
                              randomArrayRect[rectID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayRectWebp[rectID][1] +
                              " 2x," +
                              randomArrayRectWebp[rectID][0] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayRect[rectID][1] +
                              " 2x," +
                              randomArrayRect[rectID][0] +
                              " 1x"
                            }
                          />
                          <img
                            src={randomArrayRect[rectID][1]}
                            alt="Salle dela sérigraphie"
                          />
                        </picture>
                      ) : null
                    }
                    {
                      // if element in 4th position after each 16th element
                      // add a divider
                      index % 16 === 14 ? (
                        <picture className="bi-divider divider_three">
                          {" "}
                          <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayRectWebp[imgID][1]
                            }
                          />
                            <source
                              media="(min-width: 1440px)"
                              srcSet={
                                randomArrayRect[imgID][1] 
                              }
                              />
                          <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[rectID][3] +
                              " 2x," +
                              randomArrayRectWebp[rectID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[rectID][3] +
                              " 2x," +
                              randomArrayRect[rectID][2] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayRectWebp[rectID][1] +
                              " 2x," +
                              randomArrayRectWebp[rectID][0] +
                              " 1x"
                            }
                          />
                          <source
                            media="(min-width: 768px)"
                            srcSet={
                              randomArrayRect[rectID][1] +
                              " 2x," +
                              randomArrayRect[rectID][0] +
                              " 1x"
                            }
                          />
                          <img
                            src={randomArrayRect[rectID][0]}
                            alt="Salle dela sérigraphie"
                          />
                        </picture>
                      ) : null
                    }
                  </>
                );
              })) ||
              (crushes &&
                crushes.map((crush, index) => {
                  let objectCrush = {
                    slug: crush.slug.current,
                    name: crush.name,
                    picture: crush.cover.asset.path,
                    cropData: { ...crush.cover.crop, ...crush.cover.asset },
                    author: crush.author_article,
                  };
                  // console.log(objectCrush);
                  return (
                    <>
                      <div
                        className={
                          "bi-crushes-content_gridItem" +
                          " bi-" +
                          objectCrush.author
                        }
                        key={objectCrush.name}
                      >
                        <CrushListItem crush={objectCrush} desktopWidth="206" />
                      </div>
                      {
                        // if element in 4th position after each 16th element
                        // add a divider
                        index % 16 === 2 ? (
                          <picture
                            className="bi-divider divider_one"
                            data-id={
                                  Math.floor((Math.random() * 8) )
                            }
                          >
                            <source
                              media="(min-width: 1440px)"
                              srcSet={
                                randomArrayCarreWebp[
                                  Math.floor((Math.random() * 8))
                                ][1]
                              }
                            />
                              <source
                              media="(min-width: 1440px)"
                              srcSet={
                                randomArrayCarre[Math.floor((Math.random() * 8) )][1] 
                              }
                              />
                            <source
                              media="(max-width: 767px)"
                              srcSet={
                                randomArrayCarreWebp[
                                  Math.floor((Math.random() * 8))
                                ][3] +
                                " 2x," +
                                randomArrayCarreWebp[
                                  Math.floor((Math.random() * 8) )
                                ][2] +
                                " 1x"
                              }
                            />
                            <source
                              media="(max-width: 767px)"
                              srcSet={
                                randomArrayCarre[
                                  Math.floor((Math.random() * 8))
                                ][3] +
                                " 2x," +
                                randomArrayCarre[
                                  Math.floor((Math.random() * 8) )
                                ][2] +
                                " 1x"
                              }
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={
                                randomArrayCarreWebp[
                                  Math.floor((Math.random() * 8))
                                ][1] +
                                " 2x," +
                                randomArrayCarreWebp[
                                  Math.floor((Math.random() * 8))
                                ][0] +
                                " 1x"
                              }
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={
                                randomArrayCarre[
                                  Math.floor((Math.random() * 8))
                                ][1] +
                                " 2x," +
                                randomArrayCarre[
                                  Math.floor((Math.random() * 8))
                                ][0] +
                                " 1x"
                              }
                            />
                            <img
                              src={
                                randomArrayCarre[
                                  Math.floor((Math.random() * 8))
                                ][0]
                              }
                              alt="Salle dela sérigraphie"
                            />
                          </picture>
                        ) : null
                      }
                      {
                        // if element in 4th position after each 16th element
                        // add a divider
                        index % 16 === 8 ? (
                          <picture className="bi-divider divider_two">
                            {" "}
                            <source
                              media="(min-width: 1440px)"
                              srcSet={
                                randomArrayRectWebp[Math.floor((Math.random() * 7))][1]
                              }
                            />
                                                       <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7) )][1] 
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[Math.floor((Math.random() * 7))][3] +
                              " 2x," +
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][2] +
                              " 1x"
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7))][3] +
                              " 2x," +
                              randomArrayRect[Math.floor((Math.random() * 7) )][2] +
                              " 1x"
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][1] +
                              " 2x," +
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][0] +
                              " 1x"
                            }
                          />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7) )][1] +
                              " 2x," +
                              randomArrayRect[Math.floor((Math.random() * 7) )][0] +
                              " 1x"
                            }
                          />
                            <img
                              src={randomArrayRect[Math.floor((Math.random() * 7) )][0]}
                              alt="Salle dela sérigraphie"
                            />
                          </picture>
                        ) : null
                      }
                      {
                        // if element in 4th position after each 16th element
                        // add a divider
                        index % 16 === 14 ? (
                          <picture className="bi-divider divider_three">
                            {" "}
                            <source
                              media="(min-width: 1440px)"
                              srcSet={
                                randomArrayRectWebp[Math.floor((Math.random() * 7))][1]
                              }
                            />
                            <source
                            media="(min-width: 1440px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7) )][1] 
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][3] +
                              " 2x," +
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][2] +
                              " 1x"
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7) )][3] +
                              " 2x," +
                              randomArrayRect[Math.floor((Math.random() * 7) )][2] +
                              " 1x"
                            }
                            />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][1] +
                              " 2x," +
                              randomArrayRectWebp[Math.floor((Math.random() * 7) )][0] +
                              " 1x"
                            }
                          />
                            <source
                            media="(min-width: 767px)"
                            srcSet={
                              randomArrayRect[Math.floor((Math.random() * 7) )][1] +
                              " 2x," +
                              randomArrayRect[Math.floor((Math.random() * 7) )][0] +
                              " 1x"
                            }
                          />
                            <img
                              src={randomArrayRect[Math.floor((Math.random() * 7) )][0]}
                              alt="Salle dela sérigraphie"
                            />
                          </picture>
                        ) : null
                      }
                    </>
                  );
                }))}
            {!crushes && (
              <p>Il n'y a pas encore de coups de coeur pour le moment.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    crushes: allSanityCrushDocumentType(sort: {order: DESC, fields: _createdAt}) {
      nodes {
        slug {
          current
        }
        author_article
        name
        genre
        year(locale: "fr-FR", formatString: "YYYY")
        volume
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
          }
        }
      }
    }
  }
`;

export default Page;
