import React from "react";
import Button from "../../utils/atoms/Button.jsx";
import { Link, navigate } from "gatsby";

//Get dispatch and state from redux store and the slice of the authentication
import { useSelector, useDispatch } from "react-redux";
import {
  setConnected,
  setData,
} from "../../../assets/scripts/store/redux-slices/authentication.js";

import "../../../assets/styles/components/modules/forms.scss";

export const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { connected } = useSelector((state) => state.authentication);
  // Get authentication data from redux store
  const userData = useSelector((state) => state.authentication.data);

  //Check if the user is connected
  const redirect = () => {
    if (connected) {
      // Redirect to the home page
      navigate("/account/mon-compte/");
    }
  };

  React.useEffect(() => {
    if (connected) {
      setTimeout(()=>{
        redirect();
      }, 100)
    }
  }, [connected]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all fields are filled
    if (email === "" || password === "") {
      setError("Email et/ou mot de passe incorrect");
      setSuccess("");
      setLoading(false);
      let pwInput = document.querySelector('input[type="password"]');
      let mailInput = document.querySelector('input[type="email"]');
      pwInput.classList.add("borderError");
      mailInput.classList.add("borderError");
      return;
    }

    // send request to netlify function login-user
    setLoading(true);
    fetch("/.netlify/functions/login-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((res) => {
      if (res.status === 200) {
        setSuccess("Login successful");
        setError("");
        setLoading(false);
        res.json().then((data) => {
          dispatch(setConnected(true));
          dispatch(setData(data[0]));
          // set cookies lib-experience-connected to true and lib-experience-user-id to the user id
          document.cookie = `lib-experience-connected=true; path=/; max-age=31536000`;
          document.cookie = `lib-experience-user-id=${data[0]._id}; path=/; max-age=31536000`;
          // Redirect to the home page
          setTimeout(() => {
            navigate("/account/mon-compte/");
          }, 100);
        });
      } else {
        setError("Email et/ou mot de passe incorrect");
        let pwInput = document.querySelector('input[type="password"]');
        let mailInput = document.querySelector('input[type="email"]');
        pwInput.classList.add("borderError");
        mailInput.classList.add("borderError");
        setSuccess("");
        setLoading(false);
        //delete cookies lib-experience-connected and lib-experience-user-id
        document.cookie = `lib-experience-connected=; path=/; max-age=0`;
        document.cookie = `lib-experience-user-id=; path=/; max-age=0`;
      }
    });
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        {connected && <p className="success">Vous êtes connecté</p>}
      </div>
      <div className="bi-form-loader">
        {/*<!--- Loading manager --->*/}
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={password}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <Link to="/account/lost-password">
        {" "}
        <p className="forgetpw">Mot de passe oublié ?</p>
      </Link>
      <Button className="bi-button yellow login-account" type="submit" value="Connexion">Connexion</Button>
    </form>
  );
};

export default Login;
