import * as React from "react";
import { graphql } from "gatsby";
import {PortableText} from '@portabletext/react'

import "../assets/styles/pages/legals.scss";
import { Helmet } from "react-helmet";

const Page = (props) => {
  console.log(props.data.sanityLegalDocumentType);
  const { content, title , id, _rawContent} = props.data.sanityLegalDocumentType;
  return (
    <>
    <Helmet>
      <title>Mentions légales | Librairie Expérience : librairie BD indépendante à Lyon</title>
      <meta name="description" content="Consultez nos mentions légales et conditions générales de vente à tout moment ou contactez-nous depuis la rubrique Contact de notre site." />
    </Helmet>
    <section className="bi-layout bi-legals">
      <h1>{title}</h1>
      <div className="bi-legal-content">
        <PortableText value={props.data.sanityLegalDocumentType._rawContent} />
      </div>
    </section>
    </>
  );
};

export const query = graphql`
  {
    sanityLegalDocumentType {
      title
      id
      _rawContent
      content {
        _key
        _type
        style
        list
        _rawChildren
      }
    }
  }
`;

export default Page;
