import React from "react";
import Button from "../../utils/atoms/Button";
import { navigate } from "gatsby";

import "../../../assets/styles/components/modules/forms.scss";

export const Contact = () => {
  const [firstName, setFirstName] = React.useState(""); // Required
  const [lastName, setLastName] = React.useState(""); // Required
  const [orderIdentifiant, setOrderIdentifiant] = React.useState(""); // Non required
  const [object, setObject] = React.useState(""); // Required
  const [email, setEmail] = React.useState(""); // Required
  const [message, setMessage] = React.useState(""); // Required
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "orderIdentifiant":
        setOrderIdentifiant(value);
        break;
      case "object":
        setObject(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all obligatory fields are filled
    if (
      firstName === "" ||
      lastName === "" ||
      object === "" ||
      email === "" ||
      message === ""
    ) {
      setError("Remplissez tous les champs");
      setSuccess("");
      setLoading(false);
      return;
    }

    // send request to netlify function contact-form
    setLoading(true);
    fetch("/.netlify/functions/send-mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        order: orderIdentifiant,
        object: object,
        email: email,
        message: message,
        type: "contact-form",
      }),
    }).then((res) => {
      setSuccess(
        "Votre demande nous est bien parvenue, nous reviendrons vers vous dans les plus brefs délais !"
      );
      setError("");
      setLoading(false);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="bi-form-error">
        {/*<!--- Error manager --->*/}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
      <div className="bi-form-loader">
        {loading && <p className="loading">Chargement...</p>}
      </div>
      <input
        type="text"
        name="firstName"
        placeholder="Prénom*"
        value={firstName}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="lastName"
        placeholder="Nom*"
        value={lastName}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="orderIdentifiant"
        placeholder="Numéro de commande"
        value={orderIdentifiant}
        onChange={handleChange}
      />
      <input
        type="text"
        name="object"
        placeholder="Objet*"
        value={object}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email*"
        value={email}
        onChange={handleChange}
        required
      />
      <textarea
        name="message"
        placeholder="Message*"
        value={message}
        onChange={handleChange}
        required
      />
      <div className="bi-form-footer">
        <p className="small">*Champs obligatoires</p>
        <Button className="bi-button yellow" type="submit" value="Envoyer">Envoyer</Button>
      </div>
    </form>
  );
};

export default Contact;
