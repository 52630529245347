import React, { useState, createContext } from "react";

import reduxStore from "../assets/scripts/store/redux";

import { Provider } from "react-redux";

import { Helmet } from "react-helmet";

import "../assets/styles/utils/root.scss";

import Loader from "../components/modules/biAnimations/Loader";

import Header from "./modules/Header";
import Footer from "./modules/Footer";
import Cart from "./modules/Cart";
import Search from "./modules/Search";
import Cookies from "./utils/cookies/Cookies";
import Menu from "./modules/Menu";
import Partner from "./modules/about/partner.jsx";
import eventBus from "../assets/scripts/utils/eventBus";
import ScrollBar from "./utils/atoms/ScrollBar";
import PageTransition from "./modules/biAnimations/PageTransition";

import useSmoothScroll from "../assets/scripts/utils/hooks/useSmoothScroll";

import parallaxAnimation from "../assets/scripts/utils/parallaxAnimation";

const DefaultLayout = ({ children, location }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const scroll = useSmoothScroll();

  React.useEffect(() => {
    eventBus.on("scrollStop", () => {
      scroll?.stop();
      document.body.style.overflow = "hidden";
    });
    eventBus.on("scrollStart", () => {
      scroll?.start();
      document.body.style.overflow = "";
    });

    scroll?.virtualScroll.on(({ y, deltaY }) => {
      eventBus.dispatch("headerScroll", { y, deltaY });
    });
  }, [scroll]);

  React.useEffect(() => {
    console.log(
      "%c 🚀 Lead Dev by Donaël Walter, Design by Lucas Macaluso, Made at Studio Bihua https://studio-bihua.fr/ ",
      "color:white; background-color:black; padding: 16px;"
    );

    const initParallax = () => {
      parallaxAnimation.imagesParallax();
      parallaxAnimation.blockParallax();
    };

    eventBus.on("loaderEnd", () => {
      initParallax()
    });
    window.addEventListener("resize", () => {
      // initParallax();
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    });
    eventBus.on("pageTransitionEnd", () => {
      initParallax();
    });
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "fr",
        }}
      />
      <Provider store={reduxStore}>
        <ScrollBar/>
        <Loader />
        <div
          className={
            "bi-layout-content " +
            location.pathname.replace(/[^a-zA-Z0-9]/g, "-").replace("-", "")
          }
        >
          <Cookies />

          <Header />
          <Cart />
          <Search />
          <Menu />
          <Partner />
          <PageTransition location={location.pathname}>
            <main data-location={location.pathname}>
              <div className="bi-fake-header"></div>
              {children}
            </main>
          </PageTransition>
          <Footer />
          <div className="bi-children-modal"></div>
        </div>
      </Provider>
    </>
  );
};

export default DefaultLayout;
