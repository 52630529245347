import React from "react";
import "../../../assets/styles/pages/message.scss";
import Button from "../../../components/utils/atoms/Button.jsx"
const Page = () => {
  // Page component when stripe payment is canceled
  const [sessionData, setSessionData] = React.useState({});
  const [sessionStructuredData, setSessionStructureData] = React.useState({
    clientProducts: [
      {
        productName: null,
        productPrice: null,
        productQuantity: null,
      },
    ],
    clientTotal: null,
  });
  // Page component when stripe payment is canceled
  React.useEffect(() => {
    // Get the session id from the url
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );
    if (sessionId) {
      // Get the session data from the netlify function retrieve-checkout-session.js endpoint
      fetch("/.netlify/functions/retrieve-checkout-session", {
        method: "POST",
        body: JSON.stringify({ sessionId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setSessionData(data);
          setSessionStructureData({
            clientProducts: data.session.line_items.data.map((item) => ({
              productName: item.description,
              productPrice: item.price.unit_amount / 100,
              productQuantity: item.quantity,
            })),
            clientTotal: data.session.payment_intent.amount / 100,
          });
        });
    }
  }, []);
  return (
    <>
      <section className="bi-message-page">
        <h1 className="bi-title-secondary">Processus de <span className="bi-span-title-1">paiement</span> <span className="bi-span-title-2">abandonné</span></h1>
        <div className="bi-recap-commande">
          {sessionData && sessionStructuredData.clientProducts && (
            <>
              <h2 className="bi-title-tertiary">Votre commande</h2>
            <hr />

              <ul>
                {sessionStructuredData.clientProducts.map((product, index) => {
                  return (
                    <li key={index}>
                      <h3 className="bi-title-quaternary">
                        {product.productName}
                      </h3>
                      <div>{product.productQuantity * product.productPrice}€</div>
                    </li>
                  );
                })}
                <li >
                  <p>
                    Total
                    </p> 
                  <p>
                    {sessionStructuredData.clientTotal}€
                  </p>
                  </li>
              </ul>
                
            </>
          )}
          <Button type="link" className="bi-button black" to="/store">
            Accéder à la boutique
          </Button>
        </div>

      </section>

    </>
  );
};

export default Page;
