import React from "react";
import { Link } from "gatsby";
import Tag from "../../utils/atoms/Tag";
import IconButton from "../../utils/atoms/IconButton";
import { SwitchTransition, Transition } from "react-transition-group";
import { getDateLocale } from "../../../assets/scripts/utils/dateManager";
import gsap from 'gsap';

const PastEventsSlider = ({ events }) => {
  events = events.sort((a,b) => getDateLocale(b.year) - getDateLocale(a.year)).reverse();
  const [pageLength, setPageLength] = React.useState(4);
  const [eventsPages, setEventsPages] = React.useState(
    events.reduce((acc, event, index) => {
      if (index % pageLength === 0) {
        acc.push(events.slice(index, index + pageLength));
      }
      return acc;
    }, [])
  );
  const [activePage, setActivePage] = React.useState(0);
  const [isAnimated, setIsAnimated] = React.useState(false);
  const [maxActivePage, setMaxActivePage] = React.useState(0);

  React.useEffect(() => {
    setEventsPages(
      events.reduce((acc, event, index) => {
        if (index % pageLength === 0) {
          acc.push(events.slice(index, index + pageLength));
        }
        return acc;
      }, [])
    );
    setMaxActivePage(eventsPages.length - 1);
  }, [events]);

  const nextSlider = () => {
    if (activePage < maxActivePage) {
      setIsAnimated(true);
      setActivePage(activePage + 1);
    }
  };

  const prevSlider = () => {
    if (activePage > 0) {
      setIsAnimated(true);
      setActivePage(activePage - 1);
    }
  };

  const getDirection = (ev, obj) => {
    /* let w = obj.offsetWidth,
      h = obj.offsetHeight,
      x = ev.pageX - obj.offsetLeft - (w / 2) * (w > h ? h / w : 1),
      y = ev.pageY - obj.offsetTop - (h / 2) * (h > w ? w / h : 1),
      d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;

      console.log(w, h, x, y);
    return d; */
    let rect = obj.getBoundingClientRect();
    let x = ev.clientX - rect.left; //x position within the element.
    let y = ev.clientY - rect.top;
    let h = rect.height;

    return y < h / 2 ? "top" : "bottom";
  };

  const update = (ev, prefix, index) => {
    let liS = document.querySelectorAll(".pages .page_item li");
    let el = liS[index];
    el.removeAttribute("class");
    el.classList.add(prefix + "-" + getDirection(ev, el));
  };
  return (
    <>
      <div className="bi-events-title_wrapper">
        <h2 className="bi-title-quaternary bi-events-pastArtists-title">
          Les événements passés
        </h2>
        {eventsPages.length - 1 > 0 && (
          <div className="slide__arrows">
            <div
              className="slide-slider__arrow slide-slider__arrow--left"
              data-disabled={activePage === 0}
              onClick={(e) => {
                prevSlider();
              }}
            >
              <IconButton type="slider-prev" />
            </div>
            <div
              className="slide-slider__arrow slide-slider__arrow--right"
              data-disabled={activePage === eventsPages.length - 1}
              onClick={(e) => {
                nextSlider();
              }}
            >
              <IconButton type="slider-next" />
            </div>
          </div>
        )}
      </div>
      <div className="slider_wrapper pages">
        <div className="page_item">
          <SwitchTransition mode="out-in">
            <Transition key={Math.random()} unmountOnExit timeout={1200} onEnter={(node)=>{
              gsap.killTweensOf('.page_item li');
              gsap.set('.page_item li', {translateY: 32})
              gsap.fromTo('.page_item li', {opacity: 0, translateY: 32}, {opacity: 1, translateY: 0, stagger: 0.1})
            }} onExit={(node)=>{
              gsap.fromTo('.page_item li', {opacity: 1, translateY: 0}, {opacity: 0, translateY: -32, stagger: 0.1})
            }}>
            <ul>
            {eventsPages.length > 0 &&
              eventsPages[activePage].map((event, index) => {
                return (
                  <li
                    key={index}
                    onMouseEnter={(ev) => {
                      update(ev, "in", index);
                    }}
                    onMouseLeave={(ev) => {
                      update(ev, "out", index);
                    }}
                  >
                    <Link to={"/evenements/" + event.slug.current}>
                      <p>{event.name}</p>
                      <p>{event.year}</p>
                      <Tag data-parallax="false">{event.type}</Tag>
                    </Link>
                  </li>
                );
              })}
          </ul>
            </Transition>
          </SwitchTransition>
        </div>
      </div>
    </>
  );
};

export default PastEventsSlider;
