import React from "react";

import "../../assets/styles/templates/product.scss";

import { PortableText } from "@portabletext/react";

import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../assets/scripts/store/redux-slices/cart";

import Button from "../utils/atoms/Button";
import ProductListItem from "../modules/products/ProductListItem";
import IconButton from "../utils/atoms/IconButton";

import urlImageBuilder from "../../assets/scripts/utils/urlImageBuilder";
import {
  addToCrush,
  verifCrushExisting,
  removeFromCrush,
} from "../../assets/scripts/utils/crushManager";

import ProductSliderItem from "../modules/products/ProductSliderItem";
import { Helmet } from "react-helmet";
import gsap from "gsap";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle(array) {
  const seed = getRandomInt(0, 5);
  let exportedArray = array;
  const minShuffle = () => {
    var m = exportedArray.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = exportedArray[m];
      exportedArray[m] = exportedArray[i];
      exportedArray[i] = t;
    }

    return array;
  };

  for (let index = 0; index < seed; index++) {
    exportedArray = minShuffle();
  }

  return exportedArray;
}

const Page = (props) => {
  const [mobile, setMobile] = React.useState();
  const { connected, id, data } = useSelector((state) => state.authentication);
  const [crushExisting, setCrushExisting] = React.useState(false);
  const product = props.pageContext.product;
  const allProducts = props.pageContext.allProducts.nodes.filter(
    (x) => x.name != product.name && x.type === product.type
  );

  console.log(product)

  const [featuredProducts, setFeaturedProducts] = React.useState(
    allProducts.slice(0, 16)
  );
  let cartProduct = {
    id: product.id,
    imageUrl: urlImageBuilder(
      product.cover.asset.path.replace("image-", "").replace("-jpg", ".jpg"),
      200,
      null,
      { ...product.cover.crop, ...product.cover.asset }
    ),
    name: product.name,
    price:
      data.is_pro || product.promo
        ? data.is_pro && product.pro_price
          ? product.pro_price
          : product.promo
            ? product.price - (product.price * product.promo) / 100
            : product.price
        : product.price,
    quantity: 1,
  };

  const dispatch = useDispatch();

  const setProductToCart = (product) => {
    let quantity = document.querySelector(".bi-product-cart .bi-quantity")
      .dataset.value;
    let cartProductSet = { ...product };

    cartProductSet.quantity = parseInt(quantity);

    dispatch(addProductToCart(cartProductSet));
  };

  React.useEffect(() => {
    const details = document.querySelectorAll(
      ".bi-product-information__details details"
    );
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", (event) => {
        // Close all the details that are not targetDetail.
        event.preventDefault();

        details.forEach((detail) => {
          if (detail === targetDetail && !detail.getAttribute("open")) {
            openDetail(detail);
          } else {
            closeDetail(detail);
          }
        });
      });
    });
    if (window.innerWidth > 768) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    });

    let randomArray = shuffle(featuredProducts);
    setFeaturedProducts(randomArray);
  }, []);

  React.useEffect(() => {
    if (connected) {
      setCrushExisting(verifCrushExisting(product._id, data.wishlist));
    }
  }, [connected]);

  // Details management
  const openDetail = (detail) => {
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    let baseHeight = detail
      .querySelector("summary")
      .getBoundingClientRect().height;
    detail.setAttribute("open", "true");
    let targetHeight = detail.getBoundingClientRect().height;
    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: baseHeight,
      },
      {
        height: targetHeight,
      }
    );
  };

  const closeDetail = (detail) => {
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    gsap.killTweensOf(detail.querySelector("p"));
    detail.querySelector("p").style = " ";

    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: detail.getBoundingClientRect().height,
        ease: "easeOutExpo",
      },
      {
        height: detail.querySelector("summary").getBoundingClientRect().height,
        ease: "easeOutExpo",
        onComplete: () => {
          detail.removeAttribute("open");
        },
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>
          {product.name + ", " + product.editor + ", " + product.designer + ""}{" "}
          | Librairie Expérience : achetez en ligne
        </title>
      </Helmet>
      <section className="bi-product-part bi-layout">
        <ProductSliderItem product={product} />
        <div className="bi-product-content">
          <h1 className="bi-title-secondary bi-product-title">
            {product.name}
          </h1>
          <div className="bi-product-information">
            <div>
              <p className="small">Dessinateur</p>
              <p>{product.designer}</p>
            </div>
            <div>
              <p className="small">Editeur</p>
              <p>{product.editor}</p>
            </div>
            <div>
              <p className="small">
                Prix{" "}
                {data.is_pro && <span className="bi-red-highlight">pro</span>}{" "}
                {product.promo && !connected && (
                  <span className="bi-red-highlight">-{product.promo}%</span>
                )}
              </p>
              {data.is_pro ? (
                product.pro_price ? (
                  <p>
                    <span className="bi-red-highlight">
                      {product.pro_price}€
                    </span>{" "}
                    {"  "}
                    <span className="bi-main-price">{product.price}€</span>
                  </p>
                ) : (
                  <p>{product.price + "€"}</p>
                )
              ) : (
                <p>
                  <span className={product.promo && "bi-red-highlight"}>
                    {(!product.promo
                      ? product.price
                      : product.price - (product.price * product.promo) / 100) +
                      "€ "}
                  </span>
                  {product.promo && (
                    <span className="bi-main-price">{product.price}€</span>
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="bi-product-cart">
            <Button type="quantity" />{" "}
            {
              product.quantity > 0 ? <Button
                className="bi-button yellow"
                onClick={() => {
                  setProductToCart(cartProduct);
                }}
              >
                Ajouter au panier
              </Button> : <Button
                className="bi-button gray"
              >
                Ce produit n’est plus disponible
              </Button>
            }
          </div>
          <div className="bi-product-information__plus">
            <PortableText value={product.description} />
            {product.videourl && (
              <div className="bi-information_plus-action">
                <IconButton type="video" />{" "}
                <a href={product.videourl} target="_blank">
                  Lire la vidéo du produit
                </a>
              </div>
            )}
            {connected && (
              <>
                {crushExisting ? (
                  <div
                    className="bi-information_plus-action"
                    onClick={async (event) => {
                      await removeFromCrush(product, connected, id, event);
                      alert("Produit supprimé de votre liste de souhaits");
                      setCrushExisting(false);
                    }}
                  >
                    <IconButton type="wish-big" />{" "}
                    <p>Enlever de ma liste de souhaits</p>
                  </div>
                ) : (
                  <div
                    className="bi-information_plus-action"
                    onClick={async (event) => {
                      await addToCrush(product, connected, id, event);
                      alert("Produit ajouté à votre liste de souhaits");
                      setCrushExisting(true);
                    }}
                  >
                    <IconButton type="wish-big" />{" "}
                    <p>Ajouter à ma liste de souhaits</p>
                  </div>
                )}
              </>
            )}
          </div>
          <ul className="bi-product-information__details">
            <li>
              <details>
                <summary>
                  <div>
                    <p>Détails du produit</p>
                    <svg
                      width="24"
                      height="4"
                      viewBox="0 0 24 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg_open"
                    >
                      <path
                        d="M24 3.71434H0L0 0.285767L24 0.285775V3.71434Z"
                        fill="#1D1D1D"
                      />
                    </svg>

                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg_close"
                    >
                      <path
                        d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
                        fill="#1D1D1D"
                      />
                    </svg>
                  </div>
                </summary>
                <p>{product.details}</p>
              </details>
            </li>
            <li>
              <details>
                <summary>
                  {" "}
                  <div>
                    <p>Livraison et retour</p>
                    <svg
                      width="24"
                      height="4"
                      viewBox="0 0 24 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg_open"
                    >
                      <path
                        d="M24 3.71434H0L0 0.285767L24 0.285775V3.71434Z"
                        fill="#1D1D1D"
                      />
                    </svg>

                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg_close"
                    >
                      <path
                        d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
                        fill="#1D1D1D"
                      />
                    </svg>
                  </div>
                </summary>
                <p>{product.delivery}</p>
              </details>
            </li>
          </ul>
        </div>
      </section>
      <section className="bi-product-sugestion">
        <h2>Ça pourrait vous intéresser</h2>
        {featuredProducts.map((product, index) => {
          if (mobile ? index < 4 : index < 6) {
            return <ProductListItem product={product} key={index} />;
          } else {
            return <></>;
          }
        })}
      </section>
    </>
  );
};
export default Page;
