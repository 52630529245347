import React from "react";

import gsap from 'gsap';
import { useSelector, useDispatch } from "react-redux";
import { setTeamMember } from "../../../assets/scripts/store/redux-slices/modals";

import LaLibrairieJLMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-mobile.jpg";
import LaLibrairieJLMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-mobile@2x.jpg";

import LaLibrairieCMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-c-mobile.jpg";
import LaLibrairieCMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-c-mobile@2x.jpg";

import LaLibrairieJMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-j-mobile.jpg";
import LaLibrairieJMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-j-mobile@2x.jpg";

import LaLibrairieMMobile from "../../../assets/images/pages/a-propos/team/lalibrairie-m-mobile.jpg";
import LaLibrairieMMobileRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-m-mobile@2x.jpg";

import LaLibrairieJLDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-desktop.jpg";
import LaLibrairieJLDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-jl-desktop@2x.jpg";

import LaLibrairieCDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-c-desktop.jpg";
import LaLibrairieCDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-c-desktop@2x.jpg";

import LaLibrairieJDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-j-desktop.jpg";
import LaLibrairieJDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-j-desktop@2x.jpg";

import LaLibrairieMDesktop from "../../../assets/images/pages/a-propos/team/lalibrairie-m-desktop.jpg";
import LaLibrairieMDesktopRetina from "../../../assets/images/pages/a-propos/team/lalibrairie-m-desktop@2x.jpg";

import Slider from '../../modules/sliderModule/index';

const AboutSlider = () => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = React.useState(false);

  const moveEyes = (ev, el) => {
    let rect = el.getBoundingClientRect();
    let x = ev.clientX - rect.left; //x position within the element.
    let y = ev.clientY - rect.top;

    gsap.set(el.querySelector('.about-slider-item-eye'), { top: y, left: x });
  }

  React.useEffect(() => {
    setMobile(window.innerWidth < 769);

    window.addEventListener('resize', () => {
      setMobile(window.innerWidth < 769)
    })
  }, [])

  return (
    <>
      {mobile && (
        <Slider items={
          new Array(4)
        }>
          <li
            className="bi-slider_item"
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Jean-Louis",
                  open: true,
                })
              );
            }}
          >
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieJLMobileRetina + " 2x," + LaLibrairieJLMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieJLDesktopRetina +
                  " 2x," +
                  LaLibrairieJLDesktop +
                  " 1x"
                }
              />
              <img src={LaLibrairieJLDesktop} alt="" />
            </picture>
          </li>
          <li
            className="bi-slider_item"
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Camille",
                  open: true,
                })
              );
            }}
          >
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieCMobileRetina + " 2x," + LaLibrairieCMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieCDesktopRetina + " 2x," + LaLibrairieCDesktop + " 1x"
                }
              />
              <img src={LaLibrairieCDesktop} alt="" />
            </picture>
          </li>
          <li
            className="bi-slider_item"

            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Jonathan",
                  open: true,
                })
              );
            }}
          >
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieJMobileRetina + " 2x," + LaLibrairieJMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieJDesktopRetina + " 2x," + LaLibrairieJDesktop + " 1x"
                }
              />
              <img src={LaLibrairieJDesktop} alt="" />
            </picture>
          </li>
          <li
            className="bi-slider_item"
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Maxime",
                  open: true,
                })
              );
            }}
          >
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieMMobileRetina + " 2x," + LaLibrairieMMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieMDesktopRetina + " 2x," + LaLibrairieMDesktop + " 1x"
                }
              />
              <img src={LaLibrairieMMobile} alt="" />
            </picture>
          </li>
        </Slider>
      )}
      {!mobile && (<div className="about-slider">
        <div className="about-slider_container">
          <div
            className="about-slider-item"
            onMouseMove={(e) => {
              moveEyes(e, document.querySelectorAll('.about-slider-item')[0])
            }}
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Jean-Louis",
                  open: true,
                })
              );
            }}
          >
            <div className="about-slider-item-eye">
              <svg
                width="42"
                height="28"
                viewBox="0 0 42 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.83333C20.2272 5.84541 19.4601 5.96819 18.7221 6.19792C19.0634 6.79825 19.2452 7.47608 19.25 8.16667C19.25 8.7029 19.1444 9.23388 18.9392 9.72929C18.734 10.2247 18.4332 10.6748 18.054 11.054C17.6748 11.4332 17.2247 11.734 16.7293 11.9392C16.2339 12.1444 15.7029 12.25 15.1667 12.25C14.4761 12.2452 13.7982 12.0634 13.1979 11.7221C12.7243 13.3647 12.7795 15.1148 13.3557 16.7243C13.9319 18.3339 15 19.7213 16.4086 20.6901C17.8171 21.659 19.4948 22.16 21.204 22.1224C22.9132 22.0847 24.5672 21.5102 25.9317 20.4803C27.2963 19.4504 28.3022 18.0173 28.8069 16.3839C29.3117 14.7506 29.2898 12.9998 28.7443 11.3796C28.1987 9.75936 27.1573 8.35187 25.7674 7.35645C24.3774 6.36104 22.7096 5.82817 21 5.83333V5.83333ZM41.7463 12.9354C37.792 5.2201 29.9637 0 21 0C12.0363 0 4.2058 5.22375 0.253711 12.9361C0.0869075 13.2661 0 13.6306 0 14.0004C0 14.3701 0.0869075 14.7346 0.253711 15.0646C4.20799 22.7799 12.0363 28 21 28C29.9637 28 37.7942 22.7762 41.7463 15.0639C41.9131 14.7339 42 14.3694 42 13.9996C42 13.6299 41.9131 13.2654 41.7463 12.9354V12.9354ZM21 24.5C13.8068 24.5 7.21216 20.4896 3.6509 14C7.21216 7.51042 13.806 3.5 21 3.5C28.194 3.5 34.7878 7.51042 38.3491 14C34.7886 20.4896 28.194 24.5 21 24.5Z"
                  fill="#030303"
                />
              </svg>
            </div>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieJLMobileRetina + " 2x," + LaLibrairieJLMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieJLDesktopRetina +
                  " 2x," +
                  LaLibrairieJLDesktop +
                  " 1x"
                }
              />
              <img src={LaLibrairieJLDesktop} alt="" />
            </picture>
            <h3 className="bi-title-quaternary"><span className="bi-value-mask">Jean Louis</span></h3>
          </div>
          <div
            className="about-slider-item"
            onMouseMove={(e) => {
              moveEyes(e, document.querySelectorAll('.about-slider-item')[1])
            }}
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Camille",
                  open: true,
                })
              );
            }}
          >
            <div className="about-slider-item-eye">
              <svg
                width="42"
                height="28"
                viewBox="0 0 42 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.83333C20.2272 5.84541 19.4601 5.96819 18.7221 6.19792C19.0634 6.79825 19.2452 7.47608 19.25 8.16667C19.25 8.7029 19.1444 9.23388 18.9392 9.72929C18.734 10.2247 18.4332 10.6748 18.054 11.054C17.6748 11.4332 17.2247 11.734 16.7293 11.9392C16.2339 12.1444 15.7029 12.25 15.1667 12.25C14.4761 12.2452 13.7982 12.0634 13.1979 11.7221C12.7243 13.3647 12.7795 15.1148 13.3557 16.7243C13.9319 18.3339 15 19.7213 16.4086 20.6901C17.8171 21.659 19.4948 22.16 21.204 22.1224C22.9132 22.0847 24.5672 21.5102 25.9317 20.4803C27.2963 19.4504 28.3022 18.0173 28.8069 16.3839C29.3117 14.7506 29.2898 12.9998 28.7443 11.3796C28.1987 9.75936 27.1573 8.35187 25.7674 7.35645C24.3774 6.36104 22.7096 5.82817 21 5.83333V5.83333ZM41.7463 12.9354C37.792 5.2201 29.9637 0 21 0C12.0363 0 4.2058 5.22375 0.253711 12.9361C0.0869075 13.2661 0 13.6306 0 14.0004C0 14.3701 0.0869075 14.7346 0.253711 15.0646C4.20799 22.7799 12.0363 28 21 28C29.9637 28 37.7942 22.7762 41.7463 15.0639C41.9131 14.7339 42 14.3694 42 13.9996C42 13.6299 41.9131 13.2654 41.7463 12.9354V12.9354ZM21 24.5C13.8068 24.5 7.21216 20.4896 3.6509 14C7.21216 7.51042 13.806 3.5 21 3.5C28.194 3.5 34.7878 7.51042 38.3491 14C34.7886 20.4896 28.194 24.5 21 24.5Z"
                  fill="#030303"
                />
              </svg>
            </div>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieCMobileRetina + " 2x," + LaLibrairieCMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieCDesktopRetina + " 2x," + LaLibrairieCDesktop + " 1x"
                }
              />
              <img src={LaLibrairieCDesktop} alt="" />
            </picture>
            <h3 className="bi-title-quaternary"><span className="bi-value-mask">Camille</span></h3>
          </div>
          <div
            className="about-slider-item"
            onMouseMove={(e) => {
              moveEyes(e, document.querySelectorAll('.about-slider-item')[2])
            }}
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Jonathan",
                  open: true,
                })
              );
            }}
          >
            <div className="about-slider-item-eye">
              <svg
                width="42"
                height="28"
                viewBox="0 0 42 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.83333C20.2272 5.84541 19.4601 5.96819 18.7221 6.19792C19.0634 6.79825 19.2452 7.47608 19.25 8.16667C19.25 8.7029 19.1444 9.23388 18.9392 9.72929C18.734 10.2247 18.4332 10.6748 18.054 11.054C17.6748 11.4332 17.2247 11.734 16.7293 11.9392C16.2339 12.1444 15.7029 12.25 15.1667 12.25C14.4761 12.2452 13.7982 12.0634 13.1979 11.7221C12.7243 13.3647 12.7795 15.1148 13.3557 16.7243C13.9319 18.3339 15 19.7213 16.4086 20.6901C17.8171 21.659 19.4948 22.16 21.204 22.1224C22.9132 22.0847 24.5672 21.5102 25.9317 20.4803C27.2963 19.4504 28.3022 18.0173 28.8069 16.3839C29.3117 14.7506 29.2898 12.9998 28.7443 11.3796C28.1987 9.75936 27.1573 8.35187 25.7674 7.35645C24.3774 6.36104 22.7096 5.82817 21 5.83333V5.83333ZM41.7463 12.9354C37.792 5.2201 29.9637 0 21 0C12.0363 0 4.2058 5.22375 0.253711 12.9361C0.0869075 13.2661 0 13.6306 0 14.0004C0 14.3701 0.0869075 14.7346 0.253711 15.0646C4.20799 22.7799 12.0363 28 21 28C29.9637 28 37.7942 22.7762 41.7463 15.0639C41.9131 14.7339 42 14.3694 42 13.9996C42 13.6299 41.9131 13.2654 41.7463 12.9354V12.9354ZM21 24.5C13.8068 24.5 7.21216 20.4896 3.6509 14C7.21216 7.51042 13.806 3.5 21 3.5C28.194 3.5 34.7878 7.51042 38.3491 14C34.7886 20.4896 28.194 24.5 21 24.5Z"
                  fill="#030303"
                />
              </svg>
            </div>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieJMobileRetina + " 2x," + LaLibrairieJMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieJDesktopRetina + " 2x," + LaLibrairieJDesktop + " 1x"
                }
              />
              <img src={LaLibrairieJDesktop} alt="" />
            </picture>
            <h3 className="bi-title-quaternary"><span className="bi-value-mask">Jonathan</span></h3>
          </div>
          <div
            className="about-slider-item"
            onMouseMove={(e) => {
              moveEyes(e, document.querySelectorAll('.about-slider-item')[3])
            }}
            onClick={() => {
              dispatch(
                setTeamMember({
                  name: "Maxime",
                  open: true,
                })
              );
            }}
          >
            <div className="about-slider-item-eye">
              <svg
                width="42"
                height="28"
                viewBox="0 0 42 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.83333C20.2272 5.84541 19.4601 5.96819 18.7221 6.19792C19.0634 6.79825 19.2452 7.47608 19.25 8.16667C19.25 8.7029 19.1444 9.23388 18.9392 9.72929C18.734 10.2247 18.4332 10.6748 18.054 11.054C17.6748 11.4332 17.2247 11.734 16.7293 11.9392C16.2339 12.1444 15.7029 12.25 15.1667 12.25C14.4761 12.2452 13.7982 12.0634 13.1979 11.7221C12.7243 13.3647 12.7795 15.1148 13.3557 16.7243C13.9319 18.3339 15 19.7213 16.4086 20.6901C17.8171 21.659 19.4948 22.16 21.204 22.1224C22.9132 22.0847 24.5672 21.5102 25.9317 20.4803C27.2963 19.4504 28.3022 18.0173 28.8069 16.3839C29.3117 14.7506 29.2898 12.9998 28.7443 11.3796C28.1987 9.75936 27.1573 8.35187 25.7674 7.35645C24.3774 6.36104 22.7096 5.82817 21 5.83333V5.83333ZM41.7463 12.9354C37.792 5.2201 29.9637 0 21 0C12.0363 0 4.2058 5.22375 0.253711 12.9361C0.0869075 13.2661 0 13.6306 0 14.0004C0 14.3701 0.0869075 14.7346 0.253711 15.0646C4.20799 22.7799 12.0363 28 21 28C29.9637 28 37.7942 22.7762 41.7463 15.0639C41.9131 14.7339 42 14.3694 42 13.9996C42 13.6299 41.9131 13.2654 41.7463 12.9354V12.9354ZM21 24.5C13.8068 24.5 7.21216 20.4896 3.6509 14C7.21216 7.51042 13.806 3.5 21 3.5C28.194 3.5 34.7878 7.51042 38.3491 14C34.7886 20.4896 28.194 24.5 21 24.5Z"
                  fill="#030303"
                />
              </svg>
            </div>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet={
                  LaLibrairieMMobileRetina + " 2x," + LaLibrairieMMobile + " 1x"
                }
              />
              <source
                media="(min-width: 768px)"
                srcSet={
                  LaLibrairieMDesktopRetina + " 2x," + LaLibrairieMDesktop + " 1x"
                }
              />
              <img src={LaLibrairieMMobile} alt="" />
            </picture>
            <h3 className="bi-title-quaternary"><span className="bi-value-mask">Maxime</span></h3>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default AboutSlider;
