import * as React from "react";
import QuotesSection from "../../components/sections/Quotes";
import StoreList from "../../components/sections/StoreList";
import { graphql } from "gatsby";

import "../../assets/styles/pages/shop.scss";

import BoutiqueImageDesktop from "../../assets/images/pages/boutique/boutique_footer_desktop_1x.jpg";
import BoutiqueImageDesktopRetina from "../../assets/images/pages/boutique/boutique_footer_desktop_2x.jpg";
import BoutiqueImageMobile from "../../assets/images/pages/boutique/boutique_footer_mobile_1x.jpg";
import BoutiqueImageMobileRetina from "../../assets/images/pages/boutique/boutique_footer_mobile_2x.jpg";
import { Helmet } from "react-helmet";

const Page = (props) => {
  const { data } = props;
  return (
    <>
      <Helmet>
        <title>
          Boutique | Librairie Expérience : sérigraphies, tirages de tête,
          figurines et para BD
        </title>
        <meta
          name="description"
          content="Nous vendons le meilleur de l’univers de BD : sérigraphies, tirages de tête, figurines et para BD, en ligne et à Bellecour Lyon."
        />
      </Helmet>
      <section className="bi-shop">
        <h1>Boutique librairie experience</h1>
        <StoreList
          products={data.serigraphie.nodes}
          title="Sérigraphies"
          description="Depuis 2007, Expérience fabrique de manière artisanale des sérigraphies inspirées de l'univers de la BD."
        />
        <QuotesSection content="Les créations d'Expérience sérigraphie s'invitent sur vos murs !" />
        <StoreList
          products={data.paraBD.nodes}
          title="Para BD"
          description="Des figurines pour tous les goûts, une belle sélection de tirages de têtes et des affiches de différents formats "
        />
        <picture
          className="bi-shop-picture"
          data-parallax="true"
          data-p-direction="top"
          data-p-vitesse="0.25"
        >
          <source
            media="(max-width: 767px)"
            srcSet={
              BoutiqueImageMobileRetina + " 2x," + BoutiqueImageMobile + " 1x"
            }
          />
          <source
            media="(min-width: 768px)"
            srcSet={
              BoutiqueImageDesktopRetina + " 2x," + BoutiqueImageDesktop + " 1x"
            }
          />
          <img src={BoutiqueImageDesktop} alt="Salle dela sérigraphie" />
        </picture>
      </section>
    </>
  );
};
export const query = graphql`
  query AllProducts {
    paraBD: allSanityProductDocumentType(
      sort: { fields: _createdAt, order: DESC }
      filter:{is_visible: {ne: false}, type: { eq: "para-bd" } }
    ) {
      nodes {
        name
        id
        quantity
        _type
        type
        slug {
          current
        }
        price
        pro_price
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
      }
    }
    serigraphie: allSanityProductDocumentType(
      sort: { fields: _createdAt, order: DESC }
      filter: {is_visible: {ne: false},type: { eq: "serigraphie" } }
    ) {
      nodes {
        name
        id
        quantity
        _type
        type
        price
        pro_price
        slug {
          current
        }
        cover {
          crop {
            bottom
            left
            right
            top
          }
          asset {
            _id
            width
            height
            label
            path
            altText
          }
        }
      }
    }
  }
`;

export default Page;
