import React from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  addProductToCart,
  removeProductFromCart,
  removeAllProductsFromCart,
  checkQuantity,
  setTotalPrice
} from "../assets/scripts/store/redux-slices/cart";

import Tag from "../components/utils/atoms/Tag";
import Button, { FilterButton } from "../components/utils/atoms/Button";
import Product from "../components/modules/cart/Product";
const BrandingPage = (props) => {
 const products = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const returnMultipleProducts = (products) => {};
  return (
    <>
      <section className="bi-branding_header">
        <h1>Bienvenue sur le design système de la librairie</h1>
      </section>
      <section>
        <Tag>Ceci est un tag</Tag>
        <Button className="bi-button orange">Ceci est un boutton orange</Button>
        <Button className="bi-button black">Ceci est un boutton noir</Button>
        <Button className="bi-button blue">Ceci est un boutton bleu</Button>
        <Button className="bi-button yellow">Ceci est un boutton jaune</Button>
        <Button className="bi-button red">Ceci est un boutton rouge</Button>
        <Button type="link" to="/">
          Ceci est un boutton-lien
        </Button>
        <Button type="submit" value="Ceci est un boutton-input-submit" />
        <Button type="text">Ceci est un boutton text</Button>
        <FilterButton type="big" close={true} className="bi-button bi-button-filter_big blue">
          Camille
        </FilterButton>
        <FilterButton type="big" close={false} className="bi-button bi-button-filter_big orange">
          JP
        </FilterButton>
        <FilterButton type="small" close={true}>
          Genre
        </FilterButton>
        <FilterButton type="small" close={false}>
          Volume
        </FilterButton>
        <br />
        <br />
        <hr />
        <p>Cart Functionalities:</p>
        <br />
        <Button
          className="bi-button black"
          onClick={() => {
            dispatch(
              addProductToCart({
                id: "test",
                name: "Produit de test N°1",
                price: 8,
                quantity: 4,
              })
            );
          }}
        >
          0€ - Ajouter au panier
        </Button>
        <Button
          className="bi-button blue"
          onClick={() => {
            dispatch(
              addProductToCart({
                id: "test-2",
                name: "Produit de test N°2",
                price: 12,
                quantity: 4,
              })
            );
          }}
        >
          0€ - Ajouter un autre produit au panier
        </Button>
        <Button
          className="bi-button red"
          onClick={() => {
            dispatch(removeProductFromCart({ id: "test-2" }));
          }}
        >
          Supprimer les produits du boutton bleu
        </Button>
        <Button
          className="bi-button red"
          onClick={() => {
            dispatch(removeAllProductsFromCart());
          }}
        >
          Supprimer tous les produits
        </Button>
        <Button type="quantity" />
        <br />
        <p>Quantity switcher pour le produit du boutton bleu</p>
        <Button type="quantity" product={{ id: "test-2" }} />

        <hr />
        <p>Cart Modules:</p>
        <Product />
        <br />
        <br />
        {products.cart.map((el, index) => {
          return (
            <>
              <Product product={el}>
                <Button type="quantity" product={el} />{" "}
                <Button type="text"
                  onClick={() => {
                    dispatch(removeProductFromCart(el));
                  }}
                >
                  Supprimer
                </Button>
              </Product>
              <br />
            </>
          );
        })}
      </section>
    </>
  );
};

export default BrandingPage;
