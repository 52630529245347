import { Link, navigate } from "gatsby";
import React from "react";
import Button from "../../utils/atoms/Button";

import { useSelector, useDispatch } from "react-redux";
import {
  setConnected,
  setData,
} from "../../../assets/scripts/store/redux-slices/authentication.js";

import "../../../assets/styles/components/modules/tabs.scss";

export const Tabs = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const dispatch = useDispatch();
  const { connected, data } = useSelector((state) => state.authentication);

  //Check if the user is connected
  const redirect = () => {
    if (!connected) {
      // Redirect to the home page
      setTimeout(() => {
        navigate("/account/connexion/");
      }, 200);
    }
  };

  const tabsPath = {
    0: "/account/mon-compte",
    1: "/account/mes-commandes",
    2: "/account/wishlist",
    3: "/account/pro",
  };

  React.useEffect(() => {
    // Check current url contains one path of tabsPath
    let currentTab = Object.keys(tabsPath).find((key) =>
      window.location.pathname.includes(tabsPath[key])
    );

    setActiveTab(currentTab);
  });

  React.useEffect(() => {
    redirect();
  }, [connected]);

  return (
    <div className="bi-layout bi-account-tabs">
      <div className="bi-account-tabs-wrapper">
        <ul>
          <li className={activeTab === "0" && "active"}>
            <Link to="/account/mon-compte/">Mon compte</Link>
          </li>
          <li className={activeTab === "1" && "active"}>
            <Link to="/account/mes-commandes/">Mes commandes</Link>
          </li>
          <li className={activeTab === "2" && "active"}>
            <Link to="/account/wishlist/">Ma liste de souhaits</Link>
          </li>
          <li className={(activeTab === "3" && "active ") + (data.is_pro && " is_pro")}>
            <Link to="/account/pro/">Espace professionnel</Link>
          </li>
        </ul>
        <Button
          className="bi-button yellow"
          onClick={() => {
            //delete cookies lib-experience-connected and lib-experience-user-id
            document.cookie = `lib-experience-connected=; path=/; max-age=0`;
            document.cookie = `lib-experience-user-id=; path=/; max-age=0`;
            setTimeout(()=>{
              dispatch(setConnected(false));
            },100)
          }}
        >
          Déconnexion
        </Button>
      </div>
    </div>
  );
};

export default Tabs;
