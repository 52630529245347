import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../components/modules/account/Tabs";
import "../../assets/styles/pages/account.scss";
import Update from "../../components/modules/forms/Update";
import Button from "../../components/utils/atoms/Button";
const Page = () => {
  const [showPro, setShowPro] = React.useState(true);
  const dispatch = useDispatch();
  const [editable, setEditable] = React.useState(false);
  const { data, connected } = useSelector((state) => state.authentication);
  console.log(data);
  return (
    <section className="bi-account-c">
      <Tabs data={data} />
      <div className="bi-layout">
        <div className="bi-account-c_content">
          {!data.is_pro && showPro && (
            <div className="bi-pro">
              <p>
              Vous êtes un professionnel ? Inscrivez-vous via le formulaire
              </p>
              <div className="bi-pro-manage">
                <p
                  className="small"
                  onClick={() => {
                    setShowPro(false);
                  }}
                >
                  Non, merci
                </p>
                <Button
                  className="bi-button yellow"
                  type="link"
                  to="/account/pro/"
                >
                  Je minscris
                </Button>
              </div>
            </div>
          )}
          {data.firstname && (
            <p>
              Bienvenue <b>{data.firstname}</b>
            </p>
          )}
          <div className="bi-title_wrapper">
            <h1 className="bi-title-tertiary">Mon compte</h1>
            {editable && (
              <Button
                className="bi-button yellow"
                onClick={() => {
                  setEditable(false);
                }}
              >
                Annuler l'édition
              </Button>
            )}
          </div>

          {!editable ? (
            <div>
              <ul>
                <li>
                  <div>
                    <b>Prénom :</b> <span>{data.firstname}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <b>Nom :</b> <span>{data.name}</span>
                  </div>
                </li>
                {data.has_accepted_newsletter && (
                  <li>
                    <div>
                      <b>Abonné à la newsletter:</b> <span>Oui</span>
                    </div>
                  </li>
                )}
                {data.is_pro && (
                  <li>
                    <div>
                      <b>Compte professionel:</b> <span>Oui</span>
                    </div>
                  </li>
                )}
                <li>
                  <div>
                    <b>Mail :</b> <span>{data.email}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <b>Mot de passe :</b> <span>●●●●●●●●●●●</span>
                  </div>
                </li>
              </ul>
              <br />
              <br />
              <Button
                className="bi-button yellow"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Modifier mes infos
              </Button>
            </div>
          ) : (
            <>
              <Update data={data} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Page;
