import React from "react";
import IconButton from "../../utils/atoms/IconButton";
import Button from "../../utils/atoms/Button";


import Slider from '../sliderModule/index';
import "../../../assets/styles/components/modules/products/grid-suggest.scss";
const GridSugest = ({ title, children, items, heading }) => {
  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (!(window.innerWidth > 769)) {
        setMobile(true);
      }
    }, 100);

    window.addEventListener("resize", () => {
      if (!(window.innerWidth > 769)) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    });
  }, []);

  if (mobile) {
    return (
      <Slider items={items} heading={heading} className='bi-suggest'>{children}</Slider>
    );
  }

  return (
    <div className="bi-suggest">
      {
        heading !== undefined ? (<div className='bi-slider-heading'>
          <h2 className={`bi-title-${heading.type ? heading.type : 'quaternary'}`}>{heading.title}</h2>
          <p>{heading.content}</p>
          {
            heading.buttonContent && (<Button className="bi-button yellow" type="link" to={heading.buttonLink}>
              {heading.buttonContent}
            </Button>)
          }
        </div>) : ''
      }
      <ul className="grid-suggest desktop-view">{children}</ul>
    </div>
  );
};

export default GridSugest;
