import * as React from "react";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";

import "../assets/styles/pages/faq.scss";
import { Helmet } from "react-helmet";
import Tag from "../components/utils/atoms/Tag";
import gsap from "gsap";
import eventBus from "../assets/scripts/utils/eventBus";

const Page = (props) => {
  console.log(props.data);
  let array = [];

  props.data.allSanityFaqDocumentType.edges.forEach((event) => {
    array.push(event.node.type);
  });

  console.log(array);
  let filteredArray = [...new Set(array)];

  const faqTitlePageAnimation = () => {
    gsap.fromTo(
      "h1 .title-line .word",
      {
        translateY: "200px",
        rotate: (index, target) => {
          return index % 2 ? -4 : 4;
        },
      },
      {
        translateY: "0px",
        stagger: 0.15,
        rotate: 0,
      }
    );
  };

  React.useEffect(() => {
    faqTitlePageAnimation();
    eventBus.on("loaderEnd", () => {
      faqTitlePageAnimation();
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          FAQ | Librairie Expérience : librairie BD indépendante à Lyon
        </title>
        <meta
          name="description"
          content="Une question à propos de votre commande ? Comment suivre votre colis ? Consultez notre FAQ en ligne pour trouver rapidement une réponse à vos questions."
        />
      </Helmet>
      <section className="bi-faq">
        <div className="bi-faq-header">
          <h1>
            {" "}
            <span className="title-line">
              <span className="word">Foire aux</span>
            </span>{" "}
            <span className="title-line">
              <span className="word">Questions</span>
            </span>
            <div className="bi-visibility-hidden bi-tags-wrapper">
              <Tag parallax-vitesse="-0.25" id="h1-tag1" line="1" column="2">
                Allo ??!
              </Tag>
              <Tag parallax-vitesse="0.3" id="h1-tag2" line="2" column="3">
                J'ai besoin d'aide!
              </Tag>
              <Tag parallax-vitesse="-0.4" id="h1-tag3" line="3" column="0">
                Quand est-ce que mon colis va arriver ?
              </Tag>
            </div>
          </h1>
        </div>
        <div className="bi-faq-content">
          {filteredArray.map((category, index) => {
            return (
              <div key={index} className="bi-faq-wrapper">
                <h2 className="bi-title-tertiary">{category}</h2>
                <ul>
                  {props.data.allSanityFaqDocumentType.edges.map(
                    (event, index) => {
                      if (event.node.type === category) {
                        return (
                          <li>
                            <details>
                              <summary className="bi-title-quaternary">
                                <span>{event.node.question}</span>{" "}
                                <div className="bi-summary-icon">
                                  <svg
                                    width="18"
                                    height="11"
                                    viewBox="0 0 18 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18 2.09312L15.885 -9.24496e-08L9 6.79892L2.115 -6.94355e-07L-9.14931e-08 2.09312L9 11L18 2.09312Z"
                                      fill="#1D1D1D"
                                    />
                                  </svg>
                                </div>
                              </summary>
                              <div>
                                <PortableText value={event.node._rawAnswer} />
                              </div>
                            </details>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    allSanityFaqDocumentType {
      edges {
        node {
          question
          type
          answer {
            _key
            _type
            style
            list
            _rawChildren
          }
          _rawAnswer
        }
      }
    }
  }
`;

export default Page;
