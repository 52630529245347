import gsap from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import React from "react";
import urlImageBuilder from "../../../assets/scripts/utils/urlImageBuilder";
const ProductSliderItem = ({ product }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [filVisibility, setFilVisibility] = React.useState(true);
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragStartPosition, setDragStartPosition] = React.useState(0);
  const [dragLastPosition, setLastDragPosition] = React.useState(0);
  const slider = React.useRef();
  const getVWtoPixel = (value, vw) => (value * vw) / 100;
  React.useEffect(() => {
    if (window.innerWidth < 769) {
      document.querySelector(
        ".bi-product-gallery ul"
      ).style.transform = `translateX(-${currentIndex * getVWtoPixel(100, window.innerWidth)
      }px)`;
    }
  }, [currentIndex]);

  React.useEffect(() => {
    setTimeout(()=> {
      setIntersectionObserverGallery();
    }, 200);

    if (window.innerWidth > 768) {
      setCurrentFilImages();
      window.addEventListener('scroll', () => {
        setCurrentFilImages();
      })
    }
  }, []);

  const setIntersectionObserverGallery = () => {
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (window.innerWidth > 768) {
            if (!entry.isIntersecting) {
              gsap.to(".gallery-fil-arianne.desktop", {
                opacity: 0,
                translateX: -40,
                onComplete: () => {
                  setFilVisibility(false);
                },
              });
            } else {
              setFilVisibility(true);
              setFilVisibility((value) => {
                if (value === true) {
                  gsap.fromTo(".gallery-fil-arianne.desktop", {
                    opacity: 0,
                    translateY: 40,
                  }, {
                    opacity: 1, translateY: 0,
                    duration: '200ms'
                  });
                }
                if (value === false && window.scrollY >= 100) {
                  gsap.fromTo(".gallery-fil-arianne.desktop",{
                    opacity: 1, translateY: 0,
                    duration: '200ms'
                  }, {
                    opacity: 0,
                    translateY: -40,
                  });
                }
                return value;
              });
            }
          }
        });
      },
      { rootMargin: "-80% 0px 0px 0px" }
    );

    observer.observe(document.querySelector(".bi-product-gallery"));
  };

  const setCurrentFilImages = (target) => {
    let lists = Array.prototype.slice.call(document
      .querySelectorAll(".bi-product-gallery ul li img"));


    if(lists && lists.length > 0) {
      getNearestElement(lists);
    }
  };

  const getNearestElement = (lists) => {
    let distancesArray = lists.map((el, index) => {
      return el.getBoundingClientRect().top + el.getBoundingClientRect().top / 2;
    });
    let screenHeight = window.innerHeight / 2;

    let closest = distancesArray.reduce((prev, curr) => {
      return (Math.abs(curr - screenHeight) < Math.abs(prev - screenHeight) ? curr : prev)
    })

    let elementList = lists[distancesArray.indexOf(closest)];
    setCurrentIndex(distancesArray.indexOf(closest));
  };

  const onDragStart = (event, el) => {
    if (window.innerWidth < 769) {
      // if event is a touch event
      if (event.touches) {
        event = event.touches[0];
      }
      // get el translateX value from transform: translateX(x)
      let x = event.clientX - el.offsetWidth / 2;
      setIsDragging(true);
      setDragStartPosition(x);
      document.querySelector(".bi-product-gallery ul").style.transition = "0ms";
    }
  };

  const onDragMove = (event, el) => {
    if (window.innerWidth < 769) {
      setIsDragging((isDragging) => {
        if (event.touches) {
          event = event.touches[0];
        }
        if (isDragging) {
          // Move the element with the mouse position and the start mouse position difference on x Axis

          let cursorX = event.clientX - el.offsetWidth / 2;
          setLastDragPosition(cursorX);
          let x =
            (dragStartPosition +
              el.offsetWidth * currentIndex -
              el.offsetLeft -
              (event.clientX - el.offsetWidth / 2)) *
            -1;

          el.style.transition = "0ms";
          el.style.transform = `translateX(${x}px)`;
        }
        return isDragging;
      });
    }
  };

  const onDragEnd = (event, el) => {
    if (window.innerWidth < 769) {
      // if event is a touch event
      setCurrentIndex((val) => {
        return val;
      });

      if (event.touches) {
        event = event.touches[0];
      }

      let viewportWidth = window.innerWidth;

      setIsDragging(false);

      // Check if the element move more than 25% of the width to the right
      if (
        dragLastPosition > dragStartPosition + viewportWidth / 8 &&
        currentIndex > 0
      ) {
        setCurrentIndex((val) => {
          document.querySelector(".bi-product-gallery ul").style.transition =
            "300ms";
          document.querySelector(
            ".bi-product-gallery ul"
          ).style.transform = `translateX(-${val - 1 * getVWtoPixel(100, window.innerWidth)
          }px)`;
          return val - 1;
        });
      } else if (
        dragLastPosition < dragStartPosition - viewportWidth / 8 &&
        currentIndex < slider.current.querySelectorAll("li").length - 1
      ) {
        setCurrentIndex((val) => {
          document.querySelector(".bi-product-gallery ul").style.transition =
            "300ms";
          document.querySelector(
            ".bi-product-gallery ul"
          ).style.transform = `translateX(-${val + 1 * getVWtoPixel(100, window.innerWidth)
          }px)`;
          return val + 1;
        });
      } else {
        setCurrentIndex((val) => {
          document.querySelector(".bi-product-gallery ul").style.transition =
            "300ms";
          document.querySelector(
            ".bi-product-gallery ul"
          ).style.transform = `translateX(-${val * getVWtoPixel(100, window.innerWidth)
          }px)`;
          return val;
        });
      }
    }
  };

  const isMobile = () => window.innerWidth > 768;

  return (
    <div className="bi-product-gallery">
      <ul
        ref={slider}
        onMouseDown={(e) => {
          isMobile && onDragStart(e, slider.current);
        }}
        onTouchStart={(e) => {
          isMobile && onDragStart(e, slider.current);
        }}
        onMouseMove={(e) => {
          isMobile && onDragMove(e, slider.current);
        }}
        onTouchMove={(e) => {
          isMobile && onDragMove(e, slider.current);
        }}
        onMouseLeave={(e) => {
          isMobile && onDragEnd(e, slider.current);
        }}
        onTouchEnd={(e) => {
          isMobile && onDragEnd(e, slider.current);
        }}

        className={product.gallery.length < 1 && 'bi-one'}
      >
        <li id="gallery-point-0" data-index="0" data-type={product.type}>
          <img
            src={urlImageBuilder(
              product.cover.asset.path
                .replace("image-", "")
                .replace("-jpg", ".jpg"),
              720,
              null,
              { ...product.cover.crop, ...product.cover.asset }
            )}
          />
        </li>
        {product.gallery.map((image, index) => {
          return (
            <li
              key={index}
              id={"gallery-point-" + index + 1}
              data-index={index + 1}
            >
              <img
                src={urlImageBuilder(
                  image.asset.path
                    .replace("image-", "")
                    .replace("-jpg", ".jpg"),
                  720,
                  null,
                  { ...image.crop, ...image.asset }
                )}
                style={{
                  width: "100%",
                }}
              />
            </li>
          );
        })}
      </ul>
      <div className="gallery-fil-arianne mobile">
        <div
          className={"gallery-point " + (currentIndex === 0 ? "active" : "")}
          onClick={() => {
            setCurrentIndex(0);
          }}
        ></div>
        {product.gallery.map((el, index) => (
          <div
            className={
              "gallery-point " + (currentIndex === index + 1 ? "active" : "")
            }
            onClick={() => {
              setCurrentIndex(index + 1);
            }}
          ></div>
        ))}
      </div>
      {filVisibility && (
        <div className="gallery-fil-arianne desktop">
          <div
            className={"gallery-point " + (currentIndex === 0 ? "active" : "")}
            onClick={() => {
              gsap.registerPlugin(ScrollToPlugin);
              gsap.to(window, { duration: 1, scrollTo: "#gallery-point-0" });
            }}
          >
            <img
              src={urlImageBuilder(
                product.cover.asset.path
                  .replace("image-", "")
                  .replace("-jpg", ".jpg"),
                100,
                null,
                { ...product.cover.crop, ...product.cover.asset }
              )}
              style={{
                width: "100%",
              }}
            />
          </div>
          {product.gallery.map((image, index) => (
            <div
              className={
                "gallery-point " + (currentIndex === index + 1 ? "active" : "")
              }
              onClick={() => {
                let anchor = "#gallery-point-" + index + 1;
                gsap.registerPlugin(ScrollToPlugin);
                gsap.to(window, {
                  duration: 1,
                  scrollTo: anchor,
                  offsetY: "50%",
                });
              }}
              key={index}
            >
              <img
                src={urlImageBuilder(
                  image.asset.path
                    .replace("image-", "")
                    .replace("-jpg", ".jpg"),
                  100,
                  null,
                  { ...image.crop, ...image.asset }
                )}
                style={{
                  width: "100%",
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSliderItem;
